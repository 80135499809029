import { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../svg-shapes";
import { FieldProps, FormFieldContainer } from "./form-field-container";
import style from "./form-tags-field.module.css";

export type FormTag = {
  id?: string;
  name: string;
};

type TagsFieldProps = FieldProps<FormTag[]> & {
  options: FormTag[];
  value: FormTag[];
  placeholder?: string;
  canAddNewTags?: boolean;
  customStyle?: React.CSSProperties;
};

type TagProps = {
  tag: FormTag;
  onDelete: (tag: FormTag) => void;
};

export function FormTagsField(props: TagsFieldProps) {
  const {
    id,
    value,
    options,
    placeholder = "",
    onChange,
    onFocus = () => {},
    onBlur = () => {},
    autoFocus = false,
    tabIndex,
    canAddNewTags = false,
    customStyle,
  } = props;
  const ref = useRef<any>(null);

  const [isFocused, setFocused] = useState(false);
  const [text, setText] = useState("");
  const [selectedTags, setSelectedTags] = useState(value);
  const [shownOptions, setShownOptions] = useState<FormTag[]>([]);
  const [placeHolder, setPlaceHolder] = useState(placeholder);

  const mouseDownRef = useRef(false);

  useEffect(() => {
    if (autoFocus) {
      ref && ref.current.focus();
    }
  }, []);

  useEffect(() => {
    setSelectedTags(value);
  }, [value]);

  function trimTags(tags: FormTag[], from: FormTag[]) {
    return from.filter((t) => !tags.includes(t)); //.slice(0, 10);
  }

  useEffect(() => {
    if (!isFocused) {
      setShownOptions([]);
    } else if (!text) {
      setShownOptions(trimTags(selectedTags, options));
    } else {
      const term = text.toLowerCase();
      const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(term));
      setShownOptions(trimTags(selectedTags, filteredOptions));
    }
  }, [isFocused, text, selectedTags]);

  useEffect(() => {
    onChange(selectedTags);
  }, [selectedTags]);

  function inputChanged(e: any) {
    setText(e.target.value);
  }

  function focus(e: any) {
    setFocused(true);
    onFocus(e.target.id);
    setPlaceHolder("");
  }

  function blur(e: any) {
    if (mouseDownRef.current) {
      ref.current.focus();
      mouseDownRef.current = false;
    } else {
      setFocused(false);
      onBlur(e.target.id);
    }
  }

  function deleteTag(tag: FormTag) {
    setSelectedTags((tags) => tags.filter((t) => t.name !== tag.name));
  }

  function addTag(tag: FormTag) {
    setSelectedTags((tags) => [...tags, tag]);
    setText("");
  }

  function renderChips(tags: FormTag[]) {
    return tags.map((tag) => <TagChip tag={tag} onDelete={deleteTag} key={tag.name} />);
  }

  function renderAutocomplete(options: FormTag[]) {
    if (options.length === 0) {
      return null;
    }
    const rendered = options.map((option) => (
      <div
        key={option.name}
        className={style.autocompleteOption}
        onMouseDown={(e) => {
          mouseDownRef.current = true;
          addTag(option);
        }}
      >
        <span>{option.name}</span>
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="4.5" x2="8.5" y2="4.5" stroke="#848199" strokeLinecap="round" />
          <line x1="4.5" y1="8.5" x2="4.5" y2="0.5" stroke="#848199" strokeLinecap="round" />
        </svg>
      </div>
    ));
    return <div className={style.autocomplete}>{rendered}</div>;
  }

  return (
    <div className={style.fieldContainer}>
      <FormFieldContainer {...props} isFocused={false}>
        <div className={style.tagsContainer} style={customStyle}>
          {selectedTags.length > 0 && renderChips(selectedTags)}
          <input
            className={style.input}
            ref={ref}
            id={id}
            value={text}
            onChange={inputChanged}
            onFocus={focus}
            onBlur={blur}
            placeholder={placeHolder}
            autoFocus={autoFocus}
            tabIndex={tabIndex}
            autoComplete={"off"} 
          />
        </div>
      </FormFieldContainer>
      {renderAutocomplete(shownOptions)}
    </div>
  );
}

function TagChip({ tag, onDelete }: TagProps) {
  return (
    <div className={style.tagChip} onClick={() => onDelete(tag)}>
      <span>{tag.name}</span>
      <CloseIcon color={"#113357"} style={{ marginTop: "5px" }} />
    </div>
  );
}
