import { Crown } from "frontend/icons/crown";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import React from "react";
import style from "./template-card.module.css";
import cn from "classnames";
import { CanvasTemplateMinimal } from "shared/datamodel/schemas/canvas-template";

export enum TemplateCardStyle {
    small = "small",
    regular = "regular",
}

const pencilIcon = (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 12L1 12L1 9L8.64645 1.35355C8.84171 1.15829 9.15829 1.15829 9.35355 1.35355L11.6464 3.64645C11.8417 3.84171 11.8417 4.15829 11.6464 4.35355L4 12Z"
      stroke="currentColor"
    />
    <path d="M7.11108 2.8335L10.1666 5.88905" stroke="currentColor" />
  </svg>
);

const trashIcon = (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.85745 0.885742H3.14316V3.39995H0V4.34281H0.642984L1.62079 13.143H9.37897L10.3568 4.34281H11V3.39995H7.85745V0.885742ZM6.91459 3.39995V1.8286H4.08602V3.39995H6.91459ZM1.59164 4.34281H9.40811L8.53507 12.2002H2.46468L1.59164 4.34281ZM4.71444 5.91416V10.6284H3.77158V5.91416H4.71444ZM7.22838 10.6284V5.91416H6.28552V10.6284H7.22838Z"
      fill="currentColor"
    />
  </svg>
);

export function NewTemplateCard({
  template,
  previewAvailable,
  onClick,
  onPreview,
  cardStyle = TemplateCardStyle.regular,
  onEditTemplate,
  onDeleteTemplate,
}: {
  template: CanvasTemplateMinimal;
  previewAvailable: boolean;
  onClick: (template: CanvasTemplateMinimal) => void;
  onPreview: (template: CanvasTemplateMinimal) => void;
  cardStyle?: TemplateCardStyle;
  onEditTemplate: (template: CanvasTemplateMinimal) => void;
  onDeleteTemplate: (template: CanvasTemplateMinimal) => void;
}) {

  const isSmall = cardStyle === TemplateCardStyle.small;

  const name = template.name;

  return (
    <div
      className={cn(style.gridContainer, { [style.small]: isSmall })}
      data-testid={template.name}
      onClick={() => onClick(template)}
    >
      <div className={cn(style.templateImage, { [style.small]: isSmall })}>
        {template.thumbnailImageURL && <img src={template.thumbnailImageURL} />}
        {template.metadata?.mondaySolutionId && <img src="/images/monday-mini-logo.svg" className={style.mondayLogo} />}
        {previewAvailable && (
          <div className={style.buttons}>
            <StyledButton
              title="Use Template"
              size={ButtonSize.small}
              style={ButtonStyle.filled}
              customStyle={{ width: 120, height: 26 }}
            />
            <StyledButton
              title="Preview"
              size={ButtonSize.small}
              style={ButtonStyle.outline}
              onClick={(e: any) => {
                e.stopPropagation();
                onPreview(template);
              }}
              customStyle={{ width: 120, height: 26 }}
            />
          </div>
        )}
      </div>
      <div className={style.titleContainer}>
        <div className={style.templateTitle}>{name}</div>
        {template.upgradeRequired && (
          <div className={style.proBadge}>
            <Crown size={14} />
          </div>
        )}
        {template.isOwner && (
          <div className={style.templateOwnerActions}>
            <span
              onClick={(e: any) => {
                e.stopPropagation();
                onEditTemplate(template);
              }}
            >
              {pencilIcon}
            </span>
            <span
              onClick={(e: any) => {
                e.stopPropagation();
                onDeleteTemplate(template);
              }}
            >
              {trashIcon}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
