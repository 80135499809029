import { LiveTimelineElement } from "shared/datamodel/schemas/timeline";
import BaseTimelineCanvasElement, { BaseTimelineElementProps } from "./base-timeline-element";
import { BoardIntegrationLoadingState, useBoardTasks, useFilteredItemIds } from "frontend/hooks/use-board-integrations";
import { useCurrentCanvasValue } from "frontend/canvas-designer-new/canvas-context";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createIntegrationCards } from "frontend/utils/monday-integration-utils";
import { IntegrationItem } from "shared/datamodel/schemas";
import { format, parse } from "date-fns";
import consts from "shared/consts";
import React from "react";
import CanvasElement from "frontend/canvas-designer-new/canvas-element";
import { getElementTypeForId } from "../canvas-elements-utils";
import { useAtomValue } from "jotai";
import { utilsAtom } from "state-atoms";

export type LiveTimelineCanvasElementProps = Omit<
  BaseTimelineElementProps,
  | "itemsDates"
  | "itemsMap"
  | "renderElement"
  | "element"
  | "onStartDrag"
  | "onDropElements"
  | "canDragElementIds"
  | "timelineColumnWidth"
> & { element: LiveTimelineElement };

export default function LiveTimelineCanvasElement(props: LiveTimelineCanvasElementProps) {
  const { element, onChangeItems, isSelected, isSelectable, isReadOnly } = props;
  const { filters = [], integrationId, dateColumnId } = element;

  const [{ documentId, pusherChannel }] = useCurrentCanvasValue();
  const { itemIds, isLoading: isLoadingItemIds } = useFilteredItemIds(
    filters,
    integrationId,
    documentId,
    pusherChannel
  );
  const [itemIdToElementId, setItemIdToElementId] = useState<Record<string, string>>({});
  const [itemsMap, setItemsMap] = useState<Record<string, IntegrationItem>>({});
  const [itemDates, setItemDates] = useState<{ [itemId: string]: number }>({});
  const [hiddenElementIds, setHiddenElementIds] = useState<string[]>([]);
  const canvasUtils = useAtomValue(utilsAtom(documentId));

  const { updateColumnValue, getItems, addItemsToQueue, getLoadingState } = useBoardTasks(documentId);

  const itemsData = useMemo(() => getItems(integrationId), [integrationId, getItems]);
  const isFetchingItemsData = getLoadingState(integrationId) !== BoardIntegrationLoadingState.loaded;

  const stringItemIds = JSON.stringify(itemIds);

  const isLoading = !itemsData && (isLoadingItemIds || isFetchingItemsData);

  useEffect(() => {
    if (itemIds.length == 0) {
      return;
    }

    addItemsToQueue({ [integrationId]: itemIds });
  }, [integrationId, stringItemIds]);

  useEffect(() => {
    reloadItems(integrationId, itemsData);
  }, [integrationId, itemsData]);

  function canDragElementIds(ids: string[]) {
    const validIds = ids.filter((id) => getElementTypeForId(id) === consts.CANVAS_ELEMENTS.INTEGRATION);
    return validIds.length > 0;
  }

  function onStartDrag(elements: Record<string, any>) {
    const createdIds: string[] = [];
    Object.entries(elements).forEach(([id, element]) => {
      let { x, y } = element;
      const strippedId = id.split("-")[1];
      const createdId = canvasUtils?.addIntegrationItem(
        { x, y },
        element.integrationId,
        element.integrationType,
        element.configuration?.itemId,
        strippedId
      );
      if (createdId) {
        createdIds.push(createdId);
      }
    });
    if (createdIds.length > 0) {
      setHiddenElementIds(createdIds);
    }
  }

  function onDropElements(ids: string[], date: number) {
    setHiddenElementIds([]);
    const elementIdToItemIds = Object.entries(itemIdToElementId).reduce((acc, [itemId, elementId]) => {
      acc[elementId] = itemId;
      return acc;
    }, {} as Record<string, string>);
    const changedDates: Record<string, number> = {};
    ids.forEach((elementId) => {
      changedDates[elementId] = date;
      const itemId = elementIdToItemIds[elementId];
      if (itemId) {
        const dateString = date ? format(date, "yyyy-MM-dd") : "";
        updateColumnValue(integrationId, itemId, dateColumnId, { date: dateString });
      }
    });
    setItemDates((prev) => ({ ...prev, ...changedDates }));
    canvasUtils?.onDeleteElements(ids);
  }

  async function reloadItems(integrationId: string, itemsData: ReturnType<typeof getItems>) {
    if (!itemsData) {
      return;
    }
    const itemIds = itemsData.map((item) => item.id);
    const elements = createIntegrationCards(integrationId, itemIds, () => ({ x: 0, y: 0 }));

    const map: Record<string, IntegrationItem> = {};
    const dates: Record<string, number> = {};
    const itemIdMap: Record<string, string> = {};

    Object.entries(elements).forEach(([itemId, { id, element }]) => {
      const mondayItem = itemsData.find((item) => item.id === itemId);
      itemIdMap[itemId] = id;
      map[id] = element;

      if (mondayItem) {
        const { columnValues } = mondayItem;
        const dateString = columnValues.find((column: any) => column.id === dateColumnId)?.value;
        if (!dateString) {
          return;
        }
        const date = parse(dateString, "yyyy-MM-dd", new Date()).getTime();
        dates[id] = date;
      }
    });
    setItemsMap(map);
    setItemDates(dates);
    setItemIdToElementId(itemIdMap);
  }

  function renderElement(id: string, element: any, position: { x: number; y: number }) {
    const [type] = id.split("-");

    if (type === consts.CANVAS_ELEMENTS.INTEGRATION) {
      const elementData = {
        ...element,
        x: position.x,
        y: position.y - consts.DEFAULTS.MONDAY_CARD_HEIGHT,
        containerId: id,
      };
      itemsMap[id] = elementData;
      return {
        node: (
          <MemoedCanvasElement
            key={id}
            uniqueId={id}
            onResize={numbFunc}
            onChangeElement={onChangeItems}
            patchCanvasElement={numbFunc}
            patchAnything={numbFunc}
            isSelected={isSelected}
            isSelectable={isSelectable}
            drawOutlineAroundElements={false}
            isEditing={false}
            isEditingLink={false}
            isFrameHighlighted={false}
            onElementsMutationEnded={numbFunc}
            isThumbnail={false}
            isReadOnly={isReadOnly}
            elementData={elementData}
            isInContainer={true}
          />
        ),
        nextPosition: { x: position.x, y: elementData.y },
      };
    }
    return {
      node: null,
      nextPosition: position,
    };
  }

  const elementsWithoutHidden = {
    ...itemsMap,
  };
  hiddenElementIds.forEach((id) => {
    delete elementsWithoutHidden[id];
  });

  return (
    <BaseTimelineCanvasElement
      {...props}
      timelineColumnWidth={500}
      itemsMap={elementsWithoutHidden}
      itemsDates={itemDates}
      renderElement={renderElement}
      canDragElementIds={canDragElementIds}
      onStartDrag={onStartDrag}
      onDropElements={onDropElements}
      isLoading={isLoading}
    />
  );
}

const MemoedCanvasElement = React.memo(CanvasElement);
const numbFunc = () => {};
