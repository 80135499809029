import { useContainerElements } from "frontend/subscriptions";
import BaseTimelineCanvasElement, { BaseTimelineElementProps } from "./base-timeline-element";
import { TimelineConsts } from "frontend/utils/timeline-utils";
import consts from "shared/consts";
import React from "react";
import CanvasElement from "frontend/canvas-designer-new/canvas-element";
import { useAtomValue } from "jotai";
import { editingElementIdAtom, editingElementLinkAtom } from "state-atoms";
import { ITraits, Trait } from "frontend/canvas-designer-new/elements-toolbar/elements-toolbar-types";
import { StaticTimelineElement, TimelineElement } from "shared/datamodel/schemas/timeline";
import { noop } from "frontend/utils/fn-utils";
import { isIdOfType } from "../canvas-elements-utils";

export type TimelineCanvasElementProps = Omit<
  BaseTimelineElementProps,
  | "itemsDates"
  | "itemsMap"
  | "renderElement"
  | "element"
  | "onStartDrag"
  | "onDropElements"
  | "canDragElementIds"
  | "timelineColumnWidth"
> & { element: StaticTimelineElement };

export default function TimelineCanvasElement(props: TimelineCanvasElementProps) {
  const { id, element, syncService, onChangeItems, isSelected, isSelectable, isReadOnly } = props;
  const { itemsDates, scaleX: scale } = element;

  const editingElementId = useAtomValue(editingElementIdAtom);
  const editingElementLinkId = useAtomValue(editingElementLinkAtom);
  const elements = useContainerElements(syncService?.getReplicache(), id);

  function renderElement(id: string, element: any, position: { x: number; y: number }) {
    const [type] = id.split("-");

    switch (type) {
      case consts.CANVAS_ELEMENTS.STICKY_NOTE: {
        element = {
          ...element,
          x: position.x + TimelineConsts.timelineColumnStrokeWidth,
          y: position.y - element.height - TimelineConsts.elementsLineConnectorHeight,
          scaleX: 1,
          scaleY: 1,
        };
        break;
      }
      case consts.CANVAS_ELEMENTS.SHAPE: {
        element = { ...element, x: position.x, y: position.y - 60 };
        break;
      }
      case consts.CANVAS_ELEMENTS.INTEGRATION: {
        element = { ...element, x: position.x, y: position.y - consts.DEFAULTS.MONDAY_CARD_HEIGHT };
        break;
      }
      default:
        return { node: null, nextPosition: position };
    }

    return {
      node: (
        <MemoedCanvasElement
          key={id}
          uniqueId={id}
          onResize={numbFunc}
          onChangeElement={onChangeItems}
          patchCanvasElement={numbFunc}
          patchAnything={numbFunc}
          isSelected={isSelected}
          isSelectable={isSelectable}
          drawOutlineAroundElements={false}
          isEditing={editingElementId === id}
          isEditingLink={editingElementLinkId === id}
          isFrameHighlighted={false}
          onElementsMutationEnded={numbFunc}
          isThumbnail={false}
          isReadOnly={isReadOnly}
          elementData={element}
          isInContainer={true}
        />
      ),
      nextPosition: { x: position.x, y: element.y },
    };
  }

  function canDragElementIds(ids: string[]) {
    return ids.some((id) => isIdOfType(id, consts.CANVAS_ELEMENTS.STICKY_NOTE));
  }

  function onDropElements(ids: string[], date: number) {
    const changesList = ids.map((elementId: any) => [elementId, { containerId: id, scaleX: scale, scaleY: scale }]);
    const newItemsDates = {
      ...itemsDates,
      ...ids.reduce((acc: any, id: string) => {
        acc[id] = date;
        return acc;
      }, {} as Record<string, number>),
    };
    changesList.push([id, { itemsDates: newItemsDates }]);
    syncService?.getReplicache()?.mutate.changeElements({ info: changesList as any });
  }

  return (
    <BaseTimelineCanvasElement
      {...props}
      timelineColumnWidth={280}
      itemsMap={elements}
      itemsDates={itemsDates}
      renderElement={renderElement}
      canDragElementIds={canDragElementIds}
      onStartDrag={noop}
      onDropElements={onDropElements}
    />
  );
}

const MemoedCanvasElement = React.memo(CanvasElement);
const numbFunc = () => {};

export function timelineTraits(element: TimelineElement): ITraits {
  return {
    startDate: element.startDate,
    endDate: element.endDate,
  };
}

export function timelineValidateTrait(element: TimelineElement, trait: Trait, value: any) {
  if (trait == Trait.startDate && typeof value == "number") {
    return value < element.endDate ? value : element.startDate;
  }
  if (trait == Trait.endDate && typeof value == "number") {
    return value > element.startDate ? value : element.endDate;
  }
  return value;
}
