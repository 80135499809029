import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { FixedSizeList as List } from "react-window";
import DatePicker from "frontend/ui-components/date-picker";

import { Html } from "react-konva-utils";
import styles from "./task-card-open-element.module.css";
import { Dialog, Popover, Tooltip } from "frontend/ui-components/floaters/floaters";
import consts from "shared/consts";
import cn from "classnames";
import { TaskCard } from "shared/datamodel/schemas/task-card";
import { useDebounce } from "frontend/hooks/debounce";
import { useItemsFromReplicache } from "frontend/subscriptions";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { Tag, TagType } from "shared/datamodel/schemas/metadata";
import { createTag } from "shared/datamodel/metadata";
import { format } from "date-fns";
import { canvasMetadataPrefix, dbKey } from "shared/util/utils";
import LoadedState from "frontend/state/loadedState";
import { pencilIcon } from "frontend/ui-components/svg-shapes";
import { useAtomValue, useSetAtom } from "jotai";
import { deleteElementIdAtom } from "state-atoms/stage-atoms";
import { boardAtom, userAtom, usersAtom, usersStateAtom } from "state-atoms/users-atoms";
import { focusedElementIdAtom } from "state-atoms/url-atoms";
import { RESET } from "jotai/utils";
import { handleTabInTextArea } from "../text-element";

// functions to convert between long and short ids:
// cMetadata-Tag-XXXXXX <-> XXXXXX
function getShortId(elementId: string) {
  if (elementId.startsWith(canvasMetadataPrefix)) {
    return elementId.substring(canvasMetadataPrefix.length + TagType.length + 1);
  }
  return elementId;
}
function getLongId(elementId: string) {
  if (elementId.startsWith(canvasMetadataPrefix)) {
    return elementId;
  }
  return canvasMetadataPrefix + TagType + "-" + elementId;
}

export default function TaskCardOpen({
  id,
  syncService,
  element,
  onDismiss,
  onChangeElement,
}: {
  id: string;
  syncService: SyncService<RW>;
  element: TaskCard;
  onDismiss: () => void;
  onChangeElement: (
    props: any,
    undoConfig: { shouldAdd: boolean; previousProps?: any },
    updateSubMenuData?: any
  ) => void;
}) {
  const setdeleteElementId = useSetAtom(deleteElementIdAtom);
  const user = useAtomValue(userAtom);
  const users = useAtomValue(usersAtom);
  const usersState = useAtomValue(usersStateAtom);
  const board = useAtomValue(boardAtom);

  const setFocusedElement = useSetAtom(focusedElementIdAtom);

  let allTags = useItemsFromReplicache(syncService.getReplicache()!, canvasMetadataPrefix + TagType);

  const { color } = element;
  const userPartOfBoardAccount = user?.account?.id && board?.accountId && user?.account?.id == board?.accountId;

  let cardTags = element.tags || [];

  const dispatch = ({ type, payload }: { type: string; payload?: any }) => {
    if (!payload?.id) {
      console.error("tag operation with empty tag id", payload.id);
      return;
    }
    switch (type) {
      case "toggle-user-tag": {
        let tags = cardTags;
        let tag = payload.id;
        if (tags.includes(tag)) tags = tags.filter((item) => item != tag);
        else tags = [...tags, tag];
        onChangeElement({ tags: tags }, { shouldAdd: false });
        break;
      }
      case "create-new-tag": {
        const id = dbKey(payload.id, TagType);
        syncService.mutate.putMetadata({
          id: id,
          value: payload.value,
        });
        break;
      }
      case "delete-tag": {
        const id = dbKey(payload.id, TagType);
        syncService.mutate.deleteMetadata({ id: id });
        break;
      }
      case "modify-tag": {
        const id = dbKey(payload.id, TagType);
        syncService.mutate.changeMetadata({
          id: id,
          value: payload.value,
        });
        break;
      }
    }
  };

  // All state variables, even for the popups, have to be declared in this function component
  const descriptionInputRef = useRef<any>();
  const titleRef = useRef<any>();
  const [title, setTitle] = useState(element.title);
  const [assigneeSearchInput, setAssigneeSearchInput] = useState("");
  const searchTerm = useDebounce(assigneeSearchInput, 500);
  const [showCopiedLink, setShowCopiedLink] = useState<boolean>(false);
  const hasAssignee = element.assignees && element.assignees.length > 0;

  const usersIdAsArray = useMemo(() => [...users.keys()], [users]);
  const nonAssigned = useMemo(() => {
    return hasAssignee
      ? usersIdAsArray.filter((id) => !element.assignees!.find((person) => person.id == id))
      : usersIdAsArray;
  }, [usersIdAsArray, element.assignees]);

  const filteredBySearchTerm = useMemo(() => {
    return nonAssigned.filter((id) => users.get(id)!.name.toLocaleLowerCase().includes(searchTerm));
  }, [searchTerm, nonAssigned]);

  function copyLink() {
    applyCardUpdates();
    const me = window.location;
    navigator.clipboard.writeText(me.origin + me.pathname + "#focus=" + id);
    //setFocusedElement(id);
    setShowCopiedLink(true);
    setTimeout(() => setShowCopiedLink(false), 2000);
  }

  function applyCardUpdates() {
    onChangeElement(
      {
        title,
        description: descriptionInputRef.current.value,
      },
      { shouldAdd: false }
    );
  }
  function onClose() {
    applyCardUpdates();
    setFocusedElement(RESET);
    onDismiss();
  }

  useLayoutEffect(() => {
    if (titleRef.current) {
      titleRef.current.style.height = "auto";
      titleRef.current.style.height = titleRef.current.scrollHeight + "px";
    }
  }, [titleRef]);
  // ----------------------------------------------------------------

  function renderCardColorPopup(close: () => void) {
    function selectColor(e: React.MouseEvent<Element>) {
      let node;
      for (node = e.target as HTMLElement; node && !node.id; ) node = node.parentNode as HTMLElement;
      const newColor = node?.id;
      onChangeElement({ color: newColor }, { shouldAdd: false });
      close();
    }
    return (
      <div className={cn(styles.floater, styles.colorPicker)}>
        {consts.COLOR_PALETTE.map((color) => (
          <div className={styles.colorCircleCell} key={color} id={color} onClick={selectColor}>
            <ColorCircle color={color} />
          </div>
        ))}
      </div>
    );
  }

  // ----------------------------------------------------------------

  function renderCardTags(allTags: [string, any][], cardTags: string[]) {
    return (
      <div className={styles.tagsArea}>
        {cardTags.map((shortId) => {
          let fullid = getLongId(shortId);
          const tag = allTags.find(([id, value]) => id == fullid);
          return tag && renderTagElement(tag[0], tag[1], true);
        })}
      </div>
    );
  }

  function renderTagElement(id: string, tag: Tag, selected: boolean, onClick?: React.MouseEventHandler<HTMLElement>) {
    return (
      <button
        key={id}
        id={id}
        style={{
          backgroundColor: selected ? tag.color : "white",
          border: selected ? "1px transparent solid" : "1px " + tag.color + " solid",
          color: selected ? "white" : tag.color,
          cursor: onClick ? "pointer" : "default",
        }}
        onClick={onClick}
        className={styles.tag}
      >
        <div>{tag.name}</div>
      </button>
    );
  }

  function renderTagsPopup(close: () => void) {
    return <TagsWindow tags={allTags} cardTags={cardTags} dispatch={dispatch} close={close} />;
  }
  // ----------------------------------------------------------------

  function renderAssigneePopup(close: () => void) {
    function getDataAttr(target: any, prop: string) {
      while (target && !(prop in target.dataset)) target = target.parentNode;
      return target ? target.dataset[prop] : null;
    }

    function onClickAssigned(e: React.MouseEvent<HTMLElement>) {
      setAssigneeSearchInput("");
      onChangeElement({ assignees: [] }, { shouldAdd: false });
    }
    function onClickNonAssigned(e: React.MouseEvent<HTMLElement>) {
      const id = getDataAttr(e.target as any, "id");
      if (!id) return;
      //const userId = parseInt(id);
      let selectedUser;
      for (const user of users) {
        const userId = user[0];
        if (userId == id) {
          selectedUser = user[1];
        }
      }
      //const user = users.get(userId); //TODO: Why users.get(userId) stopped working??
      if (selectedUser) {
        onChangeElement(
          {
            assignees: [
              {
                id: selectedUser.id,
                name: selectedUser.name,
                email: selectedUser.email,
                thumbnail: selectedUser.thumbnail ?? selectedUser.photo,
              },
            ],
          },
          { shouldAdd: false }
        );
      }
      setAssigneeSearchInput("");
    }

    function renderUser(user: { name: string; email: string; thumbnail: string }, selected = false) {
      return (
        <div className={styles.userContainer}>
          <img width={40} height={40} className={styles.userPhoto} src={user.thumbnail} alt={user.name} />
          <span className={styles.userName}>{user.name}</span>
          <span className={styles.userEmail}>{user.email}</span>
          {selected && <span className={styles.selected} />}
        </div>
      );
    }
    const listLength = filteredBySearchTerm.length;
    const itemHeight = 50;
    const listHeight = Math.min(listLength * itemHeight, 400);

    return (
      <div className={styles.peopleList}>
        <input
          type="text"
          placeholder="Search people"
          value={assigneeSearchInput}
          onChange={(e) => setAssigneeSearchInput(e.target.value)}
        />
        {hasAssignee && (
          <>
            <div className={styles.usersList}>
              {element.assignees!.map((user) => (
                <div data-id={user.id.toString()} onClick={onClickAssigned}>
                  {renderUser(user, true)}
                </div>
              ))}
            </div>
            <div className={styles.hr} style={{ marginTop: 10, marginBottom: 10 }} />
          </>
        )}
        {usersState == LoadedState.loading ? (
          <h1>"Loading..."</h1>
        ) : usersState == LoadedState.failed ? (
          <h2>"Failed to load members list."</h2>
        ) : usersState == LoadedState.finished ? (
          <List
            height={listHeight}
            width={300}
            itemCount={listLength}
            itemSize={itemHeight}
            itemKey={(index: number, data: any) => filteredBySearchTerm[index]}
            className={styles.usersList}
          >
            {({ index, style }: { index: number; style: any }) => (
              <li
                tabIndex={0}
                style={style}
                key={filteredBySearchTerm[index]}
                data-id={filteredBySearchTerm[index]}
                onClick={onClickNonAssigned}
              >
                {renderUser(users.get(filteredBySearchTerm[index])!)}
              </li>
            )}
          </List>
        ) : null}
      </div>
    );
  }

  // ----------------------------------------------------------------

  function renderDueDatePopup(close: () => void) {
    const dueDate = dateFromNumber(element.dueDate);
    return (
      <div className={styles.floater}>
        <DatePicker
          selected={dueDate}
          onSelected={(day: Date) => {
            onChangeElement({ dueDate: day.valueOf() }, { shouldAdd: false });
          }}
        />
        <div className={styles.footer}>
          <button
            disabled={dueDate === undefined}
            onClick={() => {
              onChangeElement({ dueDate: 0 }, { shouldAdd: false });
            }}
          >
            Clear
          </button>
        </div>
      </div>
    );
  }

  // ----------------------------------------------------------------

  function dateFromNumber(value: number) {
    try {
      return value != 0 ? new Date(value) : undefined;
    } catch {
      return undefined;
    }
  }

  const dueDate = dateFromNumber(element.dueDate);

  return (
    <Html>
      <Dialog onClose={onClose}>
        <div
          className={styles.taskCardDialog}
          style={{
            borderTopColor: color,
          }}
        >
          <div className={styles.leftPane}>
            {renderCardTags(allTags, cardTags)}
            <textarea
              tabIndex={1}
              ref={titleRef}
              className={styles.title}
              placeholder="Title..."
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
              }}
              onKeyDown={handleTabInTextArea}
            />
            <textarea
              tabIndex={2}
              ref={descriptionInputRef}
              className={styles.description}
              placeholder="Description"
              defaultValue={element.description}
              onKeyDown={handleTabInTextArea}
            />
            <div className={styles.statusLine}>
              {hasAssignee && (
                <>
                  <span className={styles.assigneeLabel}>Assigned to:</span>
                  {userPartOfBoardAccount ? (
                    <>
                      <span className={styles.assigneeText}> {element.assignees![0].name} </span>
                      <img
                        className={styles.circular}
                        width={20}
                        height={20}
                        src={element.assignees![0].thumbnail}
                        alt={element.assignees![0].name}
                      />
                    </>
                  ) : (
                    <img
                      className={styles.circular}
                      width={20}
                      height={20}
                      src="/images/my-profile-icon.svg"
                      style={{ background: "#113255" }}
                    />
                  )}
                </>
              )}
              {hasAssignee && dueDate && <span className={styles.statusLineSpacer} />}
              {dueDate && (
                <>
                  <span className={styles.dueDateLabel}>Due date:</span>
                  <span className={styles.dueDateText}>
                    <img src="/images/due-date-icon.svg" width={18} />
                    {format(dueDate, "yyyy MMM d")}
                  </span>
                </>
              )}
            </div>
          </div>

          <div className={styles.rightPane} style={{ backgroundColor: color }}>
            <div className={styles.brightBackground} />
            <div className={styles.rightPaneButtons}>
              <MenuButton tabIndex={3} popupRender={renderCardColorPopup}>
                <ColorCircle color={color} size={15} />
                Task color
              </MenuButton>

              <MenuButton tabIndex={4} popupRender={renderTagsPopup}>
                <img src="/images/tags-icon.svg" />
                Tags
              </MenuButton>

              <MenuButton tabIndex={5} popupRender={renderAssigneePopup} disabled={!userPartOfBoardAccount}>
                <img src="/images/assignee-icon.svg" />
                Assignee
              </MenuButton>

              <MenuButton tabIndex={6} popupRender={renderDueDatePopup}>
                <img src="/images/due-date-icon.svg" width={15} height={15} />
                Due Date
              </MenuButton>
            </div>

            <div className={styles.horizontalButtonsSmall}>
              <span className={styles.notification} hidden={!showCopiedLink}>
                Link copied!
              </span>
              <Tooltip label={"Get link"} tooltipClassName={styles.tooltip}>
                <button disabled={showCopiedLink} tabIndex={7} onClick={copyLink}>
                  <img src="/images/share-icon.svg" />
                </button>
              </Tooltip>

              <Tooltip label={"Delete card"} tooltipClassName={styles.tooltip}>
                <button
                  tabIndex={8}
                  onClick={() => {
                    setdeleteElementId(id);
                  }}
                >
                  <img src="/images/trash-icon.svg" />
                </button>
              </Tooltip>
            </div>

            <button tabIndex={9} className={styles.closeButton} onClick={onClose}>
              <img src="/images/x.svg" />
            </button>
          </div>
        </div>
      </Dialog>
    </Html>
  );
}

function MenuButton(
  props: React.PropsWithChildren<{
    tabIndex?: number;
    popupRender: (closePopup: () => void) => React.ReactNode;
    disabled?: boolean;
  }>
) {
  return (
    <Popover render={props.popupRender} side={"bottom-end"}>
      <div tabIndex={props.tabIndex} className={cn(styles.menuButton, { [styles.disabled]: props.disabled })}>
        {props.children}
        <span className={styles.gap} />
        <span className={styles.ggChevronDown} />
      </div>
    </Popover>
  );
}

function ColorCircle({ color, size }: { color: string; size?: number }) {
  return (
    <div
      className={styles.colorCircle}
      style={{
        backgroundColor: color,
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
      }}
    />
  );
}

function TagsWindow({
  tags,
  cardTags,
  dispatch,
  close,
}: {
  tags: [string, Tag][];
  cardTags: string[];
  dispatch: any;
  close: () => void;
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [newColor, setNewColor] = useState(consts.COLOR_PALETTE[0]);
  const [newName, setNewName] = useState("");

  function closeAndApply() {
    setIsEditMode(false);
  }

  const lowerCaseInputText = newName.trim().toLocaleLowerCase();
  const canCreateNewTag = lowerCaseInputText != "" && !tags.find(([id, value]) => value.name == newName);

  let tagsFiltered = tags.filter(([id, value]) => value.name.toLocaleLowerCase().includes(lowerCaseInputText));
  function cmp(a: Tag, b: Tag) {
    const aKey = a.order ?? a.lastModifiedTimestamp;
    const bKey = b.order ?? b.lastModifiedTimestamp;
    return aKey! - bKey!;
  }
  tagsFiltered.sort((a, b) => cmp(a[1], b[1]));

  return (
    <div className={styles.floatingPopup} style={{ width: 300 }}>
      <form
        className={styles.inputLine}
        onSubmit={(e) => {
          dispatch({
            type: "create-new-tag",
            payload: createTag(newName, newColor),
          });
          setNewName("");
          e.preventDefault();
        }}
      >
        <Popover
          side={"bottom"}
          render={(closePopup: () => void) => {
            return (
              <div className={cn(styles.floater, styles.colorPicker)}>
                {consts.COLOR_PALETTE.map((color) => (
                  <div
                    className={styles.colorCircleCell}
                    key={color}
                    id={color}
                    onClick={(e: any) => {
                      let node;
                      for (node = e.target as HTMLElement; node && !node.id; ) node = node.parentNode as HTMLElement;
                      const newColor = node?.id;
                      setNewColor(newColor);
                      closePopup();
                    }}
                  >
                    <ColorCircle color={color} />
                  </div>
                ))}
              </div>
            );
          }}
        >
          <span>
            <ColorCircle color={newColor} size={18} />
          </span>
        </Popover>
        <input
          type="text"
          placeholder="Type a tag name..."
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <input type="Submit" value="Add tag" readOnly disabled={!canCreateNewTag} hidden={!canCreateNewTag} />
      </form>
      {isEditMode && tagsFiltered.length > 0 ? (
        <EditableTagsWindow tags={tagsFiltered} dispatch={dispatch} closeAndApply={closeAndApply} />
      ) : (
        <>
          <div className={styles.tagsFlexiLine}>
            {tagsFiltered.map(([fullid, tag]) =>
              renderTagElement(
                fullid,
                tag,
                cardTags.some((x) => fullid.includes(x)),
                (e: React.MouseEvent<HTMLElement>) => {
                  let tagId = getShortId((e.currentTarget as HTMLElement).id);
                  dispatch({ type: "toggle-user-tag", payload: { id: tagId } });
                }
              )
            )}
          </div>
          {!!tagsFiltered.length && (
            <>
              <div className={styles.hr} />
              <button className={styles.tagsAreaButton} onClick={() => setIsEditMode(true)}>
                {pencilIcon}
                Edit Tags
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}

function EditableTagsWindow({
  tags,
  dispatch,
  closeAndApply,
}: {
  tags: [string, any][];
  dispatch: any;
  closeAndApply: () => void;
}) {
  function renderColorPopup(onclick: (e: React.MouseEvent<Element>) => void) {
    return (
      <div className={styles.colorPicker}>
        {consts.COLOR_PALETTE.map((color) => (
          <div className={styles.colorCircleCell} key={color} id={color} onClick={onclick}>
            <ColorCircle color={color} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      <div className={styles.tagsEditArea}>
        {tags.map(([longId, tag]) => {
          return (
            <div key={longId} className={styles.tagInEditMode}>
              <Popover
                render={(close: () => void) => {
                  function selectColor(e: React.MouseEvent<Element>) {
                    let node;
                    for (node = e.target as HTMLElement; node && !node.id; ) node = node.parentNode as HTMLElement;
                    const newColor = node?.id;
                    dispatch({
                      type: "modify-tag",
                      payload: {
                        id: getShortId(longId),
                        value: {
                          type: tag.type,
                          name: tag.name,
                          color: newColor,
                          order: tag.order ?? tag.lastModifiedTimestamp,
                        } as Tag,
                      },
                    });
                    close();
                  }
                  return renderColorPopup(selectColor);
                }}
              >
                <span key={longId}>
                  <ColorCircle color={tag.color} />
                </span>
              </Popover>
              <input
                type="text"
                value={tag.name}
                onChange={(e) => {
                  dispatch({
                    type: "modify-tag",
                    payload: {
                      id: getShortId(longId),
                      value: {
                        type: tag.type,
                        name: e.target.value,
                        color: tag.color,
                        order: tag.order ?? tag.lastModifiedTimestamp,
                      } as Tag,
                    },
                  });
                }}
              />
              <img
                src="/images/x.svg"
                onClick={() => {
                  dispatch({ type: "delete-tag", payload: { id: getShortId(longId) } });
                  // if we just deleted the last tag, close the window
                  if (tags.length == 1) {
                    closeAndApply();
                  }
                }}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.hr} />
      <button className={styles.tagsAreaButton} onClick={closeAndApply}>
        Close
      </button>
    </>
  );
}

function renderTagElement(id: string, tag: Tag, selected: boolean, onClick?: React.MouseEventHandler<HTMLElement>) {
  return (
    <button
      key={id}
      id={id}
      style={{
        backgroundColor: selected ? tag.color : "white",
        border: selected ? "1px transparent solid" : "1px " + tag.color + " solid",
        color: selected ? "white" : tag.color,
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
      className={styles.tag}
    >
      <div>{tag.name}</div>
    </button>
  );
}
