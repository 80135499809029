import { replaceColorOpacity } from "frontend/utils/color-utils";
import { useAtomValue } from "jotai";
import { Group, Path, Rect, Text } from "react-konva";
import { Html } from "react-konva-utils";
import { Frame } from "shared/datamodel/schemas/frame";
import { isExportingAtom, posScaleAtom } from "state-atoms";
import { ITraits, Trait } from "../elements-toolbar/elements-toolbar-types";

export default function FrameCanvasElement({
  id,
  element,
  onChangeElement,
  disableShadow,
  isFrameHighlighted,
  isThumbnail,
}: {
  id: string;
  element: Frame;
  onChangeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
  disableShadow?: boolean;
  isFrameHighlighted: boolean;
  isThumbnail: boolean;
}) {
  const { width, height, scaleX = 1, scaleY = 1, visible = true } = element;
  const scale = useAtomValue(posScaleAtom).scale || 1;
  const isExporting = useAtomValue(isExportingAtom);
  const strokeWidth = isExporting && isThumbnail ? 0 : 2 / scale;

  const sWidth = width * scaleX;
  const sHeight = height * scaleY;

  function renderShadow() {
    return (
      <Html
        groupProps={{
          x: -22,
          y: -1,
        }}
        divProps={{
          style: {
            pointerEvents: "none",
          },
        }}
      >
        <div
          style={{
            borderStyle: "solid",
            borderWidth: "0px 22px 48px",
            borderImage: "url('/images/frame-shadow.png') 0 22 48",
            width: sWidth + 44,
            height: sHeight + 49,
          }}
        ></div>
      </Html>
    );
  }

  function renderHiddenState() {
    if (visible) {
      return null;
    }
    const iconWidth = 40,
      iconHeight = 20;
    return (
      <>
        <Rect width={sWidth} height={sHeight} cornerRadius={4 / scaleY} fill={"#F6F9FC"} />
        <Group x={(sWidth - iconWidth) / 2} y={(sHeight - iconHeight) / 2 - 30} scaleX={2} scaleY={2}>
          <Path
            data="M19 3.99988L21 7.44434"
            stroke="#797E93"
            strokeWidth={1.3}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <Path
            data="M13.7329 5.62244L14.4721 9.81816"
            stroke="#797E93"
            strokeWidth={1.3}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <Path
            data="M8.26703 5.61206L7.52783 9.8182"
            stroke="#797E93"
            strokeWidth={1.3}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <Path
            data="M3 3.99988L1 7.46517"
            stroke="#797E93"
            strokeWidth={1.3}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <Path
            data="M1.01025 1C2.75934 3.16554 5.966 5.90369 11.005 5.90369C16.0441 5.90369 19.2507 3.16554 20.9998 1"
            stroke="#797E93"
            strokeWidth={1.3}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </Group>
        <Text
          text="Hidden frame"
          y={40}
          width={sWidth}
          height={sHeight - 40}
          align="center"
          verticalAlign="middle"
          fill="#797E93"
          fontFamily="Poppins"
          fontSize={32}
        />
      </>
    );
  }

  return (
    <>
      <Group scaleX={1 / scaleX} scaleY={1 / scaleY}>
        {!disableShadow && renderShadow()}
        <Rect
          cornerRadius={4}
          width={sWidth}
          height={sHeight}
          stroke={isFrameHighlighted ? "#7517F8" : "#E5E8EA"}
          strokeWidth={strokeWidth}
          listening={false}
        />
        {renderHiddenState()}
      </Group>
    </>
  );
}

export function frameTraits(element: Frame): ITraits {
  return {
    fillColor: element.fill,
    visible: element.visible,
    frameAspectRatio: { width: element.width, height: element.height, scaleX: element.scaleX, scaleY: element.scaleY },
  };
}

export function frameValidateTrait(element: Frame, trait: Trait, value: any) {
  if (trait == Trait.fillColor && typeof value == "number") {
    const color = element.fill ?? "#FFFFFF";
    return replaceColorOpacity(color, value);
  }
  return value;
}
