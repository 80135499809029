import { Rect } from "react-konva";
import { useAtomValue } from "jotai";
import { StickyNote } from "shared/datamodel/schemas";
import { posScaleAtom } from "state-atoms/stage-atoms";
import { useEffect, useState } from "react";
import { StickyNoteMarginX, StickyNoteMarginY } from "./sticky-note-element";

enum LineSide {
  Left = "left",
  Right = "right",
}

function EdgeLine({
  lineSide,
  element,
  changeElement,
}: {
  lineSide: LineSide;
  element: StickyNote;
  changeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
}) {
  const expandFactor = 2;
  const { x, y, width, height, isWide, scaleX, scaleY } = element;
  const lineHeight = height + StickyNoteMarginY;
  const [xValue, setXValue] = useState(lineSide == LineSide.Left ? x : x + scaleX * (width + StickyNoteMarginX));
  const stageScale = useAtomValue(posScaleAtom).scale;

  function recalcHeight(width: number): number {
    const text = element.text ?? "";
    const minimumHeight = element.isWide ? element.width / 2 : element.width;
    const lineHeight = element.fontSize as number;
    const textLength = text.length;
    const emptyLines = (text.match(/^[ \t]*$/gm) || []).length;
    const numberOfLines = Math.ceil((element.fontSize as number * textLength) / (width + StickyNoteMarginX)) + emptyLines;
    const textHeight = numberOfLines * lineHeight;
    return Math.max(textHeight, minimumHeight);
  }

  useEffect(() => {
    setXValue(lineSide == LineSide.Left ? x : x + scaleX * (width + StickyNoteMarginX));
  }, [x, scaleX, width, isWide, element]);

  return (
    <Rect
      name="edge-anchor"
      x={xValue}
      y={y}
      width={10 / stageScale}
      height={lineHeight}
      scaleY={scaleY}
      draggable
      onMouseEnter={(e) => {
        const container = e.target.getStage()!.container();
        container.style.cursor = "ew-resize";
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage()!.container();
        container.style.cursor = "default";
      }}
      onDragStart={(e) => {
        const container = e.target.getStage()!.container();
        container.style.cursor = "ew-resize";
      }}
      onDragMove={(e) => {
        const cursorDistance = (width / expandFactor) * scaleX;
        const shouldDragRight = Math.floor(e.target.x()) > Math.floor(xValue + cursorDistance);
        const shouldDragLeft = Math.floor(e.target.x()) < Math.floor(xValue - cursorDistance);
        if (lineSide == LineSide.Right) {
          //expand sticky note from the right
          if (shouldDragRight && !isWide) {
            changeElement(
              { width: width * expandFactor, height: recalcHeight(width * expandFactor), isWide: true },
              { shouldAdd: true, previousProps: { width, height, isWide } }
            );
          }
          //shrink sticky note from the right side
          if (shouldDragLeft && isWide) {
            changeElement(
              { width: width / expandFactor, height: recalcHeight(width / expandFactor), isWide: false },
              { shouldAdd: true, previousProps: { width, height, isWide } }
            );
          }
        } else {
          //expand sticky note from the left side
          if (shouldDragLeft && !isWide) {
            changeElement(
              { width: width * expandFactor, x: x - scaleX * width, height: recalcHeight(width * expandFactor), isWide: true },
              { shouldAdd: true, previousProps: { width, x, height, isWide } }
            );
          }
          //shrink sticky note from the left side
          if (shouldDragRight && isWide) {
            changeElement(
              {
                width: width / expandFactor,
                x: x + (scaleX * width) / expandFactor,
                height: recalcHeight(width / expandFactor),
                isWide: false,
              },
              { shouldAdd: true, previousProps: { width, x, height, isWide } }
            );
          }
        }
      }}
      onDragEnd={(e) => {
        const container = e.target.getStage()!.container();
        container.style.cursor = "default";
        if (lineSide === LineSide.Left) {
          e.target.setPosition({ x, y });
        } else {
          e.target.setPosition({ x: x + scaleX * (width + StickyNoteMarginX), y });
        }
      }}
    />
  );
}

export default function EdgeLines({
  element,
  changeElement,
}: {
  element: StickyNote;
  changeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
}) {
  return (
    <>
      <EdgeLine lineSide={LineSide.Left} element={element} changeElement={changeElement} />
      <EdgeLine lineSide={LineSide.Right} element={element} changeElement={changeElement} />
    </>
  );
}
