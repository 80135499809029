import { Crown } from "frontend/icons/crown";
import tracking from "frontend/tracking";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import { useEffect } from "react";
import { useKey } from "react-use";
import consts from "shared/consts";
import style from "./upgrade-modal.module.css";
import { userAtom } from "state-atoms/users-atoms";
import { useAtomValue } from "jotai";
import Modal from "frontend/modal/modal";

export interface UpgradeModalNeededProps {
  trialDays: number;
  onDismiss: () => void;
  onUpgrade: () => void;
  insideModal: boolean;
}

export default function UpgradeModalGeneric({
  trackingEvent,
  title,
  replaceTitleWith,
  mainTitle,
  content,
  bulletpoints,
  trialDays,
  image,
  showCancelLine = true,
  showDismissButton = false,
  upgradeButtonText,
  upgradeButtonStyle = ButtonStyle.outline,
  onDismiss,
  onUpgrade,
  insideModal,
}: {
  trackingEvent: string;
  title: React.ReactNode;
  replaceTitleWith?: React.ReactNode;
  mainTitle?: string;
  content: string;
  bulletpoints: string[];
  trialDays: number;
  image: string;
  showCancelLine?: boolean;
  showDismissButton?: boolean;
  upgradeButtonText?: string;
  upgradeButtonStyle?: ButtonStyle;
  onDismiss: () => void;
  onUpgrade: () => void;
  insideModal: boolean;
}) {
  useKey("Escape", onDismiss);
  const user = useAtomValue(userAtom);
  useEffect(() => {
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.BILLING,
      "upgrade-modal-shown",
      trackingEvent,
      user?.account?.id,
      user?.id
    );
  }, []);

  const isDowngraded = user?.planInfo?.is_downgraded;
  mainTitle ??= isDowngraded ? "Upgrade To Canvas Pro" : "Try Canvas Pro for free";
  const buttonText = upgradeButtonText ?? (isDowngraded ? "Upgrade Now" : `Try it free for ${trialDays} days`);

  function renderContent() {
    return (
      <div className={style.centerScreen}>
        <div className={style.mainLayout}>
          <button className={style.closeButton} onClick={onDismiss} data-testid="close-upgrade-modal" />
          <div className={style.upgradeModal}>
            <h2>
              {replaceTitleWith || (
                <>
                  <Crown />
                  <span className={style.title}>{title}</span>
                </>
              )}
            </h2>
            <h1>{mainTitle ?? "Try Canvas Pro for free"}</h1>
            <section dangerouslySetInnerHTML={{ __html: content }} />{" "}
            {/* enable to add bold to the content: `dangerouslySetInnerHTML={{__html:` */}
            <b>Explore all that WorkCanvas has to offer:</b>
            <ul>
              {bulletpoints.map((bulletpoint) => (
                <li key={bulletpoint}>&nbsp;&nbsp;{bulletpoint}</li>
              ))}
            </ul>
            {showCancelLine && (
              <>
                <div className={style.hline} />
                <span className={style.cancelContainer}>
                  <b>Cancel any time</b>
                  {!isDowngraded && ", we'll remind you 7 days before your trial ends."}
                </span>
                <div className={style.hline} />
              </>
            )}
            <StyledButton
              title={buttonText}
              icon="/images/crown-gold.svg"
              style={upgradeButtonStyle}
              size={ButtonSize.regular}
              onClick={onUpgrade}
              customStyle={{ alignSelf: "center", width: "100%", marginTop: "auto" }}
            />
            {showDismissButton && (
              <StyledButton
                title={`continue`}
                style={ButtonStyle.outline}
                size={ButtonSize.regular}
                onClick={onDismiss}
                customStyle={{ alignSelf: "center", width: "100%", marginTop: "16px" }}
              />
            )}
          </div>

          <div className={style.upgradeImage}>
            <img src={image} />
          </div>
        </div>
      </div>
    );
  }

  return insideModal ? (
    renderContent()
  ) : (
    <Modal dimBackground={true} onClickBackground={onDismiss}>
      {renderContent()}
    </Modal>
  );
}
