import { useCustomerPortal } from "frontend/billingUtils";
import tracking from "frontend/tracking";
import React, { useEffect } from "react";
import consts from "shared/consts";
import style from "./billing-notification.module.css";

export default function BillingNotification({ accountId }: { accountId: string }) {
  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "billing-notification-shown", "account id", accountId);
  }, []);

  function renderPaymentNotification() {
    return (
      <div className={style.toolBarWarning}>
        <img src="/images/warning-i.svg" />
        <span className={style.description}>
          {`We had problem processing your payment. please update your payment method`}{" "}
          <a className={style.moreInfo} onClick={() => useCustomerPortal({ type: "update-payment" })}>
            here
          </a>
          .
        </span>
        <div className="spacer" />
      </div>
    );
  }

  return <React.Fragment>{renderPaymentNotification()}</React.Fragment>;
}
