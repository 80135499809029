import classNames from "classnames";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import { useRef } from "react";
import { useHover } from "usehooks-ts";
import style from "./toggle-button.module.css";

export default function ToggleButton({
  isOn,
  onClick,
  tooltip,
  children,
  className,
}: React.PropsWithChildren<{
  isOn: boolean;
  onClick: () => void;
  tooltip?: string;
  className?: string;
}>) {
  const clsName = classNames(style.toggleButton, className, {
    [style.selected]: isOn,
  });
  const ref = useRef<HTMLSpanElement>(null);
  const hover = useHover(ref);
  return (
    <>
      <span ref={ref} className={clsName} onClick={onClick} data-testid={tooltip}>
        {children}
      </span>
      {hover && tooltip && <Tooltip label={tooltip} relativeTo={ref} />}
    </>
  );
}
