import Checkout from "frontend/billing/checkout";
import tracking from "frontend/tracking";
import React, { useEffect, useState } from "react";
import consts from "shared/consts";
import { User } from "shared/datamodel/schemas/user";
import style from "./upgrade-from-free-notification.module.css";
import useFeatureValue from "frontend/hooks/use-features";


export default function UpgradeFromFreeNotification({ user, boards, renderedIn }: { user: User; boards: any; renderedIn?: string }) {
  const maxAllowedCanvasesInPlan = useFeatureValue(consts.FEATURE_NAMES.MAX_ALLOWED_CANVASES) ?? 3;
  const canvasesLeft = parseInt(maxAllowedCanvasesInPlan) - boards.length;

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-from-free-notification-shown");
  }, []);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  function upgradeClicked() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-from-free-notification-clicked", renderedIn);
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
    setShowUpgradeModal(true);
  }

  function renderTrialNotification() {
    return (
      <div className={style.toolbar}>
        <img src="/images/bell-i.svg" />
        <span className={style.description}>
          {
            <React.Fragment>
              {`Hey! ❤️ Just a heads up, you have `}
              <b>
                {canvasesLeft < 1 ? "no canvases" : `${canvasesLeft} ${canvasesLeft > 1 ? "canvases" : "canvas"}`}
              </b>{" "}
              {` left on your free plan. `}
              <span>Upgrade now to unlock unlimited canvases</span>
            </React.Fragment>
          }
        </span>
        <span onClick={upgradeClicked} className={style.link}>
          Upgrade Now
        </span>
        <div className="spacer" />
      </div>
    );
  }

  return (
    <React.Fragment>
      {showUpgradeModal && (
        <Checkout
          user={user}
          onDismiss={() => {
            setShowUpgradeModal(false);
          }}
        />
      )}
      {renderTrialNotification()}
    </React.Fragment>
  );
}
