import { Fonts } from "frontend/canvas-designer-new/frontend-consts";
import consts from "shared/consts";
import { DropdownButton } from "../toolbar-buttons";
import style from "./text-font-picker.module.css";

export default function TextFontPicker({
  value,
  onChange,
  multi,
  tooltip,
}: {
  value: string;
  onChange: (newStyle: string) => void;
  multi?: boolean;
  tooltip?: string;
}) {
  const curFontFace = multi ? consts.DEFAULTS.FONT : value.toLowerCase();

  function renderFontPopup(closePopup: () => void) {
    return (
      <>
        {Fonts.map((font) => (
          <span
            key={font}
            style={{ fontFamily: font.toLowerCase(), lineHeight: 2 }}
            data-testid={font}
            onClick={(e) => {
              onChange(e.currentTarget.textContent ?? "");
              closePopup();
            }}
          >
            {font}
          </span>
        ))}
      </>
    );
  }
  return (
    <DropdownButton
      tooltip={tooltip ?? "Font"}
      renderPopup={renderFontPopup}
      data-testid="TextFont"
      className={style.fontDropdownContainer}
    >
      <div className={style.button} style={{ fontFamily: curFontFace }}>
        {multi ? "Mixed fonts" : value}
      </div>
    </DropdownButton>
  );
}
