import { FontProperties } from "shared/datamodel/schemas/textEnabled";
import style from "./textAlignmentPicker.module.css";
import { Bold, Underline, Italic, LineThrough } from "frontend/icons/icons";
import ToggleButton from "./toggle-button";

export default function TextProps({
  value,
  onChange,
  multi,
}: {
  value: FontProperties;
  onChange: (newStyle: FontProperties) => void;
  multi?: boolean;
}) {
  if (multi) value = 0;
  return (
    <div className={style.container} data-testid="TextStyle">
      <ToggleButton
        tooltip="Bold"
        isOn={(value & FontProperties.Bold) != 0}
        onClick={() => {
          onChange(value ^ FontProperties.Bold);
        }}
      >
        <Bold size={20} />
      </ToggleButton>

      <ToggleButton
        tooltip="Italic"
        isOn={(value & FontProperties.Italic) != 0}
        onClick={() => {
          onChange(value ^ FontProperties.Italic);
        }}
      >
        <Italic size={20} />
      </ToggleButton>

      <ToggleButton
        tooltip="Underline"
        isOn={(value & FontProperties.Underline) != 0}
        onClick={() => {
          onChange(value ^ FontProperties.Underline);
        }}
      >
        <Underline size={20} />
      </ToggleButton>

      <ToggleButton
        tooltip="Strikethrough"
        isOn={(value & FontProperties.LineThrough) != 0}
        onClick={() => {
          onChange(value ^ FontProperties.LineThrough);
        }}
      >
        <LineThrough size={20} />
      </ToggleButton>
    </div>
  );
}
