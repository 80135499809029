import { CSSProperties, useEffect, useRef, useState } from "react";
import style from "./dropdown-picker.module.css";
import useOutsideRef from "frontend/utils/click-outside-handler";
import cn from "classnames";
import classNames from "classnames";
import OptionsPicker from "./options-picker";
import { InitialsIcon } from "frontend/ui-components/initialsIcon";
import FormInputField from "../form-fields/form-input-field";

export enum DropdownPickerStyle {
  Light = "light",
  Dark = "dark",
}

export default function DropdownPicker<T>({
  options,
  isSelected,
  titles,
  enabled,
  onChange,
  optionRenderer,
  multiValue = false,
  placeholder,
  scrollToSelected,
  optionsPickerStyle,
  pickerCustomIcon,
  pickerStyle,
  pickerCustomStyle,
  optionsContainerStyle,
  enableSearch = false,
  onSearch,
}: {
  options: T[];
  isSelected: (option: T) => boolean;
  titles: (option: T) => string;
  enabled: boolean;
  onChange: (option: T) => void;
  optionRenderer?: (
    option: T,
    selected: boolean
  ) => {
    element: JSX.Element;
    enabled: boolean;
    customStyle?: CSSProperties;
    handlesSelectedState?: boolean;
    customIcon?: string;
  };
  multiValue?: boolean;
  placeholder?: string;
  scrollToSelected?: boolean;
  optionsPickerStyle?: CSSProperties;
  pickerCustomIcon?: string;
  pickerStyle?: DropdownPickerStyle;
  pickerCustomStyle?: CSSProperties;
  optionsContainerStyle?: CSSProperties;
  enableSearch?: boolean;
  onSearch?: (search: string) => void;
}) {
  const [showPicker, setShowPicker] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const ref = useRef(null);

  useOutsideRef(ref, () => setShowPicker(false));

  useEffect(() => {
    if (!enableSearch) {
      return;
    }
    if (searchValue && onSearch) {
      onSearch(searchValue);
    }
  }, [searchValue, enableSearch]);

  function onChooseOption(option: T) {
    setSearchValue("");
    onChange(option);
    if (!multiValue) {
      setShowPicker(false);
    }
  }

  function renderOptions() {
    const filteredOptions = options.filter((option) => {
      if (!searchValue) {
        return true;
      }
      return titles(option).toLowerCase().includes(searchValue.toLowerCase());
    });
    return filteredOptions.map((option, index) => {
      const selected = isSelected(option);
      const {
        element,
        enabled,
        customStyle = {},
        handlesSelectedState = false,
      } = optionRenderer ? optionRenderer(option, selected) : { element: null, enabled: true };
      const title = element ?? titles(option);
      return (
        <div
          id={`${option}`}
          key={index}
          className={cn(style.option, {
            [style.selected]: selected && !handlesSelectedState,
            [style.disabled]: !enabled,
            [style.dark]: pickerStyle === DropdownPickerStyle.Dark,
          })}
          onClick={() => enabled && onChooseOption(option)}
          style={customStyle}
        >
          <div className={style.optionTitle} title={titles(option)}>
            {title}
          </div>
          {!handlesSelectedState && selected && <img src="/images/feature-check-i.svg" />}
        </div>
      );
    });
  }

  let selectedOptions = options.filter(isSelected);
  // if no placeholder, select first option by default
  if (!placeholder && options.length > 0 && selectedOptions.length === 0) {
    selectedOptions = [options[0]];
  }

  function renderSelectedOptions() {
    if (placeholder && selectedOptions.length === 0) {
      return (
        <div
          style={optionsPickerStyle}
          className={cn(style.placeholderTitle, { [style.dark]: pickerStyle === DropdownPickerStyle.Dark })}
          title={placeholder}
        >
          {placeholder}
        </div>
      );
    }
    const { element, enabled, customIcon } = optionRenderer
      ? optionRenderer(selectedOptions[0], false)
      : { element: null, enabled: true, customIcon: null };
    const selectedTitles = element ?? selectedOptions.map(titles).join(", ");
    return (
      <div
        style={{ ...optionsPickerStyle, ...pickerCustomStyle }}
        className={style.selectedTitle}
        title={typeof selectedTitles === "string" ? selectedTitles : titles(selectedOptions[0])}
      >
        {customIcon && <img className={style.customIcon} src={customIcon} />}
        {selectedTitles}
      </div>
    );
  }

  function renderIcon() {
    if (!enabled) {
      return null;
    }
    if (pickerCustomIcon) {
      return <img src={pickerCustomIcon} />;
    }
    return (
      <img
        src="/images/chevron.svg"
        className={classNames(style.chevron, {
          [style.dark]: pickerStyle === DropdownPickerStyle.Dark,
        })}
      />
    );
  }

  return (
    <div className={style.picker} ref={ref}>
      <div
        className={classNames(style.selectedOption, {
          [style.disabled]: !enabled,
          [style.dark]: pickerStyle === DropdownPickerStyle.Dark,
        })}
        onClick={() => setShowPicker(!showPicker)}
        style={pickerCustomStyle}
      >
        {renderSelectedOptions()}
        {renderIcon()}
      </div>
      {enabled && showPicker && (
        <OptionsPicker
          containerStyle={{ ...optionsPickerStyle, ...optionsContainerStyle }}
          scrollToSelected={scrollToSelected && selectedOptions.length > 0 ? titles(selectedOptions[0]) : undefined}
          pickerStyle={pickerStyle}
        >
          {enableSearch && (
            <div className={style.searchContainer}>
              <input
                type="text"
                value={searchValue}
                placeholder="Search..."
                autoFocus
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
              />
            </div>
          )}
          <div className={style.optionsList}>{renderOptions()}</div>
        </OptionsPicker>
      )}
    </div>
  );
}
