import { useEffect, useState } from "react";
import { getUploadLimits } from "frontend/api";
import { customAlphabet } from "nanoid";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { canvasElementPrefix, canvasMetadataPrefix, createElementId } from "shared/util/utils";
import { FileMetadata, FileMetadataType, FileUploadState } from "shared/datamodel/schemas/metadata";
import type { File as FileSchema } from "shared/datamodel/schemas/file";

import consts from "shared/consts";
import { ReadTransaction } from "@workcanvascom/reflect";

export enum UploadLimitsEnum {
  Unknown,
  Limited,
  Unlimited,
  Error,
}

export type UploadLimits = {
  limited: UploadLimitsEnum;
  limit?: number;
}

export type FileUploadProgress = {
  name: string;
  type: string;
  uppyFileId: string;
  pos: { x: number, y: number };
  complete: boolean;
  img: HTMLImageElement;
  meta: any;
  key: string;
}

export function useFilesLimitForBoard(board?: any): UploadLimits {
  const [filesLimits, setFilesLimits] = useState<UploadLimits>({ limited: UploadLimitsEnum.Unknown });
  useEffect(() => {
    if (board) {
      getUploadLimits(board.documentId)
        .then(response => {
          if (response.limited)
            setFilesLimits({ limited: UploadLimitsEnum.Limited, limit: response.limit });
          else
            setFilesLimits({ limited: UploadLimitsEnum.Unlimited });
        })
        .catch((error) => {
          setFilesLimits({
            limited: UploadLimitsEnum.Error
          })
        })
    }
  }, [board]);
  return filesLimits;
}

export function fileExt(filename: string) {
  const i = filename.lastIndexOf('.');
  return i == -1 ? '' : filename.substring(i + 1);
}


const filenameGen = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 12);

export function buildUploadUrl(accountId: string, documentId: string, filename: string, limited?: number) {
  if (limited == undefined) {
    return `${accountId}/${documentId}/${filenameGen()}-${Date.now()}.${fileExt(filename)}`;
  } else {
    return `${accountId}/${documentId}/limited/${limited}.${fileExt(filename)}`;
  }
}

export function buildMetadataEntry(filename: string, filetype: string, url: string, user: any, limitedslot: number = -1) {
  return {
    type: FileMetadataType,
    id: createElementId(),
    fileName: filename,
    url: url,
    limitedSlot: limitedslot,
    fileType: filetype,
    uploadedBy: user?.id,
    uploadState: FileUploadState.Completed,
    uploadProgress: 1,
    lastModifiedTimestamp: 0,
  } as FileMetadata;
}

export function countLimitedFilesInCanvas(syncService: SyncService<RW>) {
  return syncService.getReplicache()!.query(async (tx: ReadTransaction) => {
    let files: Record<string, any> = {}
    for await (const entry of tx.scan({ prefix: canvasMetadataPrefix + FileMetadataType })) {
      files[(entry as FileMetadata).id] = entry;
    };

    let filesInCanvas = tx.scan({ prefix: canvasElementPrefix + consts.CANVAS_ELEMENTS.FILE });
    let uploadsInProgress = 0;
    let oldStyleFiles = new Set<number>();
    let limitedSlotsUsed = new Set<number>();

    for await (const entry of filesInCanvas) {
      const file = entry as any as FileSchema;
      if (!file.hidden) {
        if (file.uploadStatus == "in-progress") {
          uploadsInProgress++;
        }
        else if (file.fileId) {
          // fileId has to be defined at this point, but I still check so compiler won't complain
          // legacy fileIds were numbers, newer ones are string
          if (typeof file.fileId == 'number')
            oldStyleFiles.add(Number(file.fileId));
          else if (file.fileId in files) {
            const {limitedSlot, fileName} = files[file.fileId];
            if (limitedSlot != undefined && limitedSlot != -1 && !fileName.startsWith("orgchart-photo"))
              limitedSlotsUsed.add(limitedSlot);
          }
        }
      }
    }
    return { limitedSlotsUsed, uploadsInProgress, oldStyleFiles: oldStyleFiles.size };
  });
}
