import { stringToPlan, updateAccountSeats } from "frontend/billingUtils";
import { useDebounce } from "frontend/hooks/debounce";
import useStateValue from "frontend/state/value";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { getPriceByPlanMapping } from "frontend/utils/billing-utils";
import useOutsideRef from "frontend/utils/click-outside-handler";
import { mondayURL } from "frontend/utils/url-utils";
import { useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { plansDataAtom } from "state-atoms/billing-atoms";
import style from "./edit-seats-modal.module.css";
import useSeatsCount from "frontend/hooks/use-seats-count";

export default function EditSeatsModal({ onDismiss }: { onDismiss: () => void }) {
  //atoms
  const plansData = useAtomValue(plansDataAtom);

  const [checkoutInProgress, setCheckoutInProgress] = useState(false);
  const [{ user }] = useStateValue();
  const [seatsCount, setSeatsCount] = useState(user?.planInfo?.seats_count! + 1);
  const { accountSubscribedCount } = useSeatsCount(user);

  const [showError, setShowError] = useState(false);
  const modalRef = useRef(null);

  const debouncedCount = useDebounce(seatsCount, 200);
  const debouncedShowError = useDebounce(showError, 200);

  const inputRef = useRef<HTMLInputElement>(null);

  const userPlan = stringToPlan(user!.planInfo!.name);
  const pricePerSeat = getPriceByPlanMapping(plansData)[userPlan!][user?.planInfo?.interval!];
 
  useEffect(() => {
    if(inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  },[])

  useEffect(() => {
    setSeatsCount(user?.planInfo?.seats_count! + 1);
  }, [accountSubscribedCount, user]);


  useEffect(() => {
    setSeatsCount(
      !isNaN(debouncedCount) ? Math.max(Math.max(0, accountSubscribedCount), debouncedCount) : 0
    );
  }, [debouncedCount])

  useEffect(() => {
    if(seatsCount <= accountSubscribedCount) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [seatsCount])

  useOutsideRef(modalRef, onDismiss);

  function renderArrow() {
    return (
      <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.33203 6H18.832" stroke="#0B2642" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.8281 11L18.8281 6" stroke="#0B2642" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.8281 1L18.8281 5.99999" stroke="#0B2642" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }

  function redirectToMondayAdmin() {
    const url = mondayURL(user!.account!.slug, `admin/my-team/my-team`);
    window.open(url, "__blank")?.focus();
  }

  function renderNoMoreSeatsToRemoveError() {
    return (
      <div className={style.errorContainer}>
        <span className={style.errorText}>You have no more unused seats to remove. You can </span>
        <br />
        <span className={style.errorText}>remove more users from</span>
        <span onClick={redirectToMondayAdmin} className={style.adminLink}>
          {" "}
          your account’s admin page.
        </span>
      </div>
    );
  }

  function renderSeatsStatus() {
    return (
      <div className={style.seatsStatusContainer}>
        <div className={style.seatStatusContent}>
          <span className={style.seatsStatusText}>{`${accountSubscribedCount}/${seatsCount} seats used`}</span>
          <div className={style.progressContainer}>
            <div className={style.progressBar} style={{ width: `${(accountSubscribedCount / seatsCount) * 100}%` }} />
          </div>
        </div>
      </div>
    );
  }

  function getSeatsDeltaInfo() {
    const seatsDelta = seatsCount - user!.planInfo!.seats_count;
    const absSeatsDelta = Math.abs(seatsDelta);
    const seatsText = absSeatsDelta > 1 ? "seats" : "seat";
    const actionText = seatsDelta >= 0 ? "added" : "removed";
    const fullText = `${seatsText} ${actionText}`;
    return {
      text: fullText,
      delta: seatsDelta,
      absoluteDelta: Math.abs(seatsDelta),
    };
  }

  return (
    <div className={style.container} ref={modalRef}>
      <div className={style.header}>
        <span>Manage seats</span>
      </div>
      <div className={style.seatsEditorContainer}>
        <div className={style.seatsEditorContent}>
          <span data-testid={"current seat count"}>{`${user?.planInfo?.seats_count} seats`}</span>
          {renderArrow()}
          <input
            ref={inputRef}
            placeholder={seatsCount.toString()}
            onChange={(e) => {
              setSeatsCount(parseInt(e.target.value));
            }}
            type={"number"}
            value={seatsCount}
            min={accountSubscribedCount}
          />
          <span>{"seats"}</span>
        </div>
      </div>
      <div className={style.paymentDetailsContainer}>
        <div className={style.paymentRow}>
          <span>Existing bill:</span>
          <span>{`$${(user!.planInfo!.seats_count * pricePerSeat).toFixed(2)} / Month`}</span>
        </div>
        <div className={style.paymentRow}>
          <span>{`${getSeatsDeltaInfo().absoluteDelta} ${getSeatsDeltaInfo().text}`}</span>
          <span>{`$${(getSeatsDeltaInfo().delta * pricePerSeat).toFixed(2)} / Month`}</span>
        </div>
        <div className={style.separator} />
        <div className={style.totalPaymentRow}>
          <span>Total new bill:</span>
          <span>{`$${(getSeatsDeltaInfo().delta * pricePerSeat + user!.planInfo!.seats_count * pricePerSeat).toFixed(2)} / Month`}</span>
        </div>
      </div>
      {debouncedShowError && renderNoMoreSeatsToRemoveError()}
      <div className={style.buttonsContainer}>
        <StyledButton
          title="Cancel"
          loading={false}
          onClick={onDismiss}
          enabled={true}
          style={ButtonStyle.outline}
        />
        <StyledButton
          title="Update Seats"
          loading={checkoutInProgress}
          onClick={async () => {
            setCheckoutInProgress(true);
            try {
              await updateAccountSeats(seatsCount);
              window.location.reload();
            } catch (error) {
              console.error("Failed to update seats", error);
            } finally {
              setCheckoutInProgress(false);
            }
          }}
          enabled={seatsCount >= accountSubscribedCount}
        />
      </div>
    </div>
  );
}
