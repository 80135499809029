import tracking from "frontend/tracking";
import StyledButton, { ButtonSize } from "frontend/ui-components/styled-button";
import { useEffect } from "react";
import { useKey } from "react-use";
import consts from "shared/consts";
import style from "./restricted-user-notice-modal.module.css";
import Modal from "frontend/modal/modal";
import { useRestricted } from "frontend/hooks/use-restricted";
import AskToEditToast from "frontend/ui-components/toast/ask-to-edit-toast";

export default function RestrictedUserNoticeModal({
  trackingEvent,
  onDismiss,
}: {
  trackingEvent: string;
  onDismiss: () => void;
}) {
  useKey("Escape", onDismiss);
  const { isAskToEditEnable, handleAskToEditClick, showAskToEditToast } = useRestricted();
  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.RESTRICTED_ACTION, "restricted-modal-shown", trackingEvent);
  }, []);

  const image = "/images/restricted.png";

  function renderContent() {
    return (
      <div className={style.centerScreen}>
        <div className={style.mainLayout}>
          <button className={style.closeButton} onClick={onDismiss} data-testid="close-upgrade-modal" />
          <div className={style.modal}>
            <img src={"/images/logo.svg"} style={{ width: 125, height: 25 }} />
            <div>
              <h1>{"You are a viewer, but don’t worry! "}</h1>
              <h2>
                <span className={style.title}>{"We've got your back"}</span>
              </h2>
            </div>
            <div>
              <p>
                Starting from today, an account admin needs to assign a WorkCanvas seat for your user to give you an
                editor access.
              </p>
              <p>It’s a one click process.</p>
              <a
                href="https://support.monday.com/hc/en-us/articles/19176360344978-WorkCanvas-user-management"
                target="_blank"
              >
                Show detailed instructions
              </a>
            </div>
            <StyledButton
              title={isAskToEditEnable ? "Request License" : "Request Sent"}
              size={ButtonSize.regular}
              onClick={() => handleAskToEditClick("restricted-notice-modal")}
              customStyle={{
                alignSelf: "center",
                width: "100%",
                marginTop: "auto",
                fontSize: 15,
                fontWeight: 500,
                fontFamily: "Sora",
              }}
              enabled={isAskToEditEnable}
            />
          </div>
          <div className={style.modalImage}>
            <img src={image} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal dimBackground={true} onClickBackground={onDismiss}>
        {renderContent()}
        {showAskToEditToast && <AskToEditToast />}
      </Modal>
    </>
  );
}
