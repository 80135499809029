export enum CursorType {
  pointer = "POINTER",
  move = "MOVE",
  template = "TEMPLATE",
  text = "TEXT",
  stickyNote = "STICKY_NOTE",
  connector = "CONNECTOR",
  mindmap = "MINDMAP",
  mindmapOrgChart = "MINDMAP_ORG_CHART",
  orgChart = "ORG_CHART",
  draw = "DRAW",
  shape = "SHAPE",
  file = "FILE",
  taskCard = "TASK_CARD",
  frame = "FRAME",
  newCanvas = "NEW-CANVAS",
  comment = "COMMENT",
  cardStack = "CARD_STACK",
  liveIntegration = "LIVE_INTEGRATION",
  sideMenuOverflow = "SIDE-MENU-OVERFLOW",
  mondayIntegration = "MONDAY_INTEGRATION",
  timeline = "TIMELINE",
  table = "TABLE",
}


/**
 * This tells us if a cursor type represents an element which counts towards the limit for basic-plan users
 * I can't put this in shared/util/utils.ts, because the backend gets that file and
 * it doesn't have access to cursor-type
 */
export const isCursorTypeLimited: Record<CursorType, boolean> = {
  [CursorType.text]: true,
  [CursorType.stickyNote]: true,
  [CursorType.connector]: true,
  [CursorType.mindmap]: true,
  [CursorType.mindmapOrgChart]: true,
  [CursorType.orgChart]: true,
  [CursorType.shape]: true,
  [CursorType.file]: true,
  [CursorType.taskCard]: true,
  [CursorType.frame]: true,
  [CursorType.liveIntegration]: true,
  [CursorType.cardStack]: true,
  [CursorType.timeline]: true,
  [CursorType.table]: true,
  // ----------
  [CursorType.template]: false,
  [CursorType.draw]: false,
  [CursorType.mondayIntegration]: false,
  [CursorType.pointer]: false,
  [CursorType.move]: false,
  [CursorType.newCanvas]: false,
  [CursorType.comment]: false,
  [CursorType.sideMenuOverflow]: false,
};
