import { getSignupURL } from "frontend/hooks/use-authentication";
import { BoardConfig, defaultBoardConfig } from "frontend/state/board-state";
import useStateValue from "frontend/state/value";
import tracking from "frontend/tracking";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { openURL } from "frontend/utils/window-utils";
import React, { useEffect, useRef, useState } from "react";
import consts from "shared/consts";
import style from "./template-preview.module.css";
import { CanvasTemplateMinimal } from "../../shared/datamodel/schemas/canvas-template";
import MiniStage from "frontend/canvas-designer-new/mini-stage";
import AppLoader from "frontend/loader/app-loader";
import Checkout from "frontend/billing/checkout";
import useFeatureValue from "frontend/hooks/use-features";

export default function TemplatePreview({
  template,
  onChooseTemplate,
  onUpgradeModalShown,
  onUpgradeModalHidden,
  boardConfig = defaultBoardConfig,
  onDismiss,
}: {
  template: CanvasTemplateMinimal;
  onChooseTemplate: (template: CanvasTemplateMinimal) => void;
  onUpgradeModalShown?: () => void;
  onUpgradeModalHidden?: () => void;
  boardConfig?: BoardConfig;
  onDismiss?: () => void;
}) {
  const isIntegrationTemplate = !!template.metadata?.mondaySolutionId || template.alias=='org-chart-integration';

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(isIntegrationTemplate);
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const isMondayIntegrationAllowed = useFeatureValue(consts.FEATURE_NAMES.MONDAY_INTEGRATION) === "true";

  const shouldRenderPreview = useRef(true);

  const [{ user }] = useStateValue();

  const title = template.name + " Template";
  const description = template.description;
  const upgradeRequired = template.upgradeRequired || (!isMondayIntegrationAllowed && isIntegrationTemplate);

  useEffect(() => {
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.TEMPLATES,
      "template-preview-shown",
      `${upgradeRequired ? "upgrade required" : "no upgrade required"}`,
      template.name
    );
  }, []);

  useEffect(() => {
    if (showUpgradeModal) {
      onUpgradeModalShown && onUpgradeModalShown();
    } else {
      onUpgradeModalHidden && onUpgradeModalHidden();
    }
  }, [showUpgradeModal]);

  function renderUpgradeIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
        <path
          d="M8.5 1L11.8333 7L16 3L14.3333 13H2.66667L1 3L5.16667 7L8.5 1Z"
          stroke="#7517F8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function renderUpgradeFooter() {
    return (
      <div onClick={upgrade} className={style.upgradeFooter}>
        <div className={style.upgradeFooterContent}>
          {renderUpgradeIcon()}
          <span className={style.upgradeText}>
            Upgrade to unlock <span className={style.upgradeLink}>all of our pro templates</span>
          </span>
        </div>
      </div>
    );
  }

  function upgrade() {
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", `paid-template`, template.name);
    if (boardConfig.isOpenSession) {
      const url = getSignupURL();
      openURL(url);
    } else {
      setShowUpgradeModal(true);
    }
  }

  function handleCopyLink() {
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.TEMPLATES,
      "preview_link_copied",
    );
    navigator.clipboard.writeText(window.location.href).then(()=>setShowCopySuccess(true)).catch(()=>setShowCopySuccess(false));
    setShowCopySuccess(true);
    setTimeout(() => setShowCopySuccess(false), 2000);
  }

  function renderTemplatePreview(previewType: "image" | "canvas") {
    switch (previewType) {
      case "image":
        return (
          <div className={style.previewImageContainer}>
            <div className={style.previewImage}>
              {template.previewImageURL && <img src={template.previewImageURL!} />}
            </div>
            {upgradeRequired && renderUpgradeFooter()}
          </div>
        );
      case "canvas":
        return (
          <div className={style.canvasPreview}>
            <MiniStage width={511} height={368} templateId={template.id} onLoad={setPreviewLoaded} />
            {false && upgradeRequired && renderUpgradeFooter()}
          </div>
        );
      default:
        break;
    }
  }

  function renderPreview() {
    const isDowngraded = user?.planInfo?.is_downgraded;
    const upgradeText = isDowngraded ? "Upgrade to unlock" : "Try it Free"; //'Upgrade to unlock';
    const renderType = isIntegrationTemplate ? "image" : "canvas";
    return (
      <>
        <div className={style.copyLinkButton}>
          <StyledButton
            title={showCopySuccess ? "Link Copied!" : "Copy link to share"}
            onClick={handleCopyLink}
            icon={"/images/template-previews/link-icon.svg"}
            style={ButtonStyle.outline}
            customStyle={{ minWidth: "179px" }}
          />
        </div>
        <div className={style.container}>
          <div className={style.content}>
            <span className={style.title}>{title}</span>
            <span className={style.description}>{description}</span>
            <div data-testid="usetemplatebutton">
              <StyledButton
                title={upgradeRequired ? upgradeText : "Use Template"}
                onClick={() => (upgradeRequired ? upgrade() : onChooseTemplate(template))}
                icon={upgradeRequired ? "/images/crown-gold.svg" : null}
                customStyle={{ position: "relative", top: "12px" }}
                style={upgradeRequired ? ButtonStyle.outline : ButtonStyle.filled}
              />
            </div>
          </div>
          {template && (
            <div className={style.previewContainer}>
              {!previewLoaded && <AppLoader customStyle={{ position: "absolute", inset: 0, transform: "unset" }} />}
              {renderTemplatePreview(renderType)}
            </div>
          )}
        </div>
      </>
    );
  }

  function renderUpgradeModal() {
    const sourceName = isIntegrationTemplate ? "monday-integration" : "templates";
    return (
      <Checkout
        user={user!}
        source={{ name: sourceName, value: template.name }}
        onDismiss={() => {
          shouldRenderPreview.current = false;
          setShowUpgradeModal(false);
          onDismiss && onDismiss();
        }}
      />
    );
  }

  return showUpgradeModal ? (
    renderUpgradeModal()
  ) : shouldRenderPreview.current ? (
    renderPreview()
  ) : (
    <React.Fragment></React.Fragment>
  ); //We are doing these checks to fix a bug where when we closed the payment modal, the preview would load again and a result,
  // the mini stage would load and would execute a network call to fetch the templat's data
}
