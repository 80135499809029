import { useRef } from "react";
import style from "./monday-status-picker.module.css";
import { useOnClickOutside } from "usehooks-ts";

export default function MondayStatusPicker({
  onSelect,
  column,
  onDismiss,
}: {
  onSelect: (id: string) => void;
  column: any;
  onDismiss: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, onDismiss);

  function renderStatuses() {
    const settings = column.settings_str ? JSON.parse(column.settings_str) : {};
    const positions: { [labelId: string]: number } = settings.labels_positions_v2;
    let orderedStatusIndexes: [string, any][] = [];
    if (positions) {
      orderedStatusIndexes = Object.entries(positions).sort(([, index1], [, index2]) => index1 - index2);
    } else {
      orderedStatusIndexes = Object.entries(settings.labels);
    }
    const statuses = orderedStatusIndexes.map(([labelId]) => {
      const { color = "#797e93" } = settings.labels_colors[labelId] ?? {};
      return {
        id: labelId,
        title: settings.labels[labelId] ?? "Unset",
        color,
      };
    });
    return statuses.map(({ id, title, color }) => (
      <div key={id} className={style.status} style={{ backgroundColor: color }} onClick={() => onSelect(id)}>
        {title}
      </div>
    ));
  }

  return (
    <div ref={ref} className={style.container}>
      {renderStatuses()}
    </div>
  );
}
