import { IRect } from "../utils/math-utils";

class BoundingBox implements DOMRectReadOnly, IRect {
  constructor(
    public minX = Number.MAX_SAFE_INTEGER,
    public minY = Number.MAX_SAFE_INTEGER,
    public maxX = Number.MIN_SAFE_INTEGER,
    public maxY = Number.MIN_SAFE_INTEGER
  ) { }

  static from = (xmin: number, ymin: number, xmax: number, ymax: number) => new BoundingBox(xmin, ymin, xmax, ymax);
  static fromRect = (rect: IRect) => BoundingBox.from(rect.x, rect.y, rect.x + rect.width, rect.y + rect.height);
  static fromCoords = (xys: number[]) => {
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    for (let i = 0; i < xys.length; i += 2) {
      minX = Math.min(minX, xys[i]);
      minY = Math.min(minY, xys[i + 1]);
      maxX = Math.max(maxX, xys[i]);
      maxY = Math.max(maxY, xys[i + 1]);
    }
    return new BoundingBox(minX, minY, maxX, maxY);
  };

  static empty = () => new BoundingBox();

  static concat = (a: BoundingBox, b: BoundingBox) => new BoundingBox(
    Math.min(a.minX, b.minX),
    Math.min(a.minY, b.minY),
    Math.max(a.maxX, b.maxX),
    Math.max(a.maxY, b.maxY)
  );

  public isValid = (b: BoundingBox) => b.minX <= b.maxX && b.minY <= b.maxY;

  public contains = (other: IRect) => {
    return other.x >= this.minX && other.y >= this.minY && other.x + other.width <= this.maxX && other.y + other.height <= this.maxY;
  }

  public addPadding = (padding: number) => new BoundingBox(
    this.minX - padding,
    this.minY - padding,
    this.maxX + padding,
    this.maxY + padding
  );

  public static expandOnAll(rects: IRect[]) {
    let total = new BoundingBox();
    for (const rect of rects) {
      total.expandRect(rect);
    }
    return total;
  }

  toJSON() {
    return `{x:${this.left},y:${this.top},width:${this.width},height:${this.height}}`;
  }

  public expandRect(rect: IRect) {
    this.minX = Math.min(this.minX, rect.x);
    this.minY = Math.min(this.minY, rect.y);
    this.maxX = Math.max(this.maxX, rect.x + rect.width);
    this.maxY = Math.max(this.maxY, rect.y + rect.height);
    return this;
  }

  public expandPoint(x: number, y: number) {
    this.minX = Math.min(this.minX, x);
    this.minY = Math.min(this.minY, y);
    this.maxX = Math.max(this.maxX, x);
    this.maxY = Math.max(this.maxY, y);
    return this;
  }

  get left() {
    return this.minX;
  }
  get right() {
    return this.maxX;
  }
  get top() {
    return this.minY;
  }
  get bottom() {
    return this.maxY;
  }
  get x() {
    return this.minX;
  }
  get y() {
    return this.minY;
  }
  get width() {
    return this.maxX - this.minX;
  }
  get height() {
    return this.maxY - this.minY;
  }
  get size() {
    return { width: this.width, height: this.height };
  }
  get center() {
    return {x: this.minX + this.width / 2, y: this.minY + this.height / 2};
  }
  asRect() {
    return ({ x: this.x, y: this.y, width: this.width, height: this.height });
  }
}

export default BoundingBox;