import { MondayLogo } from "frontend/icons/monday-logo";
import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";

export default function UpgradeModalForMondayIntegration(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="monday-integration"
      title={
        <span>
          Upgrade to integrate with <MondayLogo height={8.83} width={15} /> <b>monday</b>
          <small>.com</small>
        </span>
      }
      content={"Connect your monday.com account to keep your data up to date with our two-way sync."}
      bulletpoints={[
        "Blazing fast multiplayer",
        "Task management",
        "Commenting",
        "A world of templates",
        "Quality file export",
      ]}
      image={"/images/upgrade-modal-images/upgrade-for-monday-integration.png"}
      {...props}
    />
  );
}
