export function dateDiff(dateA:Date, dateB:Date) {
    const diff = Math.floor(dateA.getTime() - dateB.getTime());
    const day = 1000 * 60 * 60 * 24;
    const days = Math.floor(diff/day);
    const months = Math.floor(days/31);
    const years = Math.floor(months/12);
    return {days, months, years}
}

export function addDays(time:any, days:number) {
   /* (Date.prototype as any).addDays = function(days:number) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }*/
   // console.log('addDays before',{time: time.getTime(),days})
    const timeStamp = Math.floor(time.getTime() / 1000) + days * 24 * 60 * 60
    //const timeStamp = time.setDate(time.getDate() + days);
   // console.log('addDays after',{time: time.getTime(),days})
    return timeStamp;
}

export function removeDays(time:any, days:number) {
    /* (Date.prototype as any).addDays = function(days:number) {
         var date = new Date(this.valueOf());
         date.setDate(date.getDate() + days);
         return date;
     }*/
     //console.log('addDays before',{time: time.getTime(),days})
     const timeStamp = Math.floor(time.getTime() / 1000) - days * 24 * 60 * 60
     //const timeStamp = time.setDate(time.getDate() + days);
     //console.log('addDays after',{time: time.getTime(),days})
     return timeStamp;
 }

 export function getFormatedDate(date:Date) {
    return `${date.toLocaleString('default', { month: 'long' })} ${date.getUTCDate()}`
 }