import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./editable-text.module.css";
import { Tooltip } from "./floaters/tooltip";

export enum EditableTextStyle {
  card = "text-style-card",
  header = "text-style-header",
  regular = "text-style-regular",
}

export default function EditableText({
  value,
  editable,
  onChange,
  onEditing = () => {},
  textStyle,
  style = {},
  onShowNonEditableMessage = () => {},
  focus = false,
  showTooltipOnHover = false,
}: {
  value: string;
  editable: boolean;
  onChange: (value: string) => void;
  onEditing?: (editing: boolean) => void;
  textStyle: EditableTextStyle;
  style?: any;
  onShowNonEditableMessage?: () => void;
  focus?: boolean;
  showTooltipOnHover?: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [tooltip, setTooltip] = useState(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<any>(null);

  useEffect(() => {
    onEditing(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (focus) {
      setIsEditing(true);
      setTimeout(() => inputRef.current?.focus(), 50);
    }
  }, [focus]);

  function handleKeyDown(e: any) {
    if (e.key == "Enter") {
      handleTitleChange(e);
    } else if (e.key == "Escape") {
      setIsEditing(false);
    }
  }

  function handleTitleChange(e: any) {
    setIsEditing(false);

    const newValue = e.target.value;
    if (newValue === value) {
      e.target.value = value;
      return;
    }

    onChange(newValue);
  }

  function clicked(e: any) {
    e.stopPropagation();
    setIsEditing(true);
  }

  return (
    <div
      className={cn({ title: true, [textStyle]: true, editable })}
      onClick={editable ? clicked : onShowNonEditableMessage}
      style={style}
      onMouseEnter={() => {
        const isTextOverflowing = valueRef.current && valueRef.current.scrollWidth > valueRef.current.clientWidth;
        isTextOverflowing ? setTooltip(valueRef.current.innerText) : setTooltip(null);
      }}
      onMouseLeave={() => setTooltip(null)}
    >
      <div
        className={cn({
          inputBorder: true,
          show: isEditing,
        })}
      />
      {isEditing ? (
        <input
          ref={inputRef}
          type="text"
          className="titleInput"
          defaultValue={value}
          autoFocus={true}
          onKeyDown={handleKeyDown}
          onBlur={handleTitleChange}
          onFocus={(e: any) => e.target.select()}
        />
      ) : (
        [
          <span className={cn(styles.textOverflow)} key={1} ref={valueRef}>
            {value}
          </span>,
          <>
            {showTooltipOnHover && tooltip && (
              <Tooltip
                relativeTo={valueRef}
                side={"bottom"}
                label={value}
                customStyle={{
                  width: "fit-content",
                  backgroundColor: "#ebedf3",
                  color: "#113357",
                  padding: "0 12px",
                  boxShadow: "0px 2px 4px 0px #00000026",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  font: "400 16px Poppins",
                  whiteSpace: "nowrap",
                  height: 36,
                }}
                withArrow={false}
              />
            )}
          </>,
          <img key={2} src="/images/pencil-icon.svg" className="editTitleIcon" />,
        ]
      )}
      <style jsx>{`
        .title {
          display: inline-flex;
          position: relative;
          display: inline-flex;
          gap: 5px;
        }

        .${EditableTextStyle.card}.title {
          width: 100%;
          padding: 0 10px;
        }

        .${EditableTextStyle.header}.title {
          padding: 0 20px;
        }

        .${EditableTextStyle.regular}.title {
          padding: 4px;
          margin: 0 4px;
        }

        .title span {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .title span,
        .title img {
          pointer-events: none;
        }

        .title span {
          position: relative;
        }

        .title span,
        .titleInput {
          font-family: Poppins, Roboto, Arial;
          font-weight: 500;
          text-align: center;
          color: #0b2642;
        }

        .titleInput {
          width: auto;
        }

        .${EditableTextStyle.card}.title span,
        .${EditableTextStyle.card} .titleInput {
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.2px;
          height: 24px;
        }

        .${EditableTextStyle.header}.title span,
        .${EditableTextStyle.header} .titleInput {
          font-size: 20px;
          font-weight: 300;
          line-height: 30px;
          letter-spacing: 0.2px;
          height: 32px;
        }

        .${EditableTextStyle.regular}.title span,
        .${EditableTextStyle.regular} .titleInput {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.2px;
          height: 18px;
          text-align: left;
          padding-right: 15px;
        }

        .titleInput,
        .title.editable:hover {
          border: none;
          cursor: text;
        }

        .inputBorder {
          position: absolute;
          left: -5px;
          top: -1px;
          right: -5px;
          bottom: 0px;
        }

        .editable .inputBorder:hover,
        .editable .inputBorder.show {
          background-color: white;
          border-radius: 3px;
          border: 0.5px solid #c1c0cc;
        }

        .titleInput:focus {
          outline: none;
          transform: translate(-2px, -1px);
          width: 100%;
          background-color: transparent;
        }

        .editTitleIcon {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
        }

        .title.editable:hover .editTitleIcon {
          opacity: 1;
        }
      `}</style>
    </div>
  );
}
