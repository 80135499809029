import { useHover } from "frontend/ui-components/floaters/use-hover";
import { useAtomValue } from "jotai";
import { useRef } from "react";
import { Html } from "react-konva-utils";
import { posScaleAtom } from "state-atoms/stage-atoms";

export function CanvasElementLinkBadge({
  url,
  scaleX,
  scaleY,
  x,
  y,
}: {
  url: string;
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
}) {
  const scale = useAtomValue(posScaleAtom).scale;

  const ref = useRef<any>(null);
  const hover = useHover(ref);

  const width = 20;
  const height = 20;
  const imgWidth = width * 0.6;
  const imgHeight = height * 0.6;
  const padding = 10;

  function click() {
    let openURL = url;
    if (!url.includes("http://") && !url.includes("https://")) {
      openURL = "https://" + url;
    }
    window.open(openURL, "_blank");
  }

  return (
    <Html
      groupProps={{
        x: x - width / scaleX / scale - padding / scaleX,
        y: y - height / scaleY / scale - padding / scaleY,
        scaleX: 1 / scaleX / scale,
        scaleY: 1 / scaleY / scale,
      }}
      divProps={{
        style: {
          cursor: "pointer",
        },
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width,
          height,
          borderRadius: 4,
          boxShadow: "0px 8px 8px 0px rgb(0 0 0 / 10%)",
        }}
        onClick={click}
        ref={ref}
      >
        <svg
          width={imgWidth}
          height={imgHeight}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
        >
          <path
            d="M4.75537 7.24446C4.95814 7.45142 5.20018 7.61583 5.46729 7.72807C5.7344 7.84031 6.02122 7.89813 6.31096 7.89813C6.6007 7.89813 6.88752 7.84031 7.15463 7.72807C7.42174 7.61583 7.66377 7.45142 7.86655 7.24446L10.3555 4.75552C10.7681 4.34295 10.9998 3.78339 10.9998 3.19993C10.9998 2.61648 10.7681 2.05691 10.3555 1.64435C9.94292 1.23178 9.38336 1 8.7999 1C8.21644 1 7.65688 1.23178 7.24431 1.64435L6.93319 1.95546"
            stroke="#848199"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.24446 4.75553C7.04169 4.54858 6.79966 4.38417 6.53255 4.27192C6.26544 4.15968 5.97861 4.10187 5.68888 4.10187C5.39914 4.10187 5.11232 4.15968 4.8452 4.27192C4.57809 4.38417 4.33606 4.54858 4.13329 4.75553L1.64435 7.24447C1.23178 7.65704 1 8.2166 1 8.80006C1 9.38352 1.23178 9.94308 1.64435 10.3557C2.05691 10.7682 2.61648 11 3.19993 11C3.78339 11 4.34295 10.7682 4.75552 10.3557L5.06664 10.0445"
            stroke="#848199"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {hover && (
          <div
            style={{
              position: "absolute",
              background: "#fafafa",
              borderRadius: 4,
              top: height + 8,
              padding: "2px 4px",
              boxShadow: "0px 2px 2px 0px rgb(0 0 0 / 20%)",
              pointerEvents: "none",
              fontSize: 12,
            }}
          >
            {url}
          </div>
        )}
      </div>
    </Html>
  );
}
