import { BubbleFieldMutation, BubbleFieldValue } from "frontend/ui-components/form-fields/form-bubbles-field";
import { InviteResponse } from "frontend/utils/invite-utils";
import { useEffect, useState } from "react";
import { Permission, User } from "shared/datamodel/schemas";
import { State, Team } from "shared/datamodel/schemas/team";
import { InviteMode, isBusyAtom } from "state-atoms";
import { useTeam } from "./use-team";
import consts from "shared/consts";
import tracking from "frontend/tracking";
import { Project } from "shared/datamodel/schemas/project";
import { useProject } from "./use-project";
import { isEmail } from "frontend/utils/string-utils";
import { useSetAtom } from "jotai";
import { inviteUser as internalInviteUser } from "frontend/api";

type InviteActions = {
    team?: Team;
    teamName?: string;
    existingUsers?: User[];
    createTeam?: (
      teamName: string,
      users: User[],
      permission?: Permission,
      state?: State | null,
      showManageTeamModal?: boolean
    ) => void;
    editTeamMembers?: ({    
      team,
      users,
      permission,
      state,
      showManageTeamModal
    }:{
      team: Team, 
      users: User[], 
      permission?: Permission, 
      state?: State | null, 
      showManageTeamModal?: boolean
    }) => void;
    usersPermission?: Permission;
    project?: Project;
    editProjectMembers?: ({    
      project,
      users,
      state,
      showManageProjectModal,
    }: {
      project: Project,
      users: User[] | [], 
      state: State | null,
      showManageProjectModal?: boolean,
    }) => void;
}

export function useInvite() {
    const [inviteUsersResponse, setInviteUsersSuccessResponse] = useState<InviteResponse | null>(null);
    const [highlightInvalidEmails, setHighlightInvalidEmails] = useState(false);
    const [emails, setEmails] = useState<BubbleFieldValue[]>([]);
    const [submittingEmail, setSubmittingEmail] = useState(false);
    const [isLoadingInvite, setIsLoadingInvite] = useState(false);
    const [canSubmitEmail, setCanSubmitEmail] = useState(false);
    const setIsBusy = useSetAtom(isBusyAtom);    

    const { handleCreateTeam, handleEditTeamMembers, getDefaultAccountTeam } = useTeam();
    const { handleEditProjectMembers, handleCreateProjectWithoutAddingCanvases } = useProject();

    useEffect(() => {
      setCanSubmitEmail(!submittingEmail);
    }, [submittingEmail]);

    useEffect(() => {
      setIsLoadingInvite(false);
    }, [inviteUsersResponse]);

    function getInviteActions({
      usersPermission, 
      inviteMode, 
      team,
      teamName, 
      existingUsers = [],
      project,
    }: {
      usersPermission: Permission;
      inviteMode: InviteMode;
      team?: Team;
      teamName?: string;
      existingUsers?: User[];
      project?: Project;
    }): InviteActions {
      const shouldCreateTeam = inviteMode === InviteMode.Create && teamName;
      if (shouldCreateTeam) {
        return {
          teamName: teamName,
          existingUsers,
          createTeam: handleCreateTeam,
          usersPermission,
        }
      } else if (team?.accountTeam) {
        return {
          team: team,
          existingUsers: [],
          editTeamMembers: handleEditTeamMembers,
          usersPermission,
          project,
          editProjectMembers: handleEditProjectMembers,
        }
      }
      else {
        return {
          team: team,
          existingUsers,
          editTeamMembers: handleEditTeamMembers,
          usersPermission,
          project,
          editProjectMembers: handleEditProjectMembers,
        }
      };
    };

    function inviteMembersToTeamAndProject({inviteActions, newUsers}:{inviteActions: InviteActions, newUsers: User[]}) {
      const { existingUsers, team, teamName, createTeam, usersPermission, editTeamMembers, project, editProjectMembers } = inviteActions;
      const teamUsers = [...existingUsers ?? [], ...newUsers];
      const shouldCreateTeam = createTeam && teamName && usersPermission !== null;
      const shouldEditTeamMembers =  editTeamMembers && team && usersPermission !== null;
      const shouldInviteToProject = project && editProjectMembers;
      if (shouldCreateTeam) {
        createTeam(teamName, teamUsers, usersPermission);
      } else if (shouldEditTeamMembers) {
        editTeamMembers({team, users: teamUsers, permission: usersPermission, state: State.active});
      }
      if (shouldInviteToProject) {
        editProjectMembers({project, users: newUsers, state: State.active});
      }
    }

    function handleInviteSuccessfulResponse({ 
      newUsers, 
      failed, 
      addedSeats,  
      inviteActions, 
      source,
      onDismiss,
    }:{ 
      newUsers: User[], 
      failed: string[], 
      addedSeats: number, 
      inviteActions: InviteActions, 
      source: string ,
      onDismiss?: () => void;
    }) {
      setEmails([]);
      const response: InviteResponse = {
        usersCount: newUsers.length,
        failed,
        addedSeats: addedSeats,
      };
      setInviteUsersSuccessResponse(response);
      if (failed.length === 0) {
        const shouldInviteToTeamOrProject = !inviteActions?.team?.accountTeam || inviteActions.project;
        shouldInviteToTeamOrProject && inviteMembersToTeamAndProject({inviteActions, newUsers});
      }
      tracking.trackEvent(
        consts.TRACKING_CATEGORY.ADMIN_ACTION,
        "invite_completed",
        source,
        newUsers.length,
        failed.length,
        failed.map((email) => tracking.getDomain(email)),
      );
      setIsBusy(false);
      onDismiss && onDismiss();
    };

  function handleInviteResponse({
    newUsers,
    failed,
    addedSeats,
    source,
  }: {
    newUsers: User[];
    failed: string[];
    addedSeats: number;
    source: string;
  }) {
    setEmails([]);
    const response: InviteResponse = {
      usersCount: newUsers.length,
      failed,
      addedSeats: addedSeats,
    };
    setInviteUsersSuccessResponse(response);
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.ADMIN_ACTION,
      "invite_completed",
      source,
      newUsers.length,
      failed.length,
      failed.map((email) => tracking.getDomain(email))
    );
    setIsBusy(false);
  }

    function handleInvite(params: {
      source: string;
      dispatch: any;
      canvasName: string | undefined;
      inviteActions: InviteActions | null;
      invitePermission?: Permission;
      usersFromList?: User[];
      onDismiss?: () => void;
    }) {
      const { source, dispatch, canvasName, inviteActions, invitePermission, usersFromList } = params;
      if (!canSubmitEmail) {
        return;
      }
      const emailValues = [...emails.filter((email) => email.isValid).map((email) => email.value), ...usersFromList?.map((user) => user.email) ?? []];
      const allValid = emailValues.length === emails.length + (usersFromList?.length ?? 0);
      if (emailValues.length === 0 || !allValid) {
        setHighlightInvalidEmails(true);
        tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_submit_with_invalid_emails", source);
        return;
      }
      setSubmittingEmail(true);
      setInviteUsersSuccessResponse(null);
    
      tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_submit_emails", source, emailValues.length);
      const permission = invitePermission ?? Permission.editor;
      const defaultInviteActions = getInviteActions({usersPermission: permission, inviteMode: InviteMode.Edit, team: getDefaultAccountTeam(), existingUsers: []});
      let usersPermissions : Record<string, Permission> = {};
      emailValues.forEach(email => {
        usersPermissions[email] = permission;
      });
      internalInviteUser(usersPermissions, canvasName)
        .then(({ users, failed, addedSeats }) => {
          handleInviteSuccessfulResponse({ 
            newUsers: users, 
            failed, 
            addedSeats, 
            inviteActions: inviteActions ?? defaultInviteActions,
            source,
            onDismiss: params.onDismiss,
          });
        })
        .catch(() => {
          const response: InviteResponse = {
            usersCount: 0,
            failed: emailValues,
            addedSeats: 0,
          };
          setInviteUsersSuccessResponse(response);
          tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_failed", source, emailValues.length);
        })
        .finally(() => setSubmittingEmail(false));
    };

  interface InviteParams {
    inviteMode: InviteMode;
    invitePermission?: Permission;
    name?: string;
    team?: Team;
    project?: Project;
    dispatch: any;
    usersFromList?: User[];
    canvasName?: string;
    source: string;
    onDismiss?: () => void;
    setCreated?: (instance: Team | Project) => void;
  }

  function getUsersPermissions(emailValues: string[], invitePermission: Permission): Record<string, Permission> {
    let usersPermissions: Record<string, Permission> = {};
    emailValues.forEach((email) => {
      usersPermissions[email] = invitePermission;
    });
    return usersPermissions;
  }

  function getEmailsValues(usersFromList?: User[]) {
    return [
      ...emails.filter((email) => email.isValid).map((email) => email.value),
      ...(usersFromList?.map((user) => user.email) ?? []),
    ];
  }

  async function handleInviteForTeamAndProject({
    name,
    team,
    project,
    inviteMode,
    users,
    invitePermission,
    setCreated,
  }: {
    name?: string;
    team?: Team;
    project?: Project;
    inviteMode: InviteMode;
    users: User[];
    invitePermission: Permission;
    setCreated?: (team: Team | Project) => void;
  }) {
    if (inviteMode === InviteMode.Create && name) {
      if (!team) {
        // Create team and insert user
        await handleCreateTeam(name, users, invitePermission).then((team) => team && setCreated && setCreated(team));
      } else if (team && !project) {
        // Create project and insert users to parent team and project
        await handleEditTeamMembers({ team, users, permission: invitePermission, state: State.active })
        const project = await handleCreateProjectWithoutAddingCanvases(team, name, users);
        if (project) {
          setCreated && setCreated(project)
        }
      }
    } else if (inviteMode === InviteMode.Edit) {
      if (team && !project) {
        // Edit team members
        await handleEditTeamMembers({ team, users, permission: invitePermission, state: State.active });
      } else if (team && project) {
        // Edit project members and parent team members
        await handleEditTeamMembers({ team, users, permission: invitePermission, state: State.active });
        await handleEditProjectMembers({ project, users, state: State.active });
      }
    }
  }

  async function onInvite({
    inviteMode,
    name,
    team,
    project,
    dispatch,
    invitePermission,
    usersFromList,
    canvasName,
    source,
    onDismiss,
    setCreated,
  }: InviteParams): Promise<void> {
    const emailValues = getEmailsValues(usersFromList);
    try {
      const usersPermissions = getUsersPermissions(emailValues, invitePermission ?? Permission.editor);
      setSubmittingEmail(true);
      setIsLoadingInvite(true);
      tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_submit_emails", source, emailValues.length);
      //invite all users to the account:
      let { users, failed, addedSeats } = await internalInviteUser(usersPermissions, canvasName);
      handleInviteResponse({
        newUsers: users,
        failed,
        addedSeats,
        source,
      });
      if (users.length > 0) {
        await handleInviteForTeamAndProject({
          name,
          team,
          project,
          inviteMode,
          users,
          invitePermission: invitePermission ?? Permission.editor,
          setCreated,
        });
      }
      if (failed.length === 0 && addedSeats === 0) {
        onDismiss && onDismiss();
      }
    } catch {
      const response: InviteResponse = {
        usersCount: 0,
        failed: emailValues,
        addedSeats: 0,
      };
      setInviteUsersSuccessResponse(response);
      tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_failed", source, emailValues.length);
    } finally {
      setSubmittingEmail(false);
      setIsLoadingInvite(false);
    }
  }

    function handleTeammatesInvite({
      usersPermission,  
      canvasName = "", 
      inviteMode, 
      team,
      teamName, 
      dispatch, 
      existingUsers = [],
      usersFromList = [],
      source,
      project,
      onDismiss,
    }:{
      usersPermission: Permission, 
      canvasName?: string, 
      inviteMode: InviteMode, 
      team?: Team, 
      teamName?: string,
      dispatch: any, 
      existingUsers?: User[],
      usersFromList?: User[],
      source: string
      project?: Project
      onDismiss?: () => void;
    }) {
      existingUsers = [...existingUsers, ...usersFromList]
      const inviteActions = getInviteActions({usersPermission, inviteMode, team, teamName, existingUsers, project});

      if (emails.length > 0 || usersFromList.length > 0) {
        setIsLoadingInvite(true);
        handleInvite({
          source,
          dispatch,
          canvasName,
          inviteActions,
          invitePermission: usersPermission,
          usersFromList,
          onDismiss
        });
      } else {
        inviteMode === InviteMode.Create && teamName
          ? handleCreateTeam(teamName, existingUsers, usersPermission)
          : team && existingUsers && handleEditTeamMembers({team, users: existingUsers, permission: usersPermission, state: State.active});
          onDismiss && onDismiss();
      }
    };

    function changeEmails(
      { value, action }: BubbleFieldMutation,
      source: string,
    ) {
      setHighlightInvalidEmails(false);
      switch (action) {
        case "add":
          const isValid = isEmail(value);
          tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_added_email", source, isValid);
          const newValue: BubbleFieldValue = {
            value,
            displayValue: value,
            isValid,
          };
          setEmails((emails: BubbleFieldValue[]) => [...emails, newValue]);
          break;
        case "delete":
          tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "invite_removed_email", source);
          setEmails((emails: BubbleFieldValue[]) => emails.filter((email) => email.value !== value));
          break;
      }
    }

    return {
        inviteUsersResponse,
        emails,
        submittingEmail, 
        highlightInvalidEmails,
        handleTeammatesInvite,
        isLoadingInvite,
        handleInvite,
        changeEmails,
        onInvite,
      };
}