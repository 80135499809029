import { Point } from "./schemas";
import { MindmapNodeDirection, MindmapNodeOrientation, MindmapOrgChartNodeElement, mindmapOrgChartNodeSchema } from "./schemas/mindmap-org-chart";
import { createElementId, getUnixTimestampUTC } from "../util/utils";
import consts from "../consts";
import { IntegrationType } from "../integrations/integration";

export const Padding = {
  padding_vertical: 14,
  padding_horizontal: 10,
  padding_between_lines: 5,
  minNodeSize: { width: 180, height: 100 },
  padding_seperator: 10,
  padding_connector_child: 16
}

export const DEFAULT_COLOR = "#7549F5";
export const ORGCHART_NODE_DEFAULT_WIDTH = 200;
export const ORGCHART_NODE_DEFAULT_HEIGHT = 50;
export const ORGCHART_FILTER_MARKER_RADIUS = 10;
export const ORGCHART_FIELD_HEIGHT = 12;


// this function is used to create an object that will be saved to the db
export function placeMindMapOrgChartNode(
  integrationId: string,
  integrationType: IntegrationType,
  configuration: any,
  { x, y }: Point, current_item: any, rootId?: string, parentId?: string) {
  const id = createElementId();
  const color = DEFAULT_COLOR;

  const excludedColumns = [
    configuration.columnMapping.jobTitle,
    configuration.columnMapping.selfId,
    configuration.columnMapping.parentId,
    "name"
  ]
  configuration.columns = configuration.columns
    .filter((col: any) => !excludedColumns.includes(col.id))

  return {
    id,
    node: mindmapOrgChartNodeSchema.parse({
      type: consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART,
      x,
      y,
      width: ORGCHART_NODE_DEFAULT_WIDTH,
      height: ORGCHART_NODE_DEFAULT_HEIGHT,
      nodes: [],
      orientation: MindmapNodeOrientation.HORIZONTAL,
      direction: MindmapNodeDirection.TRAILING,
      zIndexLastChangeTime: getUnixTimestampUTC(),
      rootId: rootId ?? current_item.id, // if rootId is not provided, this is the root
      selfId: current_item.id, // if rootId is not provided, this is the root
      parentId: parentId,
      sortIndex: 0,
      collapsed: false,
      integrationId,
      integrationType,
      configuration,
      color: color,
      text: "",
      job_title: "",
      expandedNodes: [current_item.id],
      selectedFields: [],
      columnValues: [],
      selectedColorFieldId: undefined,
    }) as MindmapOrgChartNodeElement,
  };
}

// this function is used to create an object that will be saved to the db
export function placeMindMapOrgChart(
  integrationId: string,
  integrationType: IntegrationType,
  configuration: any,
  { x, y }: Point, all_data: any, rootId?: string, parentId?: string) {
    const current_item= all_data.find((item: any) => item.id == configuration.itemId);
    const parent_element = placeMindMapOrgChartNode(integrationId, integrationType, configuration, { x, y }, current_item, rootId, parentId);
    parent_element.node.childrenColumnValues = generateColumnValues(all_data, configuration.itemId, {})[configuration.itemId];
    const data = [{
      id: parent_element.id,
      type: parent_element.node.type,
      element: parent_element.node,
    }];

  return data;
}

function generateColumnValuesForPlacing(columns: { id: string; title: string; type: string; settings_str: string }[]) {
  const result: { [key: string]: string[] } = {}
  columns.filter((col) => col?.type === "dropdown").forEach((col) => {
    if (col.id && !result[col.id]) {
      result[col.id] = [];
    }
    const { labels } = JSON.parse(col.settings_str);

    // dropdown values are comma seperated strings
    labels.forEach((label: { name: string }) => {
      if (label.name && !result[col.id].includes(label.name)) {
        result[col.id].push(label.name);
      }
    })
  });
  return result;
}

export function generateColumnValues(all_data: any, current_element_id: string, result: { [key: string]: { [key: string]: string[] } }) {
  const current_element = all_data.find((item: { id: string }) => item.id === current_element_id);
  result[current_element.id] = {};
  current_element.columnValues.filter((col: { type: string }) => col?.type === "dropdown").map((col: { id: string; value: string }) => {
    if (col.id && !result[current_element.id][col.id]) {
      result[current_element.id][col.id] = [];
    }
    // dropdown values are comma seperated strings
    col.value.split(", ").forEach((colVal: string) => {
      if (colVal && !result[current_element.id][col.id].includes(colVal)) {
        result[current_element.id][col.id].push(colVal);
      }
    })
  });

  const children = all_data.filter((child: any) => (child.parent == current_element.self && child.parent != child.self))
  children.map((child: any) => {
    result = generateColumnValues(all_data, child.id, result);
  })
  children.map((child: any) => {
    const columnData = result[child.id];

    for (const key in columnData) {
      if (result[current_element.id][key]) {
        result[current_element.id][key] = Array.from(new Set([...result[current_element.id][key], ...columnData[key]]));
      } else {
        result[current_element.id][key] = columnData[key];
      }
    }
  });
  return result;
}

export function colorDecider(selectedColorFieldId: string, current_item: { columnValues: any[]; status: { color: string; }; }) {
  if (selectedColorFieldId) {
    const colorColumm = current_item.columnValues.find((col) => col.id === selectedColorFieldId);
    if (colorColumm) {
      return colorColumm.value.color ?? DEFAULT_COLOR;
    }
    return DEFAULT_COLOR;
  }
  return DEFAULT_COLOR;
}

export function calcHeight(selectedFilter: { [key: string]: string[] }, selectedFields: string[]) {
  const heights = [ORGCHART_NODE_DEFAULT_HEIGHT]
  if (selectedFilter && Object.keys(selectedFilter).length) {
    heights.push(ORGCHART_FILTER_MARKER_RADIUS)
  }
  for (let i = 0; i < selectedFields.length; i++) {
    if (i == 0) {
      heights.push(ORGCHART_FIELD_HEIGHT + Padding.padding_vertical)
    } else {
      heights.push(ORGCHART_FIELD_HEIGHT)

    }
  }
  const newHeight =
    heights.reduce((accumulator, height) => accumulator + height + Padding.padding_between_lines, 0) + Padding.padding_vertical
  return newHeight;
}

// this function is only for rendering
export function placeMindMapOrgChartNodeToRender(
  current_item: any,
  position: Point,
  height: number,
  rootId?: string,
  parentId?: string,
  expandedNodes?: string[],
  selectedFields?: string[],
  selectedColorFieldId?: string,
  selectedFilter?: { [key: string]: string[] },
  configuration?: any,
) {
  const id = current_item.id;
  current_item.selectedFields = selectedFields;
  const color = colorDecider(selectedColorFieldId || "", current_item)

  return {
    id,
    node: mindmapOrgChartNodeSchema.parse({
      type: consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART,
      width: ORGCHART_NODE_DEFAULT_WIDTH,
      height: height,
      nodes: [],
      orientation: MindmapNodeOrientation.HORIZONTAL,
      direction: MindmapNodeDirection.TRAILING,
      zIndexLastChangeTime: getUnixTimestampUTC(),
      rootId: rootId ?? id, // if rootId is not provided, this is the root
      parentId: parentId,
      selfId: id,
      sortIndex: 0,
      collapsed: !expandedNodes?.includes(id),
      color: color,
      text: current_item.title,
      job_title: current_item.job_title,
      x: position.x,
      y: position.y,
      expandedNodes: expandedNodes,
      selectedFields: selectedFields,
      columnValues: current_item.columnValues,
      selectedColorFieldId: selectedColorFieldId,
      selectedFilter: selectedFilter,
    }) as MindmapOrgChartNodeElement,
  };
}

// building the tree for rendering
export function buildMindmapOrgChartNodesForRootId(
  all_data: any,
  currentId: string,
  rootId: string,
  parentId: string,
  position: Point,
  expandedNodes: string[],
  selectedFields: string[],
  selectedColorFieldId: string,
  selectedFilter: { [key: string]: string[] },
  height?: number) {

  let data = [] as any;
  if (!all_data) {
    return data;
  }

  if (!height) {
    height = calcHeight(selectedFilter, selectedFields);
  }

  try {
    const current_item = all_data.find((item: any) => item.id == currentId);
    const current_element = placeMindMapOrgChartNodeToRender(current_item, position, height, rootId, parentId, expandedNodes, selectedFields, selectedColorFieldId, selectedFilter, height);

    data.push({
      id: currentId,
      node: current_element.node,
    })

    const parent_item_identifier = current_item.self;

    const children = all_data.filter((child: any) => (child.parent == parent_item_identifier && child.parent != child.self))
    children.map((child: any) => {
      const chlid_tree = buildMindmapOrgChartNodesForRootId(all_data, child.id, rootId, currentId, position, expandedNodes, selectedFields, selectedColorFieldId, selectedFilter);
      data = data.concat(chlid_tree);
    });
  } catch (error) {
    console.error(error)
    return data
  }


  return data;
}