import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import style from "./colorPicker.module.css";
import { combineColorOpacity, parseColor } from "../../../utils/color-utils";
import { DropdownButton } from "../toolbar-buttons";
import CustomColorsPicker from "frontend/ui-components/custom-colors-picker";
import { useAccountReps } from "frontend/hooks/use-account-reps";
import consts from "shared/consts";

// This function covers up a mistake I made.
// I used the color 'transparent' in some places, and '#00000000' in others,
// so now I check for both forms.
function isTransparent(color: string): boolean {
  return color == "transparent" || color == "#00000000";
}

export function ColorPicker({
  value,
  onChange,
  multi,
  colorPalette,
  customColors,
  isText,
  withTransparency = false,
  tooltip,
  onlyOutline = false,
  allowCustomColor = false,
}: {
  value?: string;
  onChange?: (color: any, addUndo: boolean) => void;
  multi?: boolean;
  colorPalette?: any;
  customColors?:any;
  isText?: boolean;
  withTransparency?: boolean;
  tooltip?: string;
  onlyOutline?: boolean;
  allowCustomColor?: boolean;
}) {
  value = typeof value === "string" ? value : "transparent";
  const colors = colorPalette || consts.COLOR_PALETTE;
  const [showCustomColorPicker, setShowCustomColorPicker] = useState(false);
  const [customPickerPosition, setCustomPickerPosition] = useState({ x: 0, y: 0 });
  const [showColorPalette, setShowColorPalette] = useState(true);

  const buttonRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { isAccountRepsReady, addCustomColorForUser } = useAccountReps();

  let { color: baseColor, opacity } = parseColor(value);

  useEffect(() => {
    if (showCustomColorPicker && buttonRef.current) {
      const { x, y, width, height } = buttonRef.current.getBoundingClientRect();
      setCustomPickerPosition({ x: x + width / 2, y: y + height / 2 });
    }
  }, [showCustomColorPicker]);

  function handleColorSelection(color: string, addUndo: boolean) {
    onChange && onChange(color, addUndo);
  }

  const handleCustomColorChange = useCallback(
    (color: string) => {
      setShowCustomColorPicker(false);

      if (colorPalette.indexOf(color) != -1) return;

      if (isAccountRepsReady) {
        addCustomColorForUser(color);
      }
      handleColorSelection(combineColorOpacity(color, opacity || 255), true);
    },
    [opacity, isAccountRepsReady]
  );

  function renderCustomColorButton(baseColor: string, opacity: number) {
    return (
      <div
        className={style.customColorButtonContainer}
        key={"custom"}
        data-testid="customcolorpicker"
        onClick={() => setShowCustomColorPicker(true)}
        ref={buttonRef}
      >
        <div className={style.customColorButton} />
      </div>
    );
  }

  function renderColorIconGrid(colorIcons: string[], customColorButton: boolean) {
    return (
      <div className={style.colorIconsGrid}>
      {colorIcons.map((color: any, index: number) => (
        <div
          className={style.colorOptionContainer}
          key={color}
          onClick={() => handleColorSelection(combineColorOpacity(color, opacity || 255), true)}
        >
          <SolidColor color={color} />
        </div>
      ))}
      {allowCustomColor && customColorButton && renderCustomColorButton(baseColor, opacity || 255)}
    </div>
    )
  }

  function renderColorPalette(baseColor: string, opacity: number) {
    if (!showColorPalette) {
      return null;
    }
    return (
      <div className={style.colorPopupLayout} ref={containerRef}>
        {withTransparency && (
          <>
            <OpacitySlider
              opacity={opacity}
              backdropColor={baseColor}
              onChange={(num) => {
                // onChange must accept number here - elements will adjust their opacity
                // while maintaining their color
                onChange && onChange(num, false);
              }}
              onSelect={() => (onChange && onChange(opacity, true))}
              disabled={isTransparent(baseColor)}
            />
            <div className={style.hr} />
          </>
        )}

        {renderColorIconGrid(colors, allowCustomColor && !customColors.length)}
        {allowCustomColor && customColors.length ?
        (
          <>
            <div className={style.hr} />
            {renderColorIconGrid(customColors, true)}
          </>
        ) : null}
      </div>
    );
  }

  function renderCustomPicker() {
    return (
      <div
        className={style.customColorPickerContainer}
        style={{ top: customPickerPosition.y, left: customPickerPosition.x }}
      >
        <CustomColorsPicker
          hex={baseColor}
          onChange={(color: string) => {
            setShowColorPalette(false);
            handleColorSelection(combineColorOpacity(color, opacity), false);
          }}
          onDismiss={(color: string) => {
            setShowColorPalette(true);
            handleCustomColorChange(color);
          }}
        />
      </div>
    );
  }

  const iconColor = multi
    ? "conic-gradient(#ff4800, #dfd902, #20dc68, #0092f4, #da54d8, #ff4800)"
    : value || "transparent";

  return (
    <DropdownButton
      tooltip={tooltip ?? "Change color"}
      renderPopup={() => renderColorPalette(baseColor, opacity)}
      data-testid={onlyOutline ? "strokecolorpicker" : "FloatingColorPicker"}
      className={style.colorPopup}
    >
      {onlyOutline ? <OutlineColor color={iconColor} /> : <SolidColor color={iconColor} />}
      {showCustomColorPicker && renderCustomPicker()}
    </DropdownButton>
  );
}

// this component renders an html input slider, that works even when the left hand
// side is smaller then the right hand side (plain html input range does not)
function FlexiSlider({
  left,
  right,
  value,
  onChange,
  onSelect,
  classname,
  style,
  isDisabled,
}: {
  left: number;
  right: number;
  value: number;
  onChange: (x: number) => void;
  onSelect: () => void;
  classname?: string;
  style?: CSSProperties;
  isDisabled?: boolean;
}) {
  const value_for_input = (value - left) / (right - left);
  return (
    <input
      type="range"
      min={0}
      max={1}
      step={0.01}
      value={value_for_input}
      className={classname}
      style={style}
      disabled={isDisabled}
      onChange={(e) => {
        let x = e.currentTarget.valueAsNumber * (right - left) + left;
        onChange(x);
      }}
      onMouseUp={onSelect}
    />
  );
}

function OpacitySlider({
  opacity,
  backdropColor,
  onChange,
  onSelect,
  disabled,
}: {
  opacity: number;
  backdropColor: string;
  onChange: (v: number) => void;
  onSelect: () => void;
  disabled: boolean;
}) {
  return (
    <div className={style.OpacityContainer} style={{ opacity: disabled ? 0.3 : 1 }}>
      <div className={style.Label}>Opacity {Math.round((opacity * 100) / 255)}%</div>
      <div className={style.Slider} data-testid="Slider">
        <FlexiSlider
          left={255}
          right={0.1 * 255}
          value={opacity}
          onChange={onChange}
          onSelect={onSelect}
          classname={style.opacitySlider}
          style={{
            background: `linear-gradient(90deg, ${backdropColor} 26.82%, transparent)`,
          }}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
}

function SolidColor({ color }: { color: string }) {
  function renderColor() {
    if (isTransparent(color)) {
      return null;
    }
    return <div className={style.colorSolid} data-testid={color}  style={{ background: color }} />;
  }
  return <div className={style.colorTransparentSolid}>{renderColor()}</div>;
}

function OutlineColor({ color }: { color: string }) {
  function renderColor() {
    if (isTransparent(color)) {
      return null;
    }
    return (
      <div className={style.colorButton}>
        <div
          className={style.ring}
          style={{
            background: `linear-gradient(#0B2642,#0B2642) padding-box, ${color} border-box`,
            borderRadius: "50%",
            border: "3px solid transparent",
          }}
        />
      </div>
    );
  }

  return <div className={style.colorTransparentRing}>{renderColor()}</div>;
}
