import consts from "backend/shared/consts";
import { EditableTextInput } from "frontend/editableTextInput";
import { useAtomValue } from "jotai";
import { Text } from "react-konva";
import { Frame } from "shared/datamodel/schemas/frame";
import { FontProperties } from "shared/datamodel/schemas/textEnabled";
import { editingElementIdAtom, posScaleAtom } from "state-atoms/stage-atoms";

export default function FrameTitleCanvasElement({
  id,
  element,
  onChangeElement,
}: {
  id: string;
  element: Frame;
  onChangeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
}) {
  const { x, y, width, title, scaleX } = {
    scaleX: 1,
    ...element,
  };

  const scale = useAtomValue(posScaleAtom).scale;
  const editingElementId = useAtomValue(editingElementIdAtom);

  if(scale < 0.15) {
    return null;
  }

  const isEditing = editingElementId === id;

  function renderEditable() {
    return (
      <EditableTextInput
        id={id}
        x={x}
        y={y - 18 / scale - 8}
        width={width}
        height={18 / scale}
        value={title}
        placeholder={"Frame title"}
        isFixedHeight={true}
        fontSize={14 / scale}
        font={consts.DEFAULTS.FONT}
        fill={"#797E93"}
        onChange={(value: string) => {
          const newTitle = value.trim() || "Frame";
          onChangeElement({ title: newTitle }, { shouldAdd: true, previousProps: { title } });
        }}
        align={"left"}
        verticalAlign={"top"}
        fontProps={FontProperties.Light}
        blurOnEnter={true}
      />
    );
  }

  return isEditing ? (
    renderEditable()
  ) : (
    <Text
      x={x}
      y={y - 18 / scale}
      width={width * scaleX}
      text={title}
      fontFamily={"Poppins"}
      fontSize={14 / scale}
      fontStyle={"300"}
      fill={"#797E93"}
      id={id}
      isCanvasElement={true}
      isFrameTitle={true}
      isFrame={true}
      isConnector={false}
      isConnectable={false}
      isSelectable={true}
      isTaskConvertible={false}
      element={element}
    />
  );
}
