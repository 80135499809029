import { ButtonStyle } from "frontend/ui-components/styled-button";
import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";

export default function UpgradeModalForUnlimitedElements(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="elements-limit"
      title=""
      replaceTitleWith={
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0",
            textAlign: "left",
            background: "#E6F1FF",
            padding: "10px",
          }}
        >
          You have exceeded the free plan limitation of 30 elements per canvas
        </span>
      }
      content={"And build your Canvas without limits."}
      bulletpoints={[
        "Add unlimited shapes to your canvas ",
        "Work together with blazing fast multiplayer ",
        "Use offline mode when on the go",
        "Export canvas in ultra high quality",
        "Explore a world of professional templates",
      ]}
      image={"/images/upgrade-modal-images/upgrade-for-unlimited-elements.png"}
      upgradeButtonStyle={ButtonStyle.filled}
      showCancelLine={false}
      {...props}
    />
  );
}
