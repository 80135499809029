import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";

export default function UpgradeModalForVideo(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="video-calls"
      title="Upgrade to video chat with your team"
      content="Video chat with your colleagues on WorkCanvas. High quality video and audio communication right inside the canvas!"
      bulletpoints={[
        "Video chatting",
        "Unlock our premium templates",
        "High quality file export",
        "Timer & voting",
        "And much more",
      ]}
      image="/images/upgrade-modal-images/upgrade-for-video.png"
      {...props}
    />
  );
}