import React, { useMemo, useRef, useState } from "react";
import { SyncService } from "frontend/services/syncService";
import Konva from "konva";
import { KonvaEventObject } from "konva/types/Node";
import { Circle, Group, Line, Shape } from "react-konva";
import { Portal } from "react-konva-utils";
import consts from "shared/consts";
import { LockType, Point, Connector, defaultShapeDimensions, InnerPointSchema } from "shared/datamodel/schemas";
import { parseStrokeWidth } from "shared/util/utils";
import { calcDashProperties, getTransformParams } from "frontend/utils/node-utils";
import {
  arePointsEqual,
  isPointInRect,
  PointInCanvas,
  smoothMaxUnit,
  IRect,
  viewportToStage,
} from "frontend/utils/math-utils";
import * as utils from "frontend/utils/connector-utils";
import { ITraits, Trait } from "../elements-toolbar/elements-toolbar-types";
import { useAtomValue } from "jotai";
import { isThumbnailExportModeAtom, posScaleAtom, syncServiceAtom, isExportingAtom } from "state-atoms";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { getElementTypeForId } from "./canvas-elements-utils";
import { getFontSize } from "../text-element";
import { ConnectorTextLabel } from "../text-element";
import { useKeyPress } from "react-use";
import { useCanvasElementById } from "frontend/subscriptions";
import { ITransformHooks } from "frontend/hooks/use-transform-hooks";
import { ShapeSocketColor } from "../frontend-consts";
import { replaceColorOpacity } from "frontend/utils/color-utils";
import { nanoid } from "nanoid";
import * as PointUtils from "frontend/utils/point-utils";
import Transform, { PointAndDirection, konvaTransformForElement, toRadians, toDegrees } from "frontend/utils/transform";
import type { Degrees } from "frontend/utils/transform";
import { initArray, minIndexBy, noop } from "frontend/utils/fn-utils";
import { produce, enablePatches } from "immer";
import * as attachUtils from "frontend/utils/connector-attach-utils";
import pointAt from "frontend/geometry/pointAt";
import normalAt from "frontend/geometry/normalAt";
import BoundingBox from "frontend/geometry/bounding-box";

enablePatches();

type OnChangeElementFn = (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;

type ConnectorEndpoint = {
  x: number;
  y: number;
  rotation?: Degrees;
};

type AnchorDragState = {
  x: number;
  y: number;
  index: number;
  snapToPoint?: PointAndDirection;
  snapToShape?: string;
};

type AnchorFrameData = null | {
  frameId: string;
  pos: Point;
};

enum AnchorIndex {
  Start,
  End,
}

const DATA_NOT_LOADED = "not loaded";
const HANDLE_COLOR = "#00A1FF";
const HANDLE_COLOR_SEMI_TRANSPARENT = "#00A1FF66";

type Point2 = readonly [number, number];

function fixMode(mode: string | null | undefined) {
  // we had a bug that inserted numbers to some elements :-(
  if (mode == null || mode == undefined) return "n/a";
  if (typeof mode == "number") {
    mode = ["left", "right", "top", "buttom"][+mode] ?? "n/a";
  }
  return mode;
}

export const ConnectorCanvasElement = React.memo(
  ({
    uniqueId,
    element,
    mutation,
    isSelected,
    isEditing,
    layer,
    onElementsMutationEnded,
    changeElement,
    changeAnyElement,
    isSelectable,
    allElements,
  }: {
    uniqueId: string;
    element: Connector;
    mutation: ITransformHooks;
    isSelected: boolean;
    isEditing: boolean;
    layer?: Konva.Layer;
    onElementsMutationEnded: any;
    changeElement: OnChangeElementFn;
    changeAnyElement: (id: string, props: any, previousProps?: any) => void;
    isSelectable: boolean;
    allElements?: any;
  }) => {
    // Atoms
    const syncService = useAtomValue(syncServiceAtom) as SyncService<RW>;
    const isExportingCanvas = useAtomValue(isExportingAtom);

    // Hooks
    const anchorsFrameIds = useRef<[AnchorFrameData, AnchorFrameData]>([null, null]);
    const [dragState, setDragState] = useState<any>(null);
    const undoState = useRef<any>();

    // Subscriptions to replicache
    const connectedShapes = element.connectedShapes;
    const firstShape = allElements
      ? allElements[connectedShapes[0]?.id as any]
      : useCanvasElementById(syncService.getReplicache()!, connectedShapes[0]?.id ?? "", DATA_NOT_LOADED, [
          connectedShapes[0]?.id,
        ]);
    const secondShape = allElements
      ? allElements[connectedShapes[1]?.id as any]
      : useCanvasElementById(syncService.getReplicache()!, connectedShapes[1]?.id ?? "", DATA_NOT_LOADED, [
          connectedShapes[1]?.id,
        ]);
    const getShape = (index: number) => (index == 0 ? firstShape : secondShape);

    const locked = (element.lock ?? 0) != LockType.None;
    const drawHandles = isSelected && !locked && !isExportingCanvas;

    // Functions

    const toStage = layer ? viewportToStage(layer.getAbsoluteTransform()) : noop;

    function viewportToElement(pos: Point) {
      let result = toStage(pos);
      const tr = getTransformParams("connector", element);
      return Transform.InvPoint(tr, result, result);
    }

    function elementPointToCanvasPoint(p: Point, out?: Point) {
      const tr = getTransformParams("connector", element);
      return Transform.Point(tr, p, out);
    }

    function canvasPointToElementPoint(p: Point, out?: Point) {
      const tr = getTransformParams("connector", element);
      return Transform.InvPoint(tr, p, out);
    }

    function dragAndSnap(index: number, point: Point, e: KonvaEventObject<MouseEvent>, cache?: WeakMap<any, any>) {
      let newDragState: AnchorDragState, snappedShape: any;
      const otherIndex = 1 - index;

      let snap = attachUtils.checkSnapping(e.evt, e.target.getPosition(), e.target.getStage()!, layer!, cache); // todo: can this return data in format of 'candidate' already?

      let candidate: any = null;
      if (snap) {
        if (snap instanceof attachUtils.SnapToAnchor) {
          const otherAnchor = elementPointToCanvasPoint((otherIndex == 0 ? p1 : p2) as ConnectorEndpoint);
          const { node, rect, distance } = snap;
          const insideShape = isPointInRect(otherAnchor, rect) && isPointInRect(e.target.getPosition(), rect);

          if (insideShape) {
            if (distance < utils.DistanceToSnapToAnchorFromInside_px) {
              candidate = {
                node,
                side: snap.side,
                position: snap.position,
                anchors: snap.anchors,
                rotation: snap.rotation,
              };
            }
          } else {
            const smallShape =
              rect.width * rect.height < defaultShapeDimensions.width * defaultShapeDimensions.height * 3;
            if (smallShape) {
              candidate = {
                node,
                side: snap.side,
                position: snap.position,
                anchors: snap.anchors,
                rotation: snap.rotation,
              };
            } else if (distance < utils.DistanceToSnapToAnchorFromOutside_px) {
              candidate = {
                node,
                side: snap.side,
                position: snap.position,
                anchors: snap.anchors,
                rotation: snap.rotation,
              };
            }
          }
        } else if (snap instanceof attachUtils.SnapToOutline) {
          candidate = {
            node: snap.node,
            side: "outline",
            position: snap.xy,
            rotation: snap.rotation || 0,
            t: snap.t,
            anchors: null,
          };
        } else if (snap instanceof attachUtils.SnapJustShowAnchors) {
          candidate = { anchors: snap.anchors };
        } else {
          console.warn("unknown ret value", snap);
        }
      }

      // Internal drag state
      newDragState = { index, x: point.x, y: point.y } as AnchorDragState;

      snappedShape = { id: null, element: null };

      // don't allow connection to same place as other endpoint)
      if (candidate?.node) {
        if (
          candidate.node.id() == element.connectedShapes[otherIndex]?.id &&
          candidate.side != "outline" &&
          candidate.side == fixMode(element.anchorsMode?.[otherIndex])
        ) {
          candidate = null;
        }
      }

      if (candidate?.node) {
        // The attached shape update
        const attachedConnectors = structuredClone(candidate.node.attrs.element.attachedConnectors ?? {});
        attachedConnectors[uniqueId] = { lineId: uniqueId };
        snappedShape.id = candidate.node.id();
        snappedShape.element = { attachedConnectors };

        // update the drag state
        let pointInElementSpace = canvasPointToElementPoint({ ...candidate.position }) as any;
        newDragState.snapToPoint = { ...pointInElementSpace, rotation: candidate.rotation };
        newDragState.snapToShape = candidate.node.id();
      }
      // element is readonly, we have to create copies
      // todo: get patch and return that instead of the whole element
      let { points, connectedShapes, anchorsMode, point1_t, point2_t } = produce(element, (draft) => {
        draft.points[index] = point;
        draft.connectedShapes[index] = candidate?.node ? { id: candidate.node.id(), type: "" } : { id: "", type: "" };
        draft.anchorsMode ??= ["n/a", "n/a"];
        draft.anchorsMode[index] = candidate?.side ?? "n/a";
        if (candidate?.side == "outline") {
          index == 0 ? (draft.point1_t = candidate.t) : (draft.point2_t = candidate.t);
        }
      });
      let newConnectorState = structuredClone({ points, connectedShapes, anchorsMode, point1_t, point2_t });

      return {
        newDragState,
        newAnchorPoints: candidate?.anchors,
        newConnectorState,
        snappedShape,
        highlightedSocket: candidate?.position,
      } as const;
    }

    const onDragStart = (index: number, e: KonvaEventObject<MouseEvent>) => {
      const shape = getShape(index);
      const prevId = element.connectedShapes[index]?.id;

      if (Boolean(prevId) != Boolean(shape)) {
        // We're never supposed to get here. If we drew the connector then we surely
        // got the data about the shape, so we should have both of them.
        console.warn("Connector didn't get from replicache its connected shape");
      }

      // duplicate the shape we're connected to state for undo information
      let attachedConnectors = prevId && shape && { ...shape.attachedConnectors };

      let p = e.target.getPosition(); // position on screen of control point
      const { x, y } = viewportToElement(p); // position relative to element
      setDragState({
        anchorDragState: {
          index,
          x,
          y,
        },
      });
      anchorsFrameIds.current[index] = null; // mark us as detachd from frame
      undoState.current = {
        connector: structuredClone(element),
        shape: {
          id: prevId,
          element: attachedConnectors ? { attachedConnectors } : undefined,
        },
      };
      // todo: this 2 calls to replicache can be unified into 1 call
      // detach from shape
      const newConnectorData = structuredClone(element);
      newConnectorData.connectedShapes[index] = { id: "", type: "" };
      changeElement(newConnectorData, { shouldAdd: false });
      // detach shape from us
      if (attachedConnectors && prevId) {
        delete attachedConnectors[uniqueId];
        changeAnyElement(prevId, { attachedConnectors });
      }
    };

    const onDragEnd = (anchorIndex: number, mousePosition: Point, e: KonvaEventObject<MouseEvent>) => {
      // This function changes the connector element in reflect, and registers an undo action.
      // There's a case we don't want to do that: if e.evt==null, because that means
      // the drag operation was cancelled because space was pressed.

      // if !e.evt - dragging was cancelled because space was pressed
      // if we should disable snapping, we don't do anything since the connector was moved already in dragmove
      if (e.evt) {
        const point = canvasPointToElementPoint(mousePosition);
        let cache = e.target.attrs.cache;
        let { newConnectorState, snappedShape } = dragAndSnap(anchorIndex, point, e, cache);

        // todo: don't apply the change if there's no difference between the new-state and undo-state
        // todo: keep the whole element in undoState, and use immer to do the change.
        // then just check if patch.length!=0
        let prev = undoState.current;
        let cur = {
          connector: newConnectorState,
          shape: snappedShape,
        };
        let prevState = new Map();
        let newState = new Map();
        prevState.set(uniqueId, prev.connector);
        newState.set(uniqueId, cur.connector);
        if (prev.shape.id) {
          prevState.set(prev.shape.id, prev.shape.element);
          const node = layer!.findOne("." + prev.shape.id);
          if (node?.attrs.element) newState.set(prev.shape.id, node.attrs.element);
        }
        // if user doesn't want to snap, we continue to register the undo action,
        // just without snapping
        const disableSnapping = utils.shouldDisableConnectorSnapping(e.evt);
        if (!disableSnapping && cur.shape.id) {
          newState.set(cur.shape.id, cur.shape.element);
        }
        onElementsMutationEnded(newState, prevState);
      }
      delete e.target.attrs.cache;
      setDragState(null);
      undoState.current = null;
    };

    const onDragMove = (index: number, mousePosition: Point, e: KonvaEventObject<MouseEvent>) => {
      if (!e.evt) {
        // dragging was cancelled because space was pressed
        return;
      }

      // todo: according to the profiler this function punches above its weight !
      // I can cache the transform for the connector, it almost certainly doesn't change when moving the endpoints
      let point = canvasPointToElementPoint(mousePosition);

      // support snapping to 45 degrees on shift
      if (e.evt.shiftKey) {
        let otherAnchor = index == 1 ? (p1 as any) : (p2 as any);
        if (element.innerPoints?.length) {
          otherAnchor = element.innerPoints[index == 1 ? element.innerPoints.length - 1 : 0];
        }
        point = utils.snapConnectorRotation(point, otherAnchor);
        // set the position of the actual anchor element
        e.target.setPosition(elementPointToCanvasPoint(point));
      }

      if (utils.shouldDisableConnectorSnapping(e.evt)) {
        setDragState({ anchorDragState: { index, x: point.x, y: point.y } as AnchorDragState });
        return;
      }
      let cache = (e.target.attrs.cache ??= new WeakMap<any, any>());
      let { newDragState, newConnectorState, newAnchorPoints, highlightedSocket } = dragAndSnap(index, point, e, cache);
      if (newDragState.snapToPoint) {
        const elementPos = newDragState.snapToPoint;
        const p = elementPointToCanvasPoint(elementPos);
        e.target.setAttrs(p);
      }
      let anchorDragState = newDragState;
      if (newConnectorState) {
        changeElement(newConnectorState, { shouldAdd: false });
      } else {
        console.warn("no newConnectorState");
      }
      const shapeStandardAnchors = newAnchorPoints ? Object.values(newAnchorPoints) : null;
      if (!newDragState?.snapToPoint) {
        highlightedSocket = null;
      }
      setDragState({ anchorDragState, shapeSockets: shapeStandardAnchors, highlightedSocket });
    };

    // todo: index is too generic. change to anchorIndex, endpointIndex, "start"|"end"... something like that (start-of-line, end-of-line)
    // connector-endpoint...
    function calcAnchorPosition(index: number): ConnectorEndpoint | "dont-show" | "not-loaded" {
      if (dragState?.anchorDragState?.index == index) {
        return dragState?.anchorDragState.snapToPoint ?? dragState.anchorDragState;
      }
      if (!connectedShapes[index]?.id) {
        return element.points[index];
      }
      const shape = getShape(index);
      if (shape == null || shape.hidden || shape.containerId) {
        return "dont-show" as const;
      }
      if (shape == DATA_NOT_LOADED) {
        return "not-loaded" as const;
      }
      let mode = fixMode(element.anchorsMode?.[index]);
      if (mode != "n/a") {
        const elementType = getElementTypeForId(connectedShapes[index]!.id);
        // todo: can delete this - since uvToXY repeats this code anyway
        let anchorPoints = utils.getAnchorPoints(elementType, shape);
        if (!anchorPoints) {
          return "dont-show";
        }
        if (elementType == consts.CANVAS_ELEMENTS.FILE) {
          const width = anchorPoints.right.x - anchorPoints.left.x;
          const height = anchorPoints.buttom.y - anchorPoints.top.y;
          if ((width == 0 || height == 0) && layer) {
            let node = layer.findOne("#" + connectedShapes[index]!.id);
            anchorPoints = utils.getAnchorPointsFromNode(node);
          }
        }
        if (!anchorPoints) {
          return "dont-show";
        }
        const t = index == 0 ? element.point1_t : element.point2_t;
        let result: any = null;
        if (mode != "outline") {
          const anchors = utils.getAnchorPoints(elementType, shape);
          if (anchors) {
            result = anchors[mode as keyof typeof anchors];
            const r = canvasPointToElementPoint(result);
            result = { ...r, rotation: result.rotation };
          }
        } else {
          const rotation = normalAt(elementType, shape)(t);
          const xy = { ...canvasPointToElementPoint(pointAt(elementType, shape)(t)), rotation: rotation as Degrees };
          result = xy;
        }
        return result;
      }
      console.warn("connector attached to shape, but without connection side", element);
      return "dont-show";
    }

    function findFrameForPosition(position: PointInCanvas, layer: Konva.Layer) {
      if (!layer) {
        return;
      }
      const stage = layer.getStage();
      const frames = layer.find(
        (i: any) =>
          i.attrs.isFrame && !i.attrs.isFrameTitle && isPointInRect(position, i.getClientRect({ relativeTo: stage }))
      );
      // select the smallest frame that contains the position
      let min = Number.MAX_SAFE_INTEGER,
        best: any = null;
      for (const frame of frames) {
        const rect = frame.getClientRect();
        const area = rect.width * rect.height;
        if (area < min) {
          min = area;
          best = frame;
        }
      }
      return best?.id() ?? "";
    }

    // Before we render we need to get the location of the shapes we're connected to.
    let p1 = calcAnchorPosition(AnchorIndex.Start);
    let p2 = calcAnchorPosition(AnchorIndex.End);

    //////////////////////////////////////////////////
    // Beyond this point - don't write hooks !!!!
    //////////////////////////////////////////////////

    // we don't show our connector if it's attached to a deleted shape
    if (p1 == "dont-show" || p2 == "dont-show") return null;

    // some old canvases didn't have lineType in some connectors :-O
    // I have no idea how it happened, but the element can't work without it!!
    if (!element.lineType) return null;

    if (p1 == "not-loaded" || p2 == "not-loaded") {
      // If we're connected to another shape we and don't have its info yet,
      // we don't render but instead we return an empty Group with our key,
      // just so react won't unmount the component.
      // note: this happens on initial load, after connecting, maybe other cases
      return (
        <Group
          key={uniqueId}
          isCanvasElement={false}
          isSelectable={isSelectable}
          isConnectable={false}
          isConnector={false}
          isFrame={false}
        />
      );
    }

    const data = computeConnectorDrawingData(p1, p2, element);

    if (element.lineType == "elbow") {
      // convert from absolute rotation to relative rotation, since I don't
      // want the elbow line to change shape when rotating
      // This is needed because elbow lines still use old drawing algorithm

      // todo: supporting snapping to anywhere on the outline means that rotation isn't just 0,90,180,270
      // it's easy for lines and curves, but for elbow lines it's might be more complicated.
      if (p1.rotation && element.rotate) {
        p1.rotation = (p1.rotation - element.rotate) as Degrees;
      }
      if (p2.rotation && element.rotate) {
        p2.rotation = (p2.rotation - element.rotate) as Degrees;
      }
    }
    function refreshFrameId(index: AnchorIndex, worldPos: PointInCanvas) {
      const cached = anchorsFrameIds.current[index];
      if (cached == null || !arePointsEqual(cached.pos, worldPos)) {
        let id;
        if (element.connectedShapes[index]?.id) {
          id = getShape(index)?.frameId;
        } else {
          id = layer ? findFrameForPosition(worldPos, layer) : undefined;
        }
        if (id != undefined) {
          anchorsFrameIds.current[index] = { pos: worldPos, frameId: id };
        }
      }
    }

    // During rendering we recheck the frames for both anchor.
    // It might seem enough to check this on drag-end of the anchors, but also
    // the connected shapes might have moved and we need to check again.
    const startPoint = { ...elementPointToCanvasPoint(p1), rotation: p1.rotation };
    const endPoint = { ...elementPointToCanvasPoint(p2), rotation: p2.rotation };
    refreshFrameId(AnchorIndex.Start, startPoint as unknown as PointInCanvas);
    refreshFrameId(AnchorIndex.End, endPoint as unknown as PointInCanvas);

    const firstFid = anchorsFrameIds.current[AnchorIndex.Start]?.frameId ?? "";
    const secondFid = anchorsFrameIds.current[AnchorIndex.End]?.frameId ?? "";
    // a connector is in a frame, if both its endpoints are in the same frame
    if (firstFid == secondFid && firstFid != "") {
      if (element.frameId != firstFid) {
        changeElement({ frameId: firstFid }, { shouldAdd: false });
      }
    } else {
      if (!!element.frameId) {
        changeElement({ frameId: "" }, { shouldAdd: false });
      }
    }

    return (
      <>
        <Group
          id={uniqueId}
          name={uniqueId}
          key={uniqueId}
          type={consts.CANVAS_ELEMENTS.CONNECTOR}
          x={element.x}
          y={element.y}
          scaleX={element.scaleX}
          scaleY={element.scaleY}
          rotation={element.rotate}
          isSelectable={isSelectable}
          startpoint={p1}
          endpoint={p2}
          {...mutation.getCallbacks()}
          isCanvasElement={true}
          isConnectable={false}
          isConnector={true}
          isDraggable={true}
          isFrame={false}
          isTaskConvertible={false}
          element={element}
        >
          <ConnectorLineAndText
            p1={p1 as any}
            p2={p2 as any}
            element={element}
            data={data}
            isEditing={isEditing}
            onChangeElement={changeElement}
          />
        </Group>
        <Portal enabled selector=".Overlay">
          {drawHandles && (
            <>
              <AnchorPoints
                start={startPoint}
                end={endPoint}
                onDragStart={onDragStart}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
              />
              <ConnectorShapeHandles
                data={data}
                element={element}
                onChangeElement={changeElement}
                p1={p1 as any}
                p2={p2 as any}
              />
            </>
          )}
          {dragState?.shapeSockets && <ConnectionPoints anchorPoints={dragState.shapeSockets} />}
          {dragState?.highlightedSocket && (
            <SingleConnectionPoint point={dragState.highlightedSocket} highlight={true} />
          )}
        </Portal>
      </>
    );
  }
);

export function GhostConnector({
  id,
  p1,
  p2,
  lineType,
  element,
  curveStrength,
}: {
  id: string;
  p1: ConnectorEndpoint;
  p2: ConnectorEndpoint;
  lineType: "line" | "curve" | "elbow";
  element: any;
  curveStrength?: number;
}) {
  const data = computeConnectorDrawingData(
    p1,
    p2,
    { lineType, anchorsMode: element.anchorsMode, innerPoints: element.innerPoints },
    curveStrength
  );
  return <ConnectorLine id={id} p1={p1} p2={p2} element={element} data={data} />;
}

function ConnectorLine({
  p1,
  p2,
  element,
  data,
  clipRect,
  id,
}: {
  p1: ConnectorEndpoint;
  p2: ConnectorEndpoint;
  data: utils.SimpleConnectorData;
  clipRect?: IRect;
  id?: string;
    element: {
    scaleX?: number;
    scaleY?: number;
    rotate?: number;
    stroke: string;
    strokeWidth: number | string;
    pointerStyles: ("arrow" | "none")[];
    anchorsMode?: null | (null | string)[];
    anchorIndexes: number[];
    activeAnchorIndex?: number | null;
    dash?: number;
    lineType: "line" | "curve" | "elbow";
    groupId?: string;
  };
}) {
  const scaleX = element.scaleX ?? 1;
  const scaleY = element.scaleY ?? 1;
  const strokeWidth = parseStrokeWidth(element.strokeWidth);
  const strokeColor = element.stroke;
  const pointerStyles = element.pointerStyles;
  const selfRect = useMemo(() => data.getSelfRect(), [data]);
  // todo: visual style (width,color,dash,arrows) should be extracted by caller and passed as props
  // so ghost element can supply them
  const distBetweenArrowHeads = PointUtils.distance(p1, p2);
  const maxArrowHeadSize = distBetweenArrowHeads / 2.5;

  function scale(p: Point) {
    p.x *= scaleX;
    p.y *= scaleY;
    return p;
  }
  return (
    <>
      <Shape
        id={id}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeEnabled={true}
        hitStrokeWidth={0}
        activeAnchorIndex={element.activeAnchorIndex}
        lineJoin="round"
        points={[p1, p2]} // todo: needed for elbow code, which should be depracted
        connectorData={data}
        {...calcDashProperties(strokeWidth, element.dash)}
        sceneFunc={(context, shape) => {
          // Clip the area where the text label is
          if (clipRect) {
            if ((context as any).isPdfContext) {
              (context as any).cliphole(clipRect,element);
            } else {
              clipHole(context, clipRect, scaleX, scaleY, element.rotate as Degrees);
            }
          }

          // Elbow lines are drawn here, because they stil use the old algorithm
          if (element.lineType == "elbow") {
            let renderer = utils.getElBowForBackCompat({
              start: p1,
              end: p2,
              ...element,
            });
            const isConnected = !!element.anchorsMode && element.anchorsMode.every((x: any) => fixMode(x) != "n/a");

            // first render to an internal object to record the commands so we can calculate stuff.
            let pathSegments = new utils.RecordCanvasCmds();
            renderer(pathSegments, shape, [p1, p2], isConnected, element.anchorsMode! as any);
            // now render and stroke without the scale
            renderer(context, shape, [p1, p2], isConnected, element.anchorsMode! as any);
            context.scale(1 / scaleX, 1 / scaleY);
            context.strokeShape(shape);

            // === Drawing arrows ===
            if (pointerStyles && (pointerStyles[0] != "none" || pointerStyles[1] != "none")) {
              context.beginPath();
              if (pointerStyles[0] === "arrow") {
                const position = scale(utils.lastPoint(pathSegments.segments));
                const dir = utils.endTangent(pathSegments.segments);
                const [p1, p2] = utils.computeArrowPoints(position, dir, strokeWidth, maxArrowHeadSize);
                context.moveTo(p1.x, p1.y);
                context.lineTo(position.x, position.y);
                context.lineTo(p2.x, p2.y);
              }
              if (pointerStyles[1] === "arrow") {
                const position = scale(utils.firstPoint(pathSegments.segments));
                const dir = utils.startTangent(pathSegments.segments);
                const [p1, p2] = utils.computeArrowPoints(position, dir, strokeWidth, maxArrowHeadSize);
                context.moveTo(p1.x, p1.y);
                context.lineTo(position.x, position.y);
                context.lineTo(p2.x, p2.y);
              }
              let arrowShape: Konva.Shape = new Konva.Shape({
                dash: [],
                dashEnabled: true,
                lineCap: "round",
                lineJoin: "round",
                stroke: element.stroke,
                strokeWidth: strokeWidth,
                strokeEnabled: true,
              });
              context.strokeShape(arrowShape);
            }

            let box = utils.calcBbox(pathSegments.segments);

            let x = box.x;
            let y = box.y;
            let width = box.width;
            let height = box.height;
            shape.getSelfRect = () => ({ x, y, width, height });
            box = box.addPadding(strokeWidth / 2);
            shape.attrs.bbox = box.asRect();
          } else {
            // Line and bezier curves are drawn here using the new class
            context.beginPath();
            data.drawOnCanvas(context);
            // remove scale so stroke isn't scaled
            context.scale(1 / scaleX, 1 / scaleY);
            context.strokeShape(shape);
            // make sure selfRect is correct for this shape
            shape.getSelfRect = () => selfRect;
          }
        }}
      />
      {pointerStyles && element.lineType != "elbow" && (
        // We undo the scale because for the purposes of computing arrows they interfere
        // The arrow size should be the same regardless of connector scale,
        // and arrow
        <Group scaleX={1 / scaleX} scaleY={1 / scaleY}>
          <ArrowHead
            position={scale(data.finalPoint())}
            comingFrom={scale(data.preFinalPoint())}
            arrowHead={pointerStyles[0]}
            strokeWidth={strokeWidth}
            strokeColor={strokeColor}
            allowedSize={maxArrowHeadSize}
          />
          <ArrowHead
            position={scale(data.firstPoint())}
            comingFrom={scale(data.secondPoint())}
            arrowHead={pointerStyles[1]}
            strokeWidth={strokeWidth}
            strokeColor={strokeColor}
            allowedSize={maxArrowHeadSize}
          />
        </Group>
      )}
    </>
  );
}

/**
 * This function prepares an inverted clip area - a "hole" where nothing is drawn,
 * unlike normal clip that defines the area where drawing happens.
 * The trick is to to clip a large area, then clip an "anti" rect inside (drawn counter-clockwise)
 * and use the 'evenodd' clip rule
 * I owe this trick to https://stackoverflow.com/questions/6271419/how-to-fill-the-opposite-shape-on-canvas
 * @param context
 * @param clipRect
 * @param scaleX   - x scale for the clip rect
 * @param scaleY   - y scale for the clip rect
 * @param rotate   - optinal rotation for the clip rect (rotated around its center)
 */
function clipHole(context: Konva.Context, clipRect: IRect, scaleX: number, scaleY: number, rotate?: Degrees) {
  const halfW = clipRect.width / 2,
    halfH = clipRect.height / 2,
    x = clipRect.x + halfW,
    y = clipRect.y + halfH;
  const MAX_CANVAS_SIZE = 32766; // it's 32767 but I'm paranoid

  let path = new Path2D();
  path.rect(-MAX_CANVAS_SIZE / 2, -MAX_CANVAS_SIZE / 2, MAX_CANVAS_SIZE, MAX_CANVAS_SIZE);
  path.rect(scaleX * halfW, -scaleY * halfH, -scaleX * clipRect.width, scaleY * clipRect.height);

  const saved = context._context.getTransform();
  context.translate(x, y); // move to center of the rect, for the rotation
  context.scale(1 / scaleX, 1 / scaleY);
  rotate && context.rotate(-toRadians(rotate));
  context._context.clip(path, "evenodd");
  context._context.setTransform(saved);
}

function ArrowHead({
  arrowHead,
  position,
  comingFrom,
  strokeWidth,
  strokeColor,
  allowedSize,
}: {
  arrowHead: string;
  position: Point;
  comingFrom: Point;
  strokeWidth: number;
  strokeColor: string;
  allowedSize: number;
}) {
  if (arrowHead == "none") return null;
  if (PointUtils.distance(comingFrom, position) < 1) return null;
  let direction = PointUtils.vectorFromTo(comingFrom, position);
  PointUtils.normalize(direction, direction);
  const [p1, p2] = utils.computeArrowPoints(position, direction, strokeWidth, allowedSize);
  return (
    <Line
      points={[p1.x, p1.y, position.x, position.y, p2.x, p2.y]}
      lineCap="round"
      lineJoin="round"
      listening={false}
      strokeWidth={strokeWidth}
      stroke={strokeColor}
    />
  );
}

function ConnectorLineAndText({
  p1,
  p2,
  element,
  data,
  isEditing,
  onChangeElement,
}: {
  p1: PointAndDirection;
  p2: PointAndDirection;
  element: Connector;
  data: utils.SimpleConnectorData;
  isEditing: boolean;
  onChangeElement?: OnChangeElementFn;
}) {
  const isThumbnailExport = useAtomValue(isThumbnailExportModeAtom);
  const [textSize, setTextSize] = useState<{ width: number; height: number }>({ width: 0, height: 48 });
  const updateTextSize = (w: number, h: number) => {
    if (w != textSize.width || h != textSize.height) setTextSize({ width: w, height: h });
  };
  const { scaleX = 1, scaleY = 1, textLocation = 0.5 } = element;
  const undoRef = useRef<any>(null);

  function computeTextPosition() {
    if (element.lineType != "elbow") {
      if (data.segments.length == 0) {
        console.warn("don't have data.segments for connector text label");
        return [0, 0];
      }
      let metrics = new utils.PathMetrics(data);
      const anchorPoint = metrics.getPointAlongPath(textLocation);
      return anchorPoint;
    }
    const segments = new utils.RecordCanvasCmds();
    let renderer = utils.getElBowForBackCompat({
      ...element,
      start: p1,
      end: p2,
    });
    const isConnected = !!element.anchorsMode && element.anchorsMode.every((x) => fixMode(x) != "n/a");
    renderer(segments, null, [p1, p2], isConnected, element.anchorsMode! as any);
    return utils.evaluatePointInCurve(segments.segments)(textLocation);
  }

  function getEvaluator(): (t: number) => Point2 {
    if (element.lineType == "elbow") {
      const segments = new utils.RecordCanvasCmds();
      let renderer = utils.getElBowForBackCompat({
        ...element,
        start: p1,
        end: p2,
      });
      const isConnected = !!element.anchorsMode && element.anchorsMode.every((x) => fixMode(x) != "n/a");
      renderer(segments, null, [p1, p2], isConnected, element.anchorsMode! as any);
      return utils.evaluatePointInCurve(segments.segments);
    } else {
      //todo: path metrics is a wasteful way to calculate points along the path.
      let metrics = new utils.PathMetrics(data);
      return metrics.getPointAlongPath.bind(metrics);
    }
  }

  // todo: when moving the text label we update element.textLocation and then recompute
  // textLabelPosition. This is very wasteful since we recompute points along the path!
  const textLabelPosition = useMemo(computeTextPosition, [
    element.lineType,
    element.textLocation,
    element.text,
    p1,
    p2,
    data,
  ]);

  const hasText = element.text?.length || isEditing;

  let clipRect: undefined | IRect = undefined;
  if (hasText) {
    clipRect = {
      x: textLabelPosition[0] - textSize.width / scaleX / 2,
      y: textLabelPosition[1] - textSize.height / scaleY / 2,
      width: textSize.width / scaleX,
      height: textSize.height / scaleY,
    };
  }

  const onTextDrag = (e: Konva.KonvaEventObject<DragEvent>) => {
    //todo: when user starts pressing space she's moving the stage. abort drag of element
    //    if (isSpacePressed) {
    //      e.target.stopDrag();
    //      return;
    //    }
    const type = (e as any).type;
    if (type == "dragstart") {
      const getpoint = getEvaluator();
      const curvePoints = initArray(1001, (n) => getpoint(n / 1000));
      undoRef.current = { textLocation, curvePoints };
    } else if (type == "dragend") {
      // todo: I can calculate the closest point on the path to the mouse position here.
      // in dragmove I go for faster solution, but here I can go for best
      onChangeElement &&
        onChangeElement(
          { textLocation: element.textLocation },
          {
            shouldAdd: true,
            previousProps: {
              textLocation: undoRef.current.textLocation,
            },
          }
        );
      undoRef.current = null;
    } else if (type == "dragmove") {
      const mousePos = e.currentTarget.position();
      const distanceToMouse = (p: Point2) => PointUtils.lenSqr({ x: p[0], y: p[1] }, mousePos);
      const curvePoints = undoRef.current.curvePoints;
      let [, indexClosestCurvePoint] = minIndexBy(distanceToMouse, curvePoints);
      const t = indexClosestCurvePoint / (curvePoints.length - 1);
      onChangeElement && onChangeElement({ textLocation: t }, { shouldAdd: false });
    }
  };

  return (
    <>
      <ConnectorLine p1={p1} p2={p2} element={element} data={data} clipRect={clipRect} />
      {!isThumbnailExport && textLabelPosition && (
        <ConnectorTextLabel
          element={element}
          updateText={(initial: string, text: string) => {
            onChangeElement && onChangeElement({ text }, { shouldAdd: true, previousProps: { text: initial } });
          }}
          position={{ x: textLabelPosition[0], y: textLabelPosition[1] }}
          textSize={textSize}
          updateTextSize={updateTextSize}
          isEditing={isEditing}
          onTextDrag={onTextDrag}
        />
      )}
    </>
  );
}

function AnchorPoints({
  start,
  end,
  onDragStart,
  onDragMove,
  onDragEnd,
}: {
  start: Point;
  end: Point;
  onDragStart: (index: number, evt: KonvaEventObject<MouseEvent>) => void;
  onDragMove: (index: number, mousePosition: PointInCanvas, evt: KonvaEventObject<MouseEvent>) => void;
  onDragEnd: (index: number, mousePosition: PointInCanvas, evt: KonvaEventObject<MouseEvent>) => void;
}) {
  const stageScale = useAtomValue(posScaleAtom).scale;
  const isSpacePressed = useKeyPress("space")[0];

  const renderAnchor = (anchor: Point, index: AnchorIndex) => {
    return (
      <Group
        x={anchor.x}
        y={anchor.y}
        name="connector-anchor"
        key={index}
        index={index}
        draggable
        onDragStart={(e) => {
          onDragStart(e.target.attrs.index, e);
        }}
        onDragEnd={(e) => {
          if (!e.evt) {
            // dragging was cancelled because space was pressed
            return;
          }
          let point = e.target.getPosition() as PointInCanvas;
          onDragEnd(e.target.attrs.index, point, e);
        }}
        onDragMove={(e) => {
          if (isSpacePressed) {
            e.target.stopDrag();
            return;
          }
          let index = e.target.attrs.index;
          let point = e.target.getPosition() as PointInCanvas;
          onDragMove(index, point, e);
        }}
      >
        <Circle
          radius={utils.ConnectorTransformPointRadius / stageScale}
          fill="white"
          stroke={HANDLE_COLOR}
          strokeWidth={2 / stageScale}
        />
      </Group>
    );
  };
  return (
    <>
      {renderAnchor(start, AnchorIndex.Start)}
      {renderAnchor(end, AnchorIndex.End)}
    </>
  );
}

function SingleConnectionPoint({ point, highlight }: { point: Point; highlight: boolean }) {
  const scale = useAtomValue(posScaleAtom).scale;
  return (
    <Circle
      x={point.x}
      y={point.y}
      listening={false}
      radius={utils.ConnectorSnapPointRadius / scale}
      fill={highlight ? ShapeSocketColor : "white"}
      stroke={highlight ? "white" : ShapeSocketColor}
      strokeWidth={2 / scale}
    />
  );
}

function ConnectionPoints({ anchorPoints }: { anchorPoints: Point[] }) {
  return (
    <>
      {anchorPoints.map((p, index) => (
        <SingleConnectionPoint point={p} highlight={false} key={"anchor connection-point" + index} />
      ))}
    </>
  );
}

function ConnectorShapeHandles({
  p1,
  p2,
  data,
  element,
  onChangeElement,
}: {
  p1: PointAndDirection;
  p2: PointAndDirection;
  data: utils.SimpleConnectorData;
  element: Connector;
  onChangeElement?: OnChangeElementFn;
}) {
  if (element.lineType == "elbow") return null;

  const scale = useAtomValue(posScaleAtom).scale;
  const isSpacePressed = useKeyPress("space")[0];
  const transform = konvaTransformForElement(element);
  const invTr = transform.copy().invert();

  const points = useMemo(() => {
    let metrics = new utils.PathMetrics(data);
    let result = new Array<Point & { id: string; real: boolean }>();
    let pathPointIndex = 0;
    for (const [x, y, real] of metrics.pathPointsAndPossibleBreakpoints()) {
      let p = transform.point({ x, y });
      result.push({ x: p.x, y: p.y, real, id: real ? element.innerPoints![pathPointIndex++].id : nanoid(10) });
    }
    return result;
  }, [p1, p2, data, transform]);

  return (
    <>
      {points.map((point) => (
        <Circle
          key={point.id}
          id={point.id}
          name="connector-shape-handle anchor"
          x={point.x}
          y={point.y}
          real={point.real}
          draggable={true}
          undoData={null}
          onDragStart={(e) => {
            e.target.setAttr("undoData", element.innerPoints ?? []);
            if (e.target.attrs.real == false) {
              let myindex = points.findIndex((p) => p.id == e.target.attrs.id);
              const pos = invTr.point(e.target.position());
              if (element.innerPoints) {
                let newInnerPoints = element.innerPoints.slice();
                let index = Math.floor(myindex / 2);
                newInnerPoints.splice(index, 0, {
                  x: pos.x,
                  y: pos.y,
                  id: e.target.attrs.id,
                });
                onChangeElement && onChangeElement({ innerPoints: newInnerPoints }, { shouldAdd: false });
              } else {
                let newInnerPoints = [{ x: pos.x, y: pos.y, id: point.id }];
                onChangeElement && onChangeElement({ innerPoints: newInnerPoints }, { shouldAdd: false });
              }
            }
          }}
          onDragEnd={(e) => {
            if (!e.evt) {
              return; // stopped dragging because of space.
            }
            onChangeElement &&
              onChangeElement(
                { innerPoints: element.innerPoints },
                {
                  shouldAdd: true,
                  previousProps: { innerPoints: e.target.attrs.undoData },
                }
              );
          }}
          onDragMove={(e) => {
            if (isSpacePressed) {
              e.target.stopDrag();
              return;
            }
            const i = element.innerPoints?.findIndex((p) => p.id == e.target.attrs.id);
            if (i == undefined || i == -1) {
              // it's possible we didn't find this point in element.innerPoints,
              // because we didn't get from replicache the update to the element.
              // I've seen this happen, it lasts for a few ms, and then the update comes in.
              return;
            }
            const pos = invTr.point(e.target.position());
            let newPoints = element.innerPoints!.slice();
            newPoints[i] = { ...newPoints[i], x: pos.x, y: pos.y };
            onChangeElement && onChangeElement({ innerPoints: newPoints }, { shouldAdd: false });
          }}
          onDblClick={(e: any) => {
            if (e.target.attrs.real) {
              let newpoints = element.innerPoints!.filter((p) => p.id != e.target.attrs.id);
              onChangeElement &&
                onChangeElement(
                  { innerPoints: newpoints },
                  { shouldAdd: true, previousProps: { innerPoints: element.innerPoints } }
                );
            }
          }}
          radius={utils.ConnectorTransformPointRadius / scale}
          fill={point.real ? "white" : HANDLE_COLOR_SEMI_TRANSPARENT}
          stroke={point.real ? HANDLE_COLOR : "white"}
          strokeWidth={2 / scale}
        />
      ))}
    </>
  );
}

function computeConnectorDrawingData(
  p1: ConnectorEndpoint,
  p2: ConnectorEndpoint,
  element: Partial<Connector>,
  curveStrength?: number
) {
  let data = new utils.SimpleConnectorData();

  switch (element.lineType) {
    case undefined:
      console.warn("lineType must be supplied");
      break;

    case "line": {
      data.moveTo(p1.x, p1.y);
      if (element.innerPoints) {
        for (const p of element.innerPoints) {
          data.lineTo(p.x, p.y);
        }
      }
      data.lineTo(p2.x, p2.y);
      break;
    }
    case "curve": {
      // Bezier curve extend from shapes perpendicularly.
      // Our connector might be rotated and scaled non-uniformly, and that means directions will be twisted.
      // I pass the rotation+scale transform to offset that
      let points = computeCurve(
        p1,
        p2,
        konvaTransformForElement({
          x: 0,
          y: 0,
          scaleX: element.scaleX ?? 1,
          scaleY: element.scaleY ?? 1,
          rotate: element.rotate ?? 0,
        }),
        element.innerPoints,
        curveStrength
      );
      data.moveTo(points[0], points[1]);
      let i = 2;
      while (i < points.length) {
        // if (i == points.length - 6) {
        //   const cp1x = points[i++],
        //     cp1y = points[i++],
        //     x2 = points[i++],
        //     y2 = points[i++];
        //   data.quadraticCurve(cp1x, cp1y, x2, y2);
        // } else
        {
          const cp1x = points[i++],
            cp1y = points[i++],
            cp2x = points[i++],
            cp2y = points[i++],
            x2 = points[i++],
            y2 = points[i++];
          data.bezierCurve(cp1x, cp1y, cp2x, cp2y, x2, y2);
        }
      }

      break;
    }
    case "elbow":
      //todo
      break;

    default:
      const _unhandled: never = element.lineType;
  }
  return data;
}

function prepNormal(inverseTransform: Konva.Transform, rotation: Degrees) {
  const rad = toRadians(rotation);
  const dir = inverseTransform.point({
    x: Math.cos(rad),
    y: Math.sin(rad),
  });
  return PointUtils.normalize(dir, dir);
}

function computeCurve(
  start: ConnectorEndpoint,
  end: ConnectorEndpoint,
  elementScaleAndRotation: Konva.Transform,
  innerPoints: InnerPointSchema[] | undefined,
  curveStrength?: number
) {
  // we invert the scale-rotation matrix so we can apply it to normal vectors
  // The point is that we want to compute normals in the connector reference frame,
  // but they will be scaled and rotated and deformed !
  // so we apply the inverse transform, and then the real transform will bring them
  // back to their good shape.
  elementScaleAndRotation.invert();

  if (!innerPoints?.length) {
    return computeCurveWithoutInnerPoints(start, end, elementScaleAndRotation, curveStrength);
  }

  let points = computeCurveWithInnerPoints(start, end, innerPoints);
  if (start.rotation != undefined) {
    const dir = prepNormal(elementScaleAndRotation, start.rotation);
    const availableLen = Math.max(Math.abs(innerPoints[0].x - start.x), Math.abs(innerPoints[0].y - start.y));
    const controlPointDist = availableLen / 2;
    const x = points[0] + dir.x * controlPointDist;
    const y = points[1] + dir.y * controlPointDist;
    points[2] = x;
    points[3] = y;
  }
  if (end.rotation != undefined) {
    const dir = prepNormal(elementScaleAndRotation, end.rotation);
    const N = innerPoints.length - 1;
    const availableLen = Math.max(Math.abs(innerPoints[N].x - end.x), Math.abs(innerPoints[N].y - end.y));
    const controlPointDist = availableLen / 2;
    const x = points[points.length - 2] + dir.x * controlPointDist;
    const y = points[points.length - 1] + dir.y * controlPointDist;
    points[points.length - 4] = x;
    points[points.length - 3] = y;
  }
  return points;
}

function computeCurveWithoutInnerPoints(
  start: ConnectorEndpoint,
  end: ConnectorEndpoint,
  invTr: Konva.Transform,
  curveStrength?: number
) {
  if (start.rotation == undefined && end.rotation == undefined) {
    let f = 0.5 * (end.x - start.x);
    let u = 0.5 * (end.y - start.y);
    // note: the -0.0001 improves stability for cases when dx==dy
    if (Math.abs(f) > Math.abs(u) - 0.0001) {
      u = 0;
    } else {
      f = 0;
    }
    return [start.x, start.y, start.x + f, start.y + u, end.x - f, end.y - u, end.x, end.y];
  } else if (start.rotation != undefined && end.rotation != undefined) {
    const dx = Math.abs(end.x - start.x);
    const dy = Math.abs(end.y - start.y);
    const dist = curveStrength ?? smoothMaxUnit(10, Math.max(dx, dy) * 0.25);

    // these are the directions I want start and end points to have
    // I use the inverse element transform first, and when drawn I'll get the correct vectors
    let startDir = prepNormal(invTr, start.rotation);
    let endDir = prepNormal(invTr, end.rotation);

    const cp1x = start.x + startDir.x * dist;
    const cp1y = start.y + startDir.y * dist;
    const cp2x = end.x + endDir.x * dist;
    const cp2y = end.y + endDir.y * dist;
    return [start.x, start.y, cp1x, cp1y, cp2x, cp2y, end.x, end.y];
  } else {
    const dx = Math.abs(end.x - start.x);
    const dy = Math.abs(end.y - start.y);
    let dist = smoothMaxUnit(50, Math.max(dx, dy) * 0.25);
    // let len = Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2));
    // let dist = len / 2;

    let cp1x, cp1y;
    if (start.rotation != undefined) {
      let normal = prepNormal(invTr, start.rotation);
      cp1x = start.x + normal.x * dist;
      cp1y = start.y + normal.y * dist;
    } else {
      let normal = prepNormal(invTr, end.rotation!);
      cp1x = end.x + normal.x * dist;
      cp1y = end.y + normal.y * dist;
    }

    return [
      start.x,
      start.y,
      cp1x,
      cp1y,

      // todo: remove these points. this should be a quadratic curve, not cubic
      // but I need to support quadratic curves elsewhere
      cp1x,
      cp1y,

      end.x,
      end.y,
    ];
  }
}

function computeCurveWithInnerPoints(
  start: ConnectorEndpoint,
  end: ConnectorEndpoint,
  innerPoints: InnerPointSchema[]
) {
  let result = [start.x, start.y, 0, 0]; // the tangent of the start-point to be filled later
  for (let i = 0; i < innerPoints.length; i++) {
    const prev = i == 0 ? start : innerPoints[i - 1];
    const cur = innerPoints[i];
    const next = i == innerPoints.length - 1 ? end : innerPoints[i + 1];
    let toPrev = { x: cur.x - prev.x, y: cur.y - prev.y };
    let toNext = { x: cur.x - next.x, y: cur.y - next.y };
    const len1 = Math.sqrt(toPrev.x * toPrev.x + toPrev.y * toPrev.y);
    const len2 = Math.sqrt(toNext.x * toNext.x + toNext.y * toNext.y);
    // the bisector for this point = (toPrev/len1 + toNext/len2)

    let bisector = { x: toPrev.x / len1 + toNext.x / len2, y: toPrev.y / len1 + toNext.y / len2 };
    PointUtils.normalize(bisector);
    // tangents are rotate(bisector, 90) and it's opposite
    // bisector can be(0,0), if cur is in the middle of [prev,next]
    // that will insert NaN into the calculations, so we check it and
    const tangent = PointUtils.invalid(bisector) ? PointUtils.normalized(toPrev) : PointUtils.rotated90(bisector);

    // tangents length should be some multiple of min(len1,len2)
    const tlen = 0.4 * Math.min(len1, len2);
    // compute control points:  cur ± tangent*tlen
    let cp1 = PointUtils.pAdd(cur, PointUtils.pMul(tangent, tlen));
    let cp2 = PointUtils.pSub(cur, PointUtils.pMul(tangent, tlen));
    // put control points in the right order; by looking at their angle with the tangent
    // angle is checked with dot product (if angle of 2 vectors < 90 deg, dot > 0)
    if (tangent.x * toPrev.x + tangent.y * toPrev.y > 0) {
      const temp = cp1;
      cp1 = cp2;
      cp2 = temp;
    }
    result.push(cp1.x, cp1.y, cur.x, cur.y, cp2.x, cp2.y);
  }
  result.push(0, 0, end.x, end.y);
  // fill tangents for start and end; default is towards next and prev points
  // next point for start is at indexes 6,7
  let px = result[6],
    py = result[7];
  let cp = PointUtils.lerp(start, { x: px, y: py }, 0.3);
  result[2] = cp.x;
  result[3] = cp.y;
  // prev point for end is at indexes -8,-7
  px = result[result.length - 8];
  py = result[result.length - 7];
  cp = PointUtils.lerp(end, { x: px, y: py }, 0.3);
  result[result.length - 4] = cp.x;
  result[result.length - 3] = cp.y;
  // caller can override the normals if she wishes
  return result;
}

export function connectorTraits(element: Connector): ITraits {
  return {
    lineColor: element.stroke,
    connectorLineWidth: element.strokeWidth,
    dash: element.dash ?? 0,

    firstEndpoint: element.pointerStyles?.[1] == "arrow",
    lineStyle: element.lineType,
    secondEndpoint: element.pointerStyles?.[0] == "arrow",

    textColor: element.textColor ?? consts.DEFAULTS.TEXT_COLOR,
    fontProps: element.fontProps ?? 0,
    fontSize: getFontSize(element),
    font: element.font ?? consts.DEFAULTS.FONT,
  };
}

export function connectorValidateTrait(element: Connector, trait: Trait, value: any) {
  if (trait == Trait.firstEndpoint) {
    let pointerStyles = [...element.pointerStyles];
    pointerStyles[1] = value ? "arrow" : "none";
    return { pointerStyles };
  }
  if (trait == Trait.secondEndpoint) {
    let pointerStyles = [...element.pointerStyles];
    pointerStyles[0] = value ? "arrow" : "none";
    return { pointerStyles };
  }
  if (trait == Trait.lineColor && typeof value == "number") {
    return replaceColorOpacity(element.stroke, value);
  }
  if (trait == Trait.textColor && typeof value == "number") {
    return replaceColorOpacity(element.textColor, value);
  }
  return value;
}
