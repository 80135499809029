import React, { useCallback, useEffect, useMemo, useState } from "react";
import Konva from "konva";
import { Circle, Group, Image, Path, Rect, Text } from "react-konva";
import { useImage } from "react-konva-utils";
import { format } from "date-fns";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { useItemsFromReplicache } from "frontend/subscriptions";
import consts from "shared/consts";
import { EditableText } from "frontend/editableText";
import { TaskCard } from "shared/datamodel/schemas/task-card";
import TaskCardOpen from "./task-card-open-element";
import { TagType } from "shared/datamodel/schemas/metadata";
import { SimpleFlexRowLayout } from "frontend/utils/layout-utils";
import tracking from "frontend/tracking";
import { useAtom, useAtomValue } from "jotai";
import { editingElementIdAtom, isExportingAtom, posScaleAtom } from "state-atoms/stage-atoms";
import { accountAtom, boardAtom, userAtom } from "state-atoms/users-atoms";
import { focusedElementIdAtom } from "state-atoms/url-atoms";
import RoundedCornersContainer from "../rounded-corners-container";
import { canvasMetadataPrefix } from "shared/util/utils";

export default function TaskCardElement({
  syncService,
  id,
  element,
  onChangeElement,
  onHeightChange,
  isReadOnly,
}: {
  syncService?: SyncService<RW>;
  id: string;
  element: TaskCard;
  onChangeElement: (
    props: any,
    undoConfig: { shouldAdd: boolean; previousProps?: any },
    updateSubMenuData?: any
  ) => void;
  onHeightChange: (height: number) => void;
  isReadOnly: boolean;
}) {
  //atoms
  const editingElementId = useAtomValue(editingElementIdAtom);
  const scale = useAtomValue(posScaleAtom).scale;
  const user = useAtomValue(userAtom);
  const account = useAtomValue(accountAtom);
  const board = useAtomValue(boardAtom);
  const [focusedElementId, setFocusedElementId] = useAtom(focusedElementIdAtom);
  const isExportingCanvas = useAtomValue(isExportingAtom);

  const { color, title } = element;
  const userPartOfBoardAccount = user?.account?.id && board?.accountId && user?.account?.id == board?.accountId;

  const shouldOpenSelf = focusedElementId == id;

  const [isOpen, setIsOpen] = useState<boolean>(shouldOpenSelf);
  if (shouldOpenSelf) {
    // once we read the value and initialized our 'open' state we delete it,
    // so on page-refresh we don't open the card again
    window.sessionStorage.setItem(consts.SET_POSITION_KEY, JSON.stringify({ x: element.x, y: element.y }));
  }
  useEffect(() => {
    if (isOpen) {
      setFocusedElementId(id);
      tracking.trackEvent(
        consts.TRACKING_CATEGORY.CANVAS_ACTION,
        "task_card_opened",
        shouldOpenSelf ? "from_share_link" : "from_canvas",
        account?.id,
        user?.id
      );
    }
  }, [isOpen]);

  let allTags = syncService ? useItemsFromReplicache(syncService.getReplicache()!, canvasMetadataPrefix + TagType) : [];

  // Constants for layout and style of text
  const textProperties = {
    fontFamily: "Poppins",
    fontSize: 14,
    lineHeight: 1.5,
    fill: "#FFFFFF",
    letterSpacing: 0.150188,
  };

  // The card is a vertical stack composed of:
  // top bar     (fixed height)
  // large space (fixed height)
  // tags area   (dynamic)
  // padding     (fixed)
  // title area  (dynamic)
  // large space (fixed height)
  // status line (fixed height)
  // padding     (fixed height)

  // I first calculate the height for the dynamic elements.
  let topBarHeight = 15,
    topSpace = 40,
    padding = 16,
    tagsY = topBarHeight + topSpace,
    tagsHeight = 0,
    titleY = 0,
    titleHeight = 0,
    statusLineY = 0,
    statusLineHeight = 16,
    innerCardWidth = consts.DEFAULTS.CARD_WIDTH - padding * 2;
  const personPhotoSize = 24;

  let tagsMetrics: any = null;
  if (allTags && element.tags?.length) {
    const isTagInElement = (id: string) => element.tags!.some((x) => id.includes(x));
    const tags = allTags.filter(([id, _]) => isTagInElement(id));
    const tagsLineHeight = 21;
    const textTemplate = new Konva.Text(textProperties);
    tagsMetrics = new SimpleFlexRowLayout(consts.DEFAULTS.CARD_WIDTH - padding * 2, tagsLineHeight, {
      xGap: 8,
      xPadding: 8,
      yGap: 8,
    });
    for (let i = 0; i < tags.length; i++) {
      let text = textTemplate.clone({ text: tags[i][1].name });
      tagsMetrics.addItem(
        text.width(),
        text.height(),
        {
          text: tags[i][1].name,
          fill: tags[i][1].color,
        },
        tags[i][0]
      );
    }
  }

  if (tagsMetrics) {
    tagsHeight = tagsMetrics.bottom;
  }

  titleY = tagsY + tagsHeight + 10; // 10px for a nice gap
  titleHeight = useMemo(() => {
    const textTemplate = new Konva.Text({
      ...textProperties,
      text: element.title,
      width: consts.DEFAULTS.CARD_WIDTH - padding * 2,
    });
    return textTemplate.height();
  }, [element.title]);

  statusLineY = titleY + titleHeight + topSpace;
  const cardHeight = statusLineY + statusLineHeight + padding;
  useEffect(() => {
    onHeightChange(cardHeight);
  }, [cardHeight]);

  const [mouseOverExpand, setMouseOverExpand] = useState<boolean>(false);
  function renderExpandButton() {
    if (isReadOnly) return null;
    const fixScaleFactor = 1 / Math.min(2, Math.max(0.5, scale));
    const size = 16 * fixScaleFactor;

    /*the path fits a view box of 15x15 pixels */
    return (
      <Group
        x={consts.DEFAULTS.CARD_WIDTH - size - 16}
        y={topBarHeight + size}
        scaleX={fixScaleFactor}
        scaleY={fixScaleFactor}
        onMouseEnter={(e) => {
          const container = e.target.getStage()!.container();
          container.style.cursor = "pointer";
          setMouseOverExpand(true);
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()!.container();
          container.style.cursor = "inherit";
          setMouseOverExpand(false);
        }}
        onClick={(e: Konva.KonvaEventObject<MouseEvent>) => {
          setIsOpen(true);
          e.target.preventDefault();
          e.cancelBubble = true;
        }}
      >
        <Circle
          x={7.5}
          y={7.5}
          radius={16}
          name="anchor" /*the name 'anchor' means the stage will ignore all mouse clicks on this button */
          fill={"transparent"}
        />
        <Path
          stroke={mouseOverExpand ? "#7517F8" : "#848199"}
          strokeWidth={1.5}
          lineCap="round"
          lineJoin="round"
          fill="transparent"
          listening={false}
          data="M1,10 v4 h4 M1,14 l5,-5 M10,1 h4 v4 M14,1 l-5,5"
        />
      </Group>
    );
  }
  const [descriptionIcon] = useImage("/images/description-icon.svg");
  const [dueDateIcon] = useImage("/images/due-date-icon.svg");
  const hasAssignee = element.assignees && element.assignees.length > 0;
  let photoUrl = hasAssignee
    ? userPartOfBoardAccount
      ? element.assignees![0].thumbnail
      : "/images/my-profile-icon.svg"
    : "";
  const [assigneePhoto, assigneePhotoStatus] = useImage(photoUrl);

  const isEditing = editingElementId === id;

  const statusLine = new SimpleFlexRowLayout(230, 30, {
    xGap: 6,
  });

  if (element.description) {
    statusLine.addItem(16, 16, { image: descriptionIcon, y: 4, key: "description" });
    statusLine.addItem(16, 16, { key: "description-space" });
  }
  if (element.dueDate != undefined && element.dueDate > 0) {
    statusLine.addItem(16, 16, { image: dueDateIcon, key: "due-date-image" });
    statusLine.addItem(100, 20, {
      ...textProperties,
      fill: "#848199",
      text: format(new Date(element.dueDate), "yyyy MMM d"),
      verticalAlign: "middle",
      key: "due-date",
    });
  }

  function renderAssignee() {
    if (isExportingCanvas) {
      const size = personPhotoSize / 2;
      return <Circle x={270 + size / 2} y={statusLineHeight / 2} fill="#000000" opacity={0.4} radius={size} />;
    }
    if (hasAssignee && assigneePhotoStatus == "loaded") {
      // If user isn't part of the account of the board, we show generic avatar for assignee
      // our svg is white-stroke, so we put background color
      return (
        <RoundedCornersContainer
          x={270}
          y={statusLineHeight / 2 - personPhotoSize / 2}
          radius={personPhotoSize / 2}
          background={userPartOfBoardAccount ? undefined : "#113255"}
        >
          <Image image={assigneePhoto} x={0.3} width={personPhotoSize} height={personPhotoSize} />
        </RoundedCornersContainer>
      );
    }
    return null;
  }

  return (
    <>
      <Rect
        width={consts.DEFAULTS.CARD_WIDTH}
        height={cardHeight}
        stroke={color}
        strokeWidth={1.5}
        shadowColor="#6F7284"
        shadowOffsetY={5}
        shadowBlur={12}
        shadowOpacity={0.15}
        perfectDrawEnabled={false}
        fill="white"
      />
      <Rect
        width={consts.DEFAULTS.CARD_WIDTH}
        height={15}
        fill={color}
      />

      <Group x={padding} y={tagsY}>
        {tagsMetrics &&
          tagsMetrics.map((tag: any, key: string) => (
            <Group key={key}>
              <Rect {...tag} cornerRadius={2} />
              <Text
                {...textProperties}
                {...tag}
                fill="white"
                align={"center"}
                verticalAlign={"middle"}
                height={tag.height + 2}
                /*
                Poppins font has large vertical size (because it has indian letters)
                When we write latin text it doesn't vertically align in the center because of this.
                My workaround: add 2 px to the height of the text box.
              */
              />
            </Group>
          ))}
      </Group>

      <EditableText
        x={padding}
        y={titleY}
        width={innerCardWidth - padding * 2}
        height={titleHeight}
        isFixedHeight={false}
        verticalAlign={"middle"}
        text={title}
        placeholder={consts.DEFAULTS.CARD_TEXT}
        id={""}
        isEditing={isEditing}
        onChange={useCallback((newValue: string) => {
          onChangeElement({ title: newValue }, { shouldAdd: false });
        }, [])}
        fontSize={textProperties.fontSize}
        font={consts.DEFAULTS.FONT}
        fill={"#0B2642"}
      />

      <Group x={padding} y={statusLineY}>
        {statusLine.map((x: any) => {
          return x.image ? <Image {...x} /> : <Text {...x} />;
        })}
        {renderAssignee()}
      </Group>

      {!isEditing && renderExpandButton()}
      {!isEditing && isOpen && syncService && (
        <TaskCardOpen
          id={id}
          syncService={syncService}
          element={element}
          onDismiss={() => {
            setIsOpen(false);
          }}
          onChangeElement={onChangeElement}
        />
      )}
    </>
  );
}
