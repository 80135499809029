import cn from "classnames";
import { replaceColorOpacity } from "frontend/utils/color-utils";
import { scan, unique } from "frontend/utils/fn-utils";
import { clamp, isPointInRect, Point } from "frontend/utils/math-utils";
import { useAtomValue } from "jotai";
import { KonvaEventObject } from "konva/types/Node";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { Group, Line, Rect, Text } from "react-konva";
import { TypeTableElement } from "shared/datamodel/schemas/table";
import { boardAtom, editingElementIdAtom, selectedElementIdsAtom, syncServiceAtom, transformerRefAtom, utilsAtom } from "state-atoms";
import { ITraits, Trait } from "../../elements-toolbar/elements-toolbar-types";
import BaseCanvasElement from "../base-canvas-element";
import { textEnabledTraits } from "../text-block-element";
import isHotkey from "is-hotkey";
import { enablePatches, produceWithPatches } from "immer";
import { useEvent, useUpdate } from "react-use";
import { EVT_ELEMENT_DROP, EVT_ELEMENT_DRAG_START } from "../card-stack/card-stack-utils";
import Konva from "konva";
import consts from "shared/consts";
import { isIdOfType } from "../canvas-elements-utils";
import { Html } from "react-konva-utils";
import { handleTabInTextArea } from "../../text-element/text-utils";
import React from "react";
import {
  useSubscribeCellsData,
  cellId,
  CellCoordinate,
  cssTextStyle,
  cellPadding,
  textStyleKonva,
  queueWork,
  cellIdForReflect,
  cellNanoid,
  TableSelection,
  useSubscribeContainedElements,
  prefixForCellsOfTable,
} from "./table-utils";
import { useHotkey } from "frontend/shortcut-context";
import { LineCap } from "konva/types/Shape";
import { ColorPicker } from "frontend/canvas-designer-new/elements-toolbar/widgets/colorPicker";
import toolbarStyle from "frontend/canvas-designer-new/elements-toolbar/elements-toolbar.module.css";
import toolbarButtonsStyle from "frontend/canvas-designer-new/elements-toolbar/toolbar-buttons.module.css";
import TextFontPicker from "frontend/canvas-designer-new/elements-toolbar/widgets/text-font-picker";
import TextProps from "frontend/canvas-designer-new/elements-toolbar/widgets/text-props";
import { TextSizePicker } from "frontend/canvas-designer-new/elements-toolbar/widgets/textSizePicker";
import { TrashIcon } from "frontend/icons/trash-icon";
import { useEventListener } from "usehooks-ts";
import CanvasElement from "frontend/canvas-designer-new/canvas-element";
import { SyncService } from "frontend/services/syncService";

enablePatches();

const isModifierOn = (e: KeyboardEvent) => e.ctrlKey || e.metaKey || e.altKey || e.shiftKey;
const isArrowKey = isHotkey(["up", "down", "left", "right"]);
const isShiftAndArrowKey = isHotkey(["shift+up", "shift+down", "shift+left", "shift+right"]);
const isCharacterKey = (e: KeyboardEvent) => e.key.length == 1 && !isModifierOn(e);
const isEnterKey = (e: KeyboardEvent) => e.key == "Enter" && !isModifierOn(e);
const isDelete = (e: KeyboardEvent) => (e.key == "Delete" || e.key == "Backspace") && !isModifierOn(e);

const SelectedCellStroke = "#0072FF";
const SelectedCellStrokeWidth = 3;

enum CellTraits {
  Background = "fill",
  TextColor = "textColor",
  Font = "font",
  FontSize = "fontSize",
  FontProps = "fontProps",
  Align = "align",
  VAlign = "valign",
}

function onDropElementsOnTable(cellKeyInReflect:string, cellData: any, ids: string[]) {
  let patch = [];
  const [_, cellPatch, cellInversePatch] = produceWithPatches((draft: any) => {
    let curContained = draft.containedIds ?? [];
    draft.containedIds = unique(curContained.concat(ids));
  })(cellData ?? {});
  patch.push({ id: cellKeyInReflect, patch: cellPatch, inversePatch: cellInversePatch });
  return patch;
}

export default function TableElement({
  id,
  element,
  onResize,
  onChangeElement,
  isSelected,
  isSelectable,
  changeElement,
  patchElement,
  patchAnything,
  onMutation,
  allElements,
}: {
  id: string;
  element: TypeTableElement;
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
  onChangeElement: (
    id: string,
    props: Map<string, any>,
    previousProps: Map<string, any>,
    addUndo: boolean,
    updateSubMenuData?: any
  ) => void;
  isSelected: boolean;
  isSelectable: boolean;
  changeElement: (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;
  patchElement: (args: { id: string; patch: any; inversePatch?: any; details?: any }) => void;
  patchAnything: (args: { id: string; patch: any; inversePatch?: any; details?: any }) => void;
  onMutation: (args: any[]) => void;
  allElements?: any;
}) {
  const syncService = useAtomValue(syncServiceAtom); //todo: support mini-stage allElements
  const transformerRef = useAtomValue(transformerRefAtom);

  const cells = useSubscribeCellsData(syncService.getReplicache(), id, allElements);
  const contained = Object.values(cells)
    .flatMap((x) => x.containedIds)
    .filter(Boolean);
  // should I send contained as as dep to useSubscribeContainedElements?
  const containedElements = useSubscribeContainedElements(syncService.getReplicache(), cells, allElements);
  const [editingCell, setEditingCell] = useState<null | CellCoordinate>(null);
  const ref = useRef<Konva.Rect>(null);

  const canvasUtils = useAtomValue(utilsAtom(useAtomValue(boardAtom)?.documentId));

  const [mouseColRow, setMouseColRow] = useState([-1, -1]);
  const [drag, setDrag] = useState({ col: -1, row: -1, dx: 0, dy: 0 });
  //todo: when dragging a table line to resize, don't show hover-widgets
  const [cursor, setCursor] = useState<null | [number, number]>(null);
  const selection = useMemo(() => new TableSelection(), [isSelected]);
  const forceRepaint = useUpdate();

  const { scaleX = 1, scaleY = 1 } = element;
  const invScaleX = 1 / scaleX;
  const invScaleY = 1 / scaleY;

  // calculate the x and y of every table line. (scan is cumulative-sum, look in wikipedia)
  const xs = scan((acc, cur) => acc + cur.size * scaleX, 0, element.cols);
  const ys = scan((acc, cur) => acc + cur.size * scaleY, 0, element.rows);

  let totalWidth = xs[xs.length - 1];
  let totalHeight = ys[ys.length - 1];

  useEffect(() => {
    if (!isSelected) {
      setEditingCell(null);
      setCursor(null);
    }
  }, [isSelected]);

  // when adding/deleting rows/cols, refresh the transformer if I'm selected
  useEffect(() => {
    if (isSelected && transformerRef.current) setTimeout(() => transformerRef.current.forceUpdate());
  }, [isSelected, element.rows, element.cols]);

  // Listening to mouse movements to know where to draw the hover-widgets
  useEffect(() => {
    if (isSelected) {
      let tr = new Konva.Transform();
      function mouseListener(e: MouseEvent) {
        ref.current?.getAbsoluteTransform().copyInto(tr);
        tr.invert();
        const point = tr.point(e);
        let x = clamp(point.x, 0, xs[xs.length - 1]);
        let y = clamp(point.y, 0, ys[ys.length - 1]);
        let col = xs.findIndex((x_) => x_ >= x);
        let row = ys.findIndex((y_) => y_ >= y);
        col = clamp(col - 1, 0, element.cols.length - 1);
        row = clamp(row - 1, 0, element.rows.length - 1);
        if (mouseColRow[0] != col || mouseColRow[1] != row) setMouseColRow([col, row]);
      }
      document.addEventListener("mousemove", mouseListener);
      return () => document.removeEventListener("mousemove", mouseListener);
    }
  }, [isSelected, ref.current, xs, ys]);

  // detect drag-and-drop over table
  useEvent(EVT_ELEMENT_DROP, (ev) => {
    if (!ref.current || !ref.current.getStage()) return;
    const tableRect = ref.current.getClientRect({ relativeTo: ref.current.getStage()! });
    const isDroppedHere = isPointInRect(ev.detail, tableRect);
    const { ids } = ev.detail;
    const x = ev.detail.x - tableRect.x;
    const y = ev.detail.y - tableRect.y;
    const col = xs.findIndex((x_) => x_ >= x) - 1;
    const row = ys.findIndex((y_) => y_ >= y) - 1;
    // queue the heavy work for later, not in the event handler
    queueWork(() => {
      if (isDroppedHere) {
        const capturableItems = ids.filter((id: string) =>
          [
            consts.CANVAS_ELEMENTS.STICKY_NOTE,
            consts.CANVAS_ELEMENTS.TEXT_BLOCK,
            consts.CANVAS_ELEMENTS.DRAWING,
            consts.CANVAS_ELEMENTS.SHAPE,
          ].some((type) => isIdOfType(id, type))
        );
        if (capturableItems.length) {
          let patch = onDropElementsOnTable(
            cellIdForReflect(id, element.rows[row].id, element.cols[col].id),
            cells[cellId(element.rows[row].id, element.cols[col].id)],
            capturableItems
          );
          onMutation([patch]);
        }
      }
    });
  });
  useEvent(EVT_ELEMENT_DRAG_START, (ev) => {
    // todo: this should be done in a single operation, no undo/redo at this point
    // and more efficiently. also, should be done in a single transaction
    for (const [cellid, value] of Object.entries(cells)) {
      if (value.containedIds?.some((id: string) => ev.detail.ids.includes(id))) {
        const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
          draft.containedIds = draft.containedIds.filter((id: string) => !ev.detail.ids.includes(id));
        })(cells[cellid]);
        patchAnything({ id: prefixForCellsOfTable(id) + cellid, patch, inversePatch });
      }
    }
  });

  function renderDraggableTableLines() {
    const verticalLinePoints = [0, 0, 0, totalHeight];
    const horizontalLinePoints = [0, 0, totalWidth, 0];

    function onColLineDrag(e: KonvaEventObject<MouseEvent>) {
      const line = e.currentTarget;
      const i = line.attrs.column;
      line.y(0);
      const x2 = Math.max(line.x(), xs[i - 1] + 10);
      line.x(x2);
      const delta = xs[i] - x2; // change from original position
      onColDrag(i, delta);
      e.cancelBubble = true;
    }

    function onRowLineDrag(e: KonvaEventObject<MouseEvent>) {
      const line = e.currentTarget;
      const i = line.attrs.row;
      line.x(0);
      const y = Math.max(line.y(), ys[i - 1] + 10);
      line.y(y);
      const delta = ys[i] - y; // change from original position
      onRowDrag(i, delta);
      e.cancelBubble = true;
    }
    return (
      <>
        {xs.map((x, i) => (
          <Line
            key={`hline-${i}-${id}`}
            name="anchor"
            column={i}
            x={x}
            points={verticalLinePoints}
            stroke={"transparent"}
            strokeWidth={2}
            hitStrokeWidth={15}
            listening={i > 0}
            draggable={true}
            onMouseEnter={setMouseColResize}
            onMouseLeave={unsetMouse}
            onDragMove={onColLineDrag}
          />
        ))}
        {ys.map((y, i) => (
          <Line
            key={`vline-${i}-${id}`}
            name="anchor"
            row={i}
            y={y}
            points={horizontalLinePoints}
            stroke={"transparent"}
            strokeWidth={2}
            hitStrokeWidth={15}
            listening={i > 0}
            draggable={true}
            onMouseEnter={setMouseRowResize}
            onMouseLeave={unsetMouse}
            onDragMove={onRowLineDrag}
          />
        ))}
      </>
    );
  }

  function renderCells() {
    let allCells = [];
    for (let col = 0; col < element.cols.length; col++) {
      if (drag.col == col) {
        continue; // the dragged column is drawn up ahead, so it's z-index is highest
      }
      for (let row = 0; row < element.rows.length; row++) {
        if (drag.row == row) {
          continue; // same as with dragged columns
        }
        const cellWidth = xs[col + 1] - xs[col];
        const cellHeight = ys[row + 1] - ys[row];
        const isEditing = editingCell?.row == element.rows[row].id && editingCell?.col == element.cols[col].id;
        const cellid = cellId(element.rows[row].id, element.cols[col].id);
        allCells.push(
          <TableCell
            key={cellid}
            x={xs[col]}
            y={ys[row]}
            width={cellWidth}
            height={cellHeight}
            defStyle={element}
            cell={cells[cellid]}
            onResize={onResize}
            onChangeElement={onChangeElement}
            isEditing={isEditing}
            onEdit={(action, value) => {
              if (action == "finish") {
                const cellid_reflect = cellIdForReflect(id, element.rows[row].id, element.cols[col].id);
                const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                  draft.text = value;
                })(cells[cellid] ?? {});
                patchAnything({ id: cellid_reflect, patch, inversePatch });
                setEditingCell(null);
              }
            }}
            patchCanvasElement={patchElement}
            patchAnything={patchAnything}
            contained={containedElements[cellid]}
            canvasUtils={canvasUtils}
          />
        );
      }
    }
    if (drag.col != -1 || drag.row != -1) {
      const dx = drag.dx;
      const dy = drag.dy;
      let startCol = drag.col == -1 ? 0 : drag.col;
      let endCol = drag.col == -1 ? element.cols.length : drag.col + 1;
      let startRow = drag.row == -1 ? 0 : drag.row;
      let endRow = drag.row == -1 ? element.rows.length : drag.row + 1;
      for (let col = startCol; col < endCol; col++) {
        for (let row = startRow; row < endRow; row++) {
          const cellWidth = xs[col + 1] - xs[col];
          const cellHeight = ys[row + 1] - ys[row];
          const cellid = cellId(element.rows[row].id, element.cols[col].id);
          allCells.push(
            <TableCell
              key={cellid}
              x={xs[col] + dx}
              y={ys[row] + dy}
              width={cellWidth}
              height={cellHeight}
              defStyle={element}
              cell={cells[cellid]}
              isEditing={false}
              onResize={onResize}
              onChangeElement={onChangeElement}
              onEdit={(action, value) => {
                if (action == "finish") {
                  const cellid_reflect = cellIdForReflect(id, element.rows[row].id, element.cols[col].id);
                  const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                    draft.text = value;
                  })(cells[cellid] ?? {});
                  patchAnything({ id: cellid_reflect, patch, inversePatch });
                  setEditingCell(null);
                }
              }}
              patchCanvasElement={patchElement}
              patchAnything={patchAnything}
              contained={containedElements[cellid]}
              canvasUtils={canvasUtils}
            />
          );
        }
      }
    }
    return <>{allCells}</>;
  }

  // todo: accumulate changes and debounce the update to reflect
  function onColDrag(col: number, delta: number) {
    const [, patch] = produceWithPatches((draft: any) => {
      draft.cols[col - 1].size -= delta * invScaleX;
    })(element);
    patchAnything({ id: "cElement-" + id, patch });
  }

  function onRowDrag(row: number, delta: number) {
    const [, patch] = produceWithPatches((draft: any) => {
      draft.rows[row - 1].size -= delta * invScaleY;
    })(element);
    patchAnything({ id: "cElement-" + id, patch });
  }

  function onCellMouseDown(col: number, row: number, e: KonvaEventObject<MouseEvent>) {
    if (e.evt.shiftKey) {
      setCursor([col, row]);
      selection.extendAbs(col, row);
      e.cancelBubble = true;
    }
  }

  function onCellClick(col: number, row: number, e: KonvaEventObject<MouseEvent>) {
    let handled = false;
    if (e.evt.metaKey) {
      setCursor([col, row]);
      selection.toggle({ x: col, y: row });
      handled = true;
    } else if (!e.evt.shiftKey) {
      if (cursor && cursor[0] == col && cursor[1] == row) {
        setEditingCell({ row: element.rows[row].id, col: element.cols[col].id });
      } else {
        setCursor([col, row]);
        selection.reset({ x: col, y: row });
      }
      handled = true;
    }
    if (handled) {
      e.cancelBubble = true;
      forceRepaint();
    }
  }

  return (
    <>
      <BaseCanvasElement
        type={"table"}
        id={id}
        element={element}
        x={element.x}
        y={element.y}
        onResize={onResize}
        isSelectable={isSelectable}
        isEditingLink={false}
        changeElement={changeElement}
        containedIds={contained}
        cells={cells}
      >
        <Group scaleX={invScaleX} scaleY={invScaleY}>
          {/* background to detect dropped elements over the table and clicks on cells when we're selected */}
          <Rect
            ref={ref}
            width={totalWidth}
            height={totalHeight}
            fill="transparent"
            listening={isSelected}
            onMouseDown={(e: KonvaEventObject<MouseEvent>) => {
              const p = e.currentTarget.getAbsoluteTransform().copy().invert().point(e.evt);
              const col = xs.findIndex((x) => x >= p.x) - 1;
              const row = ys.findIndex((y) => y >= p.y) - 1;
              onCellMouseDown(col, row, e);
            }}
            onClick={(e: KonvaEventObject<MouseEvent>) => {
              const p = e.currentTarget.getAbsoluteTransform().copy().invert().point(e.evt);
              const col = xs.findIndex((x) => x >= p.x) - 1;
              const row = ys.findIndex((y) => y >= p.y) - 1;
              onCellClick(col, row, e);
            }}
          />

          {renderCells()}
          {/* {renderContainedElements()} */}
          {isSelected && renderDraggableTableLines()}
        </Group>
      </BaseCanvasElement>

      {/* interactive widgets when selected */}
      {isSelected && (
        <Group
          isCanvasElement={false}
          isSelectable={false}
          isConnector={false}
          isConnectable={false}
          x={element.x}
          y={element.y}
        >
          {/* toolbar for selected cells (using cursor for now) */}
          {/* currently an invisible div blocks mouse clicks */}
          {cursor && (
            <TableCellsToolbar
              x={xs[cursor[0]]}
              y={ys[cursor[1]]}
              width={xs[cursor[0] + 1] - xs[cursor[0]]}
              height={ys[cursor[1] + 1] - ys[cursor[1]]}
              cell={cells[cellId(element.rows[cursor[1]].id, element.cols[cursor[0]].id)]}
              defStyle={element}
              onChange={(trait: CellTraits, value: any) => {
                let changed = false;
                for (const { x, y } of selection) {
                  // todo: aggregate all changes in a single undo
                  const cell = cells[cellId(element.rows[y].id, element.cols[x].id)] ?? {};
                  const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                    if (trait == CellTraits.Background && value == "unset") delete draft[trait];
                    else draft[trait] = value;
                  })(cell);
                  patchAnything({
                    id: cellIdForReflect(id, element.rows[y].id, element.cols[x].id),
                    patch,
                    inversePatch,
                  });
                  changed = true;
                }
                if (!changed) {
                  const cell = cells[cellId(element.rows[cursor[1]].id, element.cols[cursor[0]].id)] ?? {};
                  const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                    if (trait == CellTraits.Background && value == "unset") delete draft[trait];
                    else draft[trait] = value;
                  })(cell);
                  patchAnything({
                    id: cellIdForReflect(id, element.rows[cursor[1]].id, element.cols[cursor[0]].id),
                    patch,
                    inversePatch,
                  });
                }
              }}
            />
          )}
          {cursor && (
            <Rect
              x={xs[cursor[0]]}
              y={ys[cursor[1]]}
              width={xs[cursor[0] + 1] - xs[cursor[0]]}
              height={ys[cursor[1] + 1] - ys[cursor[1]]}
              fillEnabled={false}
              strokeEnabled={true}
              stroke={SelectedCellStroke}
              strokeWidth={SelectedCellStrokeWidth}
              listening={false}
            />
          )}

          {[...selection].map(({ x, y }) => {
            return (
              <Rect
                key={id + x + y}
                x={xs[x]}
                y={ys[y]}
                width={xs[x + 1] - xs[x]}
                height={ys[y + 1] - ys[y]}
                fillEnabled={true}
                fill={"black"}
                opacity={0.1}
                listening={false}
              />
            );
          })}

          <TableKeyboardShortcuts
            moveCursor={(dx, dy) => {
              if (cursor != null) {
                let [x, y] = cursor;
                x = clamp(x + dx, 0, element.cols.length - 1);
                y = clamp(y + dy, 0, element.rows.length - 1);
                selection.reset({ x, y });
                setCursor([x, y]);
              }
            }}
            editCell={(v) => {
              if (cursor != null) {
                const [x, y] = cursor;
                setEditingCell({ row: element.rows[y].id, col: element.cols[x].id });
                // todo: if v!='' then set the value of the cell
              }
            }}
            extendSelection={(dx, dy) => {
              if (cursor != null) {
                let [x, y] = cursor;
                selection.ensureRect({ x, y });
                selection.extendDelta(dx, dy, element.cols.length, element.rows.length);
                forceRepaint();
              }
            }}
            clearSelection={() => {
              // todo: aggregate all changes in a single undo
              let deleted = false;
              for (const { x, y } of selection) {
                deleted = true;
                const cell = cells[cellId(element.rows[y].id, element.cols[x].id)] ?? {};
                const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                  delete draft.text;
                })(cell);
                patchAnything({
                  id: cellIdForReflect(id, element.rows[y].id, element.cols[x].id),
                  patch,
                  inversePatch,
                });
              }
              return deleted;
            }}
            onClipboardEvent={(event) => {
              if (cursor != null && event.clipboardData) {
                const cellid = cellId(element.rows[cursor[1]].id, element.cols[cursor[0]].id);
                if (event.type == "copy" || event.type == "cut") {
                  event.clipboardData.clearData();
                  const text = cells[cellid]?.text;
                  event.clipboardData.setData("text/plain", text);
                  if (event.type == "cut") {
                    const cell = cells[cellid] ?? {};
                    const [_, patch, inversePatch] = produceWithPatches((draft: any) => void delete draft.text)(cell);
                    patchAnything({
                      id: cellIdForReflect(id, element.rows[cursor[1]].id, element.cols[cursor[0]].id),
                      patch,
                      inversePatch,
                    });
                  }
                } else {
                  if (event.clipboardData.types.indexOf("text/plain") != -1) {
                    var text = event.clipboardData.getData("text/plain");
                    const cell = cells[cellid] ?? {};
                    const [_, patch, inversePatch] = produceWithPatches((draft: any) => void (draft.text = text))(cell);
                    patchAnything({
                      id: cellIdForReflect(id, element.rows[cursor[1]].id, element.cols[cursor[0]].id),
                      patch,
                      inversePatch,
                    });
                  }
                }
                // don't let the browser handle the event
                event.preventDefault();
              }
            }}
          />
          {mouseColRow[0] != -1 && (
            <ColWidgets
              xStart={xs[mouseColRow[0]]}
              xEnd={xs[mouseColRow[0] + 1]}
              col={mouseColRow[0]}
              onDragCol={function (
                action: "start" | "move" | "end",
                args: { col: number; e: KonvaEventObject<MouseEvent> }
              ): void {
                switch (action) {
                  case "start":
                    setDrag({ col: args.col, row: -1, dx: 0, dy: 0 });
                    break;
                  case "move": {
                    const control = args.e.currentTarget;
                    control.y(-dragControlDistance);
                    const min = (xs[0] + xs[1]) / 2;
                    const max = (xs[xs.length - 1] + xs[xs.length - 2]) / 2;
                    const x = clamp(control.x(), min, max);
                    control.x(x);
                    setDrag((d) => ({ ...d, dx: x - (xs[d.col] + xs[d.col + 1]) / 2 }));
                    break;
                  }
                  case "end": {
                    const x = args.e.currentTarget.x();
                    const target = xs.findIndex((x_) => x_ >= x) - 1;
                    if (target != drag.col) {
                      const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                        [draft.cols[drag.col], draft.cols[target]] = [draft.cols[target], draft.cols[drag.col]];
                      })(element);
                      patchElement({ id, patch, inversePatch });
                    }
                    setDrag({ col: -1, row: -1, dx: 0, dy: 0 });
                    break;
                  }
                }
              }}
              onAddColClick={function (action: "before" | "after", col: number): void {
                const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                  if (action == "after")
                    draft.cols.splice(col + 1, 0, { id: cellNanoid(), size: draft.cols[col].size });
                  else {
                    if (col == 0) draft.cols.unshift({ id: cellNanoid(), size: draft.cols[0].size });
                    else draft.cols.splice(col, 0, { id: cellNanoid(), size: draft.cols[col - 1].size });
                  }
                })(element);
                patchElement({ id, patch, inversePatch });
              }}
              onColSelect={() => {
                selection.reset({ x: mouseColRow[0], y: 0 });
                selection.extendDelta(0, element.rows.length - 1, element.cols.length, element.rows.length);
                forceRepaint();
              }}
            />
          )}
          {mouseColRow[1] != -1 && (
            <RowWidgets
              yStart={ys[mouseColRow[1]]}
              yEnd={ys[mouseColRow[1] + 1]}
              row={mouseColRow[1]}
              onDragRow={function (
                action: "start" | "move" | "end",
                args: { row: number; e: KonvaEventObject<MouseEvent> }
              ): void {
                switch (action) {
                  case "start":
                    setDrag({ col: -1, row: mouseColRow[1], dx: 0, dy: 0 });
                    break;
                  case "move":
                    {
                      const control = args.e.currentTarget;
                      control.x(-dragControlDistance);
                      const min = (ys[0] + ys[1]) / 2;
                      const max = (ys[ys.length - 1] + ys[ys.length - 2]) / 2;
                      let y = clamp(control.y(), min, max);
                      control.y(y);
                      setDrag((d) => ({ ...d, dy: y - (ys[d.row] + ys[d.row + 1]) / 2 }));
                    }
                    break;
                  case "end": {
                    const y = args.e.currentTarget.y();
                    const target = ys.findIndex((y_) => y_ >= y) - 1;
                    if (target != drag.row) {
                      const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                        [draft.rows[drag.row], draft.rows[target]] = [draft.rows[target], draft.rows[drag.row]];
                      })(element);
                      patchElement({ id, patch, inversePatch });
                    }
                    setDrag({ col: -1, row: -1, dx: 0, dy: 0 });
                    break;
                  }
                }
              }}
              onAddRowClick={function (action: "before" | "after", row: number): void {
                const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                  if (action == "after")
                    draft.rows.splice(row + 1, 0, { id: cellNanoid(), size: draft.rows[row].size });
                  else {
                    if (row == 0) draft.rows.unshift({ id: cellNanoid(), size: draft.rows[0].size });
                    else draft.rows.splice(row, 0, { id: cellNanoid(), size: draft.rows[row - 1].size });
                  }
                })(element);
                patchElement({ id, patch, inversePatch });
              }}
              onRowSelect={() => {
                selection.reset({ x: 0, y: mouseColRow[1] });
                selection.extendDelta(element.cols.length - 1, 0, element.cols.length, element.rows.length);
                forceRepaint();
              }}
            />
          )}
          <Group
            x={totalWidth / 2}
            y={totalHeight + dragControlDistance * 2}
            onClick={() => {
              const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                draft.rows.push({ id: cellNanoid(), size: draft.rows[draft.rows.length - 1].size });
              })(element);
              patchElement({ id, patch, inversePatch });
            }}
          >
            <LargeAddRowButton />
          </Group>
          <Group
            x={totalWidth + dragControlDistance * 2}
            y={totalHeight / 2}
            onClick={() => {
              const [_, patch, inversePatch] = produceWithPatches((draft: any) => {
                draft.cols.push({ id: cellNanoid(), size: draft.cols[draft.cols.length - 1].size });
              })(element);
              patchElement({ id, patch, inversePatch });
            }}
          >
            <LargeAddColButton />
          </Group>
        </Group>
      )}
    </>
  );
}

const setMouseCursor = (cursor: CSSProperties["cursor"]) => (e: KonvaEventObject<MouseEvent>) =>
  (e.currentTarget.getStage()!.container().style.cursor = cursor!);

const setMouseColResize = setMouseCursor("col-resize");
const setMouseRowResize = setMouseCursor("row-resize");
const unsetMouse = setMouseCursor("inherit");

const dragControlDistance = 30;

function TableCell({
  x,
  y,
  width,
  height,
  cell,
  defStyle,
  isEditing,
  onEdit,
  contained,
  canvasUtils,
  patchCanvasElement,
  patchAnything,
  onResize,
  onChangeElement,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  cell?: any;
  defStyle: any;
  isEditing: boolean;
  onEdit: (action: "edit" | "finish", value?: string) => void;
  contained?: any[];
  canvasUtils: any;
  patchCanvasElement: (args: { id: string; patch: any; inversePatch?: any; details?: any }) => void;
  patchAnything: (args: { id: string; patch: any; inversePatch?: any; details?: any }) => void;
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
  onChangeElement: (
    id: string,
    props: Map<string, any>,
    previousProps: Map<string, any>,
    addUndo: boolean,
    updateSubMenuData?: any
  ) => void;
}) {
  const background = cell?.fill || defStyle.fill;
  // const selected = useAtomValue(selectedElementIdsAtom);
  // const editingId = useAtomValue(editingElementIdAtom);
  if (isEditing) {
    return (
      <CellTextEditor
        contentArea={{ x, y, width, height }}
        background={background}
        value={cell?.text}
        placeholder={"Add text"}
        isFixedHeight={true}
        onChange={(value) => onEdit("edit", value)}
        onFinish={(value) => onEdit("finish", value)}
        cssTextArea={cssTextStyle(cell, defStyle)}
      />
    );
  }
  return (
    <>
      <Rect
        key={"" + x + y}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={background}
        strokeWidth={2}
        stroke={defStyle.stroke}
        listening={false}
      />
      {!!cell?.text && (
        <Text
          key={"text" + x + y}
          x={x}
          y={y}
          width={width}
          height={height}
          padding={cellPadding}
          text={cell.text}
          {...textStyleKonva(cell, defStyle)}
          listening={false}
        />
      )}
      {/* {contained &&
        contained.map(([key, val]: [string, any]) => {
          let el = { ...val, x: val.x + x, y: val.y + y };
          return (
            <CanvasElement
              uniqueId={key}
              isSelected={selected.includes(key)}
              drawOutlineAroundElements={false}
              isEditing={editingId==key}
              isEditingLink={false} //todo
              elementData={el}
              isFrameHighlighted={false}
              isInContainer={true}
              onResize={onResize}
              onChangeElement={onChangeElement}
              patchCanvasElement={patchCanvasElement}
              patchAnything={patchAnything}
              onElementsMutationEnded={canvasUtils.onElementsMutationEnded}
              isSelectable={true}
              isReadOnly={false}
            />
          );
        })} */}
    </>
  );
}

function HtmlFrameOnCanvas({
  box,
  css,
  children,
}: {
  box: { x: number; y: number; width: number; height: number };
  children: React.ReactNode;
  css?: CSSProperties;
}) {
  return (
    <Html groupProps={{ x: box.x, y: box.y }}>
      <div style={{ width: box.width, height: box.height, ...css }}>{children}</div>
    </Html>
  );
}

function CellTextEditor({
  contentArea,
  background,
  value,
  placeholder,
  isFixedHeight,
  onChange,
  onFinish,
  cssTextArea,
}: {
  contentArea: { x: number; y: number; width: number; height: number };
  background: string;
  value?: string;
  placeholder: string;
  isFixedHeight: boolean;
  onChange: (value: string) => void;
  onFinish: (value: string) => void;
  cssTextArea?: CSSProperties;
}) {
  return (
    <HtmlFrameOnCanvas
      box={contentArea}
      css={{
        cursor: "text",
        padding: cellPadding + "px",
        border: "#1a71ff 4px solid",
        background,
      }}
    >
      <textarea
        autoFocus
        onFocus={(e) => e.currentTarget.select()}
        style={{
          height: "100%",
          width: "100%",
          // reset the default styles of a text area
          overflow: "auto",
          outline: "none",
          border: "none",
          background: "unset",
          resize: "none",
          verticalAlign: "top",
          // include the custom css for font and color
          ...cssTextArea,
        }}
        onBlur={(e) => {
          onFinish(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          handleTabInTextArea(e);
          if (e.key == "Escape") {
            e.stopPropagation();
            onFinish(e.currentTarget.value);
          }
        }}
        onInput={(e) => {
          onChange(e.currentTarget.value);
        }}
        defaultValue={value}
        placeholder={placeholder}
      />
    </HtmlFrameOnCanvas>
  );
}

function TableKeyboardShortcuts({
  moveCursor,
  editCell,
  extendSelection,
  clearSelection,
  onClipboardEvent,
}: {
  moveCursor?: (dx: number, dy: number) => void;
  editCell?: (char?: string) => void;
  extendSelection?: (dx: number, dy: number) => void;
  clearSelection?: () => boolean;
  onClipboardEvent?: (e: ClipboardEvent) => void;
}) {
  useHotkey(
    isArrowKey,
    (e) => {
      let dx = e.key == "ArrowLeft" ? -1 : e.key == "ArrowRight" ? 1 : 0;
      let dy = e.key == "ArrowUp" ? -1 : e.key == "ArrowDown" ? 1 : 0;
      moveCursor ? moveCursor(dx, dy) : console.log("cursor move", dx, dy);
      return true;
    },
    { allowRepeat: true }
  );
  useHotkey(
    (e) => isEnterKey(e) || isCharacterKey(e),
    (e) => {
      if (editCell) {
        if (e.key == "Enter") editCell();
        else editCell(e.key);
        return true;
      }
      return false;
    },
    { disabled: !editCell }
  );
  useHotkey(
    (e) => isDelete(e),
    (e) => {
      return clearSelection ? clearSelection() : false;
    }
  );
  useHotkey(
    (e) => isShiftAndArrowKey(e),
    (e) => {
      let dx = e.key == "ArrowLeft" ? -1 : e.key == "ArrowRight" ? 1 : 0;
      let dy = e.key == "ArrowUp" ? -1 : e.key == "ArrowDown" ? 1 : 0;
      extendSelection ? extendSelection(dx, dy) : console.log("extend sel", dx, dy);
      return true;
    },
    { allowRepeat: true }
  );
  // @ts-ignore
  useEventListener("copy", onClipboardEvent ? onClipboardEvent : noop, window, { passive: false });
  // @ts-ignore
  useEventListener("cut", onClipboardEvent ? onClipboardEvent : noop, window, { passive: false });
  // @ts-ignore
  useEventListener("paste", onClipboardEvent ? onClipboardEvent : noop, window, { passive: false });

  return null;
}

function ColWidgets({
  xStart,
  xEnd,
  col,
  onDragCol,
  onAddColClick,
  onColSelect,
}: {
  xStart: number;
  xEnd: number;
  col: number;
  onDragCol: (action: "start" | "move" | "end", args: { col: number; e: KonvaEventObject<MouseEvent> }) => void;
  onAddColClick: (action: "before" | "after", col: number) => void;
  onColSelect: () => void;
}) {
  const dragRowWidget = (
    <Group
      x={(xStart + xEnd) / 2}
      y={-dragControlDistance}
      draggable
      onDragStart={(e) => onDragCol("start", { col, e })}
      onDragMove={(e) => onDragCol("move", { col, e })}
      onDragEnd={(e) => onDragCol("end", { col, e })}
      onClick={onColSelect}
    >
      {/* the first rect is for the hit-area, and it's intentionally big (better ux) */}
      <Rect width={60} height={40} offset={{ x: 30, y: 20 }} fill="transparent" name="anchor-drag-col" />
      {/* graphical widget */}
      <Rect width={30} height={20} offset={{ x: 15, y: 10 }} fill="#0072FF" cornerRadius={10} listening={false} />
      <Line y={-3} points={[-5, 0, 5, -0]} stroke="#ffffff" strokeWidth={3} lineCap="round" listening={false} />
      <Line y={3} points={[-5, 0, 5, 0]} stroke="#ffffff" strokeWidth={3} lineCap="round" listening={false} />
    </Group>
  );
  return (
    <>
      <Group x={xStart} y={-dragControlDistance} onClick={() => onAddColClick("before", col)}>
        <SmallAddButton />
      </Group>
      {dragRowWidget}
      <Group x={xEnd} y={-dragControlDistance} onClick={() => onAddColClick("after", col)}>
        <SmallAddButton />
      </Group>
    </>
  );
}

function RowWidgets({
  yStart,
  yEnd,
  row,
  onDragRow,
  onAddRowClick,
  onRowSelect,
}: {
  yStart: number;
  yEnd: number;
  row: number;
  onDragRow: (action: "start" | "move" | "end", args: { row: number; e: KonvaEventObject<MouseEvent> }) => void;
  onAddRowClick: (action: "before" | "after", row: number) => void;
  onRowSelect: () => void;
}) {
  const dragRowWidget = (
    <Group
      x={-dragControlDistance}
      y={(yStart + yEnd) / 2}
      draggable
      onDragStart={(e) => onDragRow("start", { row, e })}
      onDragMove={(e) => onDragRow("move", { row, e })}
      onDragEnd={(e) => onDragRow("end", { row, e })}
      onClick={onRowSelect}
    >
      {/* the first rect is for the hit-area, and it's intentionally big (better ux) */}
      <Rect width={40} height={60} offset={{ x: 20, y: 30 }} fill="transparent" name="anchor-drag-row" />
      {/* graphical widget */}
      <Rect width={20} height={30} offset={{ x: 10, y: 15 }} fill="#0072FF" cornerRadius={10} listening={false} />
      <Line x={-3} points={[0, -5, 0, 5]} stroke="#ffffff" strokeWidth={3} lineCap="round" listening={false} />
      <Line x={3} points={[0, -5, 0, 5]} stroke="#ffffff" strokeWidth={3} lineCap="round" listening={false} />
    </Group>
  );
  return (
    <>
      <Group y={yStart} x={-dragControlDistance} onClick={() => onAddRowClick("before", row)}>
        <SmallAddButton />
      </Group>
      {dragRowWidget}
      <Group y={yEnd} x={-dragControlDistance} onClick={() => onAddRowClick("after", row)}>
        <SmallAddButton />
      </Group>
    </>
  );
}

function PlusIcon({
  size,
  color,
  width = 2,
  lineCap = "square",
}: {
  size: number;
  color: string;
  width?: number;
  lineCap?: LineCap;
}) {
  return (
    <>
      <Line
        points={[0, -size / 2, 0, size / 2]}
        stroke={color}
        strokeWidth={width}
        lineCap={lineCap}
        listening={false}
      />
      <Line
        points={[-size / 2, 0, size / 2, 0]}
        stroke={color}
        strokeWidth={width}
        lineCap={lineCap}
        listening={false}
      />
    </>
  );
}

function AddButton({
  width,
  height,
  hitWidth = width,
  hitHeight = height,
}: {
  width: number;
  height: number;
  hitWidth?: number;
  hitHeight?: number;
}) {
  const ref = useRef<Konva.Group>(null);
  return (
    <>
      <Rect
        name="anchor ignore"
        x={-hitWidth / 2}
        y={-hitHeight / 2}
        width={hitWidth}
        height={hitHeight}
        onMouseEnter={() => {
          if (ref.current) {
            ref.current.to({ scaleX: 1.2, scaleY: 1.2, duration: 0.1 });
            ref.current.getChildren()[0].to({ fill: "#6DAFFF", duration: 0.1 });
          }
        }}
        onMouseLeave={() => {
          if (ref.current) {
            ref.current.to({ scaleX: 1, scaleY: 1, duration: 0.1 });
            ref.current.getChildren()[0].to({ fill: "#BFDCFF", duration: 0.1 });
          }
        }}
      />
      <Group ref={ref} listening={false}>
        <Rect x={-width / 2} y={-height / 2} width={width} height={height} cornerRadius={13} fill="#BFDCFF" />
        <PlusIcon size={12} color="#0072FF" />
      </Group>
    </>
  );
}

function TableCellsToolbar({
  x,
  y,
  width,
  height,
  cell,
  defStyle,
  onChange,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  cell: any;
  defStyle: any;
  onChange: (trait: CellTraits, value: any) => void;
}) {
  const background = cell?.fill || defStyle.fill;
  const font = cell?.font ?? defStyle.font;
  const fontSize = cell?.fontSize ?? defStyle.fontSize;
  const textColor = cell?.textColor ?? defStyle.textColor;
  const fontProps = cell?.fontProps ?? defStyle.fontProps;

  // todo: Html creates an empty div which can block mouse scroll events
  // this is really bad ux.
  return (
    <Html groupProps={{ x: x + width / 2, y }} divProps={{ style: { width: 0, height: 0, overflow: "visible" } }}>
      <div
        className={cn(toolbarStyle.mainToolbar, toolbarStyle.withSeparators)}
        style={{ transform: " translateY(-21px) translate(-50%, -100%)", width: "fit-content" }}
      >
        <ColorPicker
          tooltip="Cell color"
          value={background}
          onChange={(color) => onChange(CellTraits.Background, color)}
          colorPalette={consts.COLOR_PALETTE.concat(["unset"])}
        />
        <ColorPicker
          tooltip="Text Color"
          value={textColor}
          colorPalette={consts.COLOR_PALETTE}
          onChange={(color) => onChange(CellTraits.TextColor, color)}
        />
        <TextFontPicker
          key="text-font"
          tooltip="Font"
          value={font}
          onChange={(value) => onChange(CellTraits.Font, value)}
        />
        <TextSizePicker
          key="text-font-size"
          value={fontSize}
          onChange={(value: any) => onChange(CellTraits.FontSize, value)}
        />
        <TextProps key="font-props" value={fontProps} onChange={(props) => onChange(CellTraits.FontProps, props)} />
        {/* <div
          role="button"
          className={toolbarButtonsStyle.button}
          onClick={console.log}
        >
          <TrashIcon color="white" size={20} />
        </div> */}
      </div>
    </Html>
  );
}

const SmallAddButton: React.FC = () => <AddButton width={26} height={26} hitWidth={40} hitHeight={40} />;
const LargeAddRowButton: React.FC = () => <AddButton width={200} height={40} />;
const LargeAddColButton: React.FC = () => <AddButton width={40} height={200} />;

export function tableTraits(element: TypeTableElement): ITraits {
  return {
    ...textEnabledTraits(element),
    [Trait.tableFillColor]: element.fill,
    [Trait.tableStrokeColor]: element.stroke,
    [Trait.tableAddColumn]: "",
    [Trait.tableAddRow]: "",
  };
}

export function tableValidateTraits(element: TypeTableElement, trait: Trait, value: any) {
  if (trait == Trait.tableFillColor && typeof value == "number") {
    return replaceColorOpacity(element.fill, value);
  }
  if (trait == Trait.tableStrokeColor && typeof value == "number") {
    return replaceColorOpacity(element.stroke, value);
  }
  if (trait == Trait.tableAddColumn) {
    return [...element.cols, { id: cellNanoid(), size: element.cols[element.cols.length - 1].size }];
  }
  if (trait == Trait.tableAddRow) {
    return [...element.rows, { id: cellNanoid(), size: element.rows[element.rows.length - 1].size }];
  }
  return value;
}
