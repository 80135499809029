import style from "./checkout.module.css";
import Modal from "frontend/modal/modal";
import UpgradeTrialModal from "frontend/modals/upgrade-trial-modal";
import { useEffect, useState } from "react";
import { PortalID } from "frontend/modal/usePortal";
import ReactDOM from "react-dom";
import UpgradeModalForVideo from "frontend/modals/upgrade/upgrade-for-video";
import { useAtomValue } from "jotai";
import { plansDataAtom } from "state-atoms";
import UpgradeModalForOrgchartNodes from "frontend/modals/upgrade/upgrade-for-orgchart-nodes";
import UpgradeModalForOrgchartFields from "frontend/modals/upgrade/upgrade-for-orgchart-fields";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import UpgradeModalForMondayIntegration from "frontend/modals/upgrade/upgrade-for-monday-integration";
import UpgradeModalForUnlimitedElements from "frontend/modals/upgrade/upgrade-for-unlimited-elements";
import UpgradeModalForCreateTemplate from "frontend/modals/upgrade/upgrade-for-create-template";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/nextjs";
import ErrorPage, { ModalErrorPage } from "frontend/error/error-page";

export type CheckoutSources =
  | "templates"
  | "video-call"
  | "org-chart-nodes"
  | "org-chart-fields"
  | "monday-integration"
  | "general"
  | "file-upload-limit"
  | "elements-limit"
  | "public-boards"
  | "create-template";

export type CheckoutSource = { name: CheckoutSources; value: string };

export default function Checkout({
  user,
  source,
  onDismiss,
}: {
  user: any;
  source?: CheckoutSource;
  onDismiss?: () => void;
}) {
  let portal = document.getElementById(PortalID.Checkout);

  const showPaymentPage = window && window.screen && window.screen.availWidth >= consts.MIN_WIDTH_TO_SHOW_PAYMENT_PAGE; //experimentValue && experimentValue.value === 'test';
  const plansData = useAtomValue(plansDataAtom);
  const selectedPlanData = plansData?.find((i: any) => i.name.toLowerCase() === "team"); //We assume all plans have the same trial days here, so we just take randomally from the teams plan. We should change it if this assumption changes
  const trialDays = selectedPlanData?.trialDays ?? 14;

  const [modalToShow, setModalToShow] = useState<string>(source?.name ?? "general");

  useEffect(() => {
    if (showPaymentPage) {
      const boardsContainer = document.getElementById("boards-container");
      if (boardsContainer) {
        boardsContainer.style.position = "fixed";
      }
      return () => {
        if (boardsContainer) {
          boardsContainer.style.position = "absolute";
        }
      };
    }
  }, [showPaymentPage]);

  function renderCheckout() {
    switch (modalToShow) {
      case "video-call":
        return (
          <UpgradeModalForVideo
            trialDays={trialDays}
            onDismiss={() => onDismiss && onDismiss()}
            onUpgrade={function (): void {
              tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
              tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "video-calls");
              setModalToShow("general");
            }}
            insideModal={!showPaymentPage}
          />
        );
      case "org-chart-nodes":
        return (
          <UpgradeModalForOrgchartNodes
            trialDays={trialDays}
            onDismiss={() => onDismiss && onDismiss()}
            onUpgrade={function (): void {
              tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
              tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "org-chart-nodes");
              setModalToShow("general");
            }}
            insideModal={!showPaymentPage}
          />
        );
      case "org-chart-fields":
        return (
          <UpgradeModalForOrgchartFields
            trialDays={trialDays}
            onDismiss={() => onDismiss && onDismiss()}
            onUpgrade={function (): void {
              tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
              tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "org-chart-fields");
              setModalToShow("general");
            }}
            insideModal={!showPaymentPage}
          />
        );
      case "elements-limit":
        return (
          <UpgradeModalForUnlimitedElements
            trialDays={trialDays}
            onDismiss={() => onDismiss && onDismiss()}
            onUpgrade={function (): void {
              tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
              tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "elements-limit");
              setModalToShow("general");
            }}
            insideModal={!showPaymentPage}
          />
        );

      case "monday-integration":
        return (
          <UpgradeModalForMondayIntegration
            trialDays={trialDays}
            onDismiss={() => onDismiss && onDismiss()}
            onUpgrade={function (): void {
              tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
              tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "monday-integration");
              setModalToShow("general");
            }}
            insideModal={!showPaymentPage}
          />
        );
      case "create-template":
        return (
          <UpgradeModalForCreateTemplate
            trialDays={trialDays}
            onDismiss={() => onDismiss && onDismiss()}
            onUpgrade={function (): void {
              tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
              tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "create-template");
              setModalToShow("general");
            }}
            insideModal={!showPaymentPage}
          />
        );
      case "templates":
      case "file-upload-limit":
      case "general":
      default:
        return (
          <UpgradeTrialModal
            user={user}
            onDismiss={() => onDismiss && onDismiss()}
            source={source}
            renderType={showPaymentPage ? "page" : "modal"}
          />
        );
    }
  }

  function renderCheckoutPortal() {
    return <div className={style.checkoutContainer}>{renderCheckout()}</div>;
  }

  function render() {
    if (!showPaymentPage) {
      return <Modal dimBackground={true}>{renderCheckout()}</Modal>;
    } else {
      return portal ? ReactDOM.createPortal(renderCheckoutPortal(), portal) : <></>;
    }
  }

  return (
    <ErrorBoundary
      fallback={<ModalErrorPage />}
      onError={(error) => {
        tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "checkout-error", source?.value);
        Sentry.addBreadcrumb({
          message: "Checkout Error",
          level: "error",
          data: {
            source,
            user,
            showPaymentPage,
            modalToShow,
          },
        });
        Sentry.captureException(error, { tags: { type: "billing", page: "checkout" }});
      }}
    >
      {render()}
    </ErrorBoundary>
  );
}
